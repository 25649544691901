import { AxiosErrorWithResponse } from 'helpers/axiosResponseHelpers';

type AxiosErrorWithReCaptchaResponse = AxiosErrorWithResponse & {
  response: {
    data: {
      Details: {
        reCaptchaScore: number;
      };
    };
  };
};

export const isReCaptchaThresholdError = (
  error: AxiosErrorWithResponse
): error is AxiosErrorWithReCaptchaResponse =>
  error.response.data?.Code === 'EVO_BUS_MM354';
