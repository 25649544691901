import apiService from './apiService';

export type SessionClient = {
  refreshSession: () => Promise<void>;
  expireSession: () => Promise<void>;
};

const sessionClient: SessionClient = {
  refreshSession: () => apiService.put('session', {}),
  expireSession: () => apiService.delete('session'),
};

export default sessionClient;
