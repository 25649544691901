import { AdditionalDriver } from 'state/formData/additionalDriversDetails';
import { EditAddressMtaDetails } from 'state/formData/mta/editAddress';
import { VehicleDetails } from 'state/formData/vehicleDetails';

export const isValueBusinessUse = (value: string): boolean =>
  value.toLowerCase().includes('business');

export const isBusinessUse = (
  data: VehicleDetails | AdditionalDriver | EditAddressMtaDetails
): boolean => isValueBusinessUse(data.carUse);
