import FaqWidget from '@rsa-digital/evo-shared-components/components/FaqWidget';
import { graphql } from 'gatsby';
import React from 'react';
import {
  processOptionalCta,
  processTextLink,
  unwrapSingleton,
} from 'helpers/csTypeProcessors';
import { trackAccordionCollapse, trackAccordionExpand } from 'helpers/eventTracking';
import { CsCta, CsLink } from 'types/contentStack';
import { FAQ } from './types';

type FaqWidgetBlockProps = {
  faq_widget: {
    faq_categories: {
      title: string;
      faqs: {
        faq: [FAQ];
      }[];
      category_link: CsLink | null;
    }[];
    display_questions_on_load: boolean;
    call_to_action: [CsCta] | null;
  };
};

export const query = graphql`
  fragment FaqWidgetBlockPolicyManagement on cs__policy_management_blocks {
    faq_widget {
      faq_categories {
        title
        faqs {
          faq {
            question
            answer
          }
        }
        category_link {
          title
          href
        }
      }
      display_questions_on_load
      call_to_action {
        display_text
        screen_reader_text
        url
      }
    }
  }
`;

const unwrapFaq = (faq: [FAQ]): FAQ => {
  const unwrappedFaq = unwrapSingleton(faq);
  /* istanbul ignore if */
  if (!unwrappedFaq) {
    throw new Error('FAQ for FAQ Widget missing!');
  }
  return unwrappedFaq;
};

const FaqWidgetBlock: React.FC<FaqWidgetBlockProps> = ({ faq_widget }) => {
  const faqCategories = faq_widget.faq_categories.map((category) => ({
    title: category.title,
    questionsAndAnswers: category.faqs.map((faq) => {
      const unwrappedFaq = unwrapFaq(faq.faq);
      return {
        ...unwrappedFaq,
        onExpand: trackAccordionExpand(`${category.title} - ${unwrappedFaq.question}`),
        onCollapse: trackAccordionCollapse(
          `${category.title} - ${unwrappedFaq.question}`
        ),
      };
    }),
    link: processTextLink(category.category_link),
    onCollapse: trackAccordionExpand(category.title),
    onExpand: trackAccordionCollapse(category.title),
  }));

  return (
    <FaqWidget
      faqCategories={faqCategories}
      cta={processOptionalCta(faq_widget.call_to_action)}
      initiallyDisplayQuestions={faq_widget.display_questions_on_load}
      data-cy="FAQ Widget"
    />
  );
};

export default React.memo(FaqWidgetBlock);
