import GridContainer from '@rsa-digital/evo-shared-components/components/GridContainer';
import React, { useEffect } from 'react';
import Analytics from 'components/Analytics';
import CookieBanner from 'components/CookieBanner';
import AccountAreaFooter from 'components/Footer/AccountAreaFooter';
import QuoteAndBuyFooter from 'components/Footer/QuoteAndBuyFooter';
import QuoteAndBuyHeader from 'components/Header/QuoteAndBuyHeader';
import SelfServeHeader from 'components/Header/SelfServeHeader';
import Meta from 'components/Meta';
import ReCaptcha from 'components/ReCaptcha';
import SessionExpiryWrapper, {
  SessionExpiryOption,
} from 'components/SessionExpiryWrapper';
import { reloadOneTrust } from 'helpers/oneTrustHelper';
import { CsMeta } from 'types/contentStack';
import RefreshButton from './refresh';

/* istanbul ignore next */
const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

export type PageType =
  | 'quoteAndBuy'
  | 'error'
  | 'accountArea'
  | 'mtaAndRenewal'
  | 'interstitial';

const getProgressBarStepFromQuoteAndBuyStep = (
  quoteAndBuyStep: number | undefined
): number | undefined => {
  switch (quoteAndBuyStep) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 2.5;
    case 4:
      return 3;
    case 5:
      return 4;
    case 6:
      return 5;
    case 7:
      return 6;
    case 8:
      return 7;
    default:
      return undefined;
  }
};

const Header: React.FC<{
  pageType: PageType;
  hideAccountMenu?: boolean;
  quoteAndBuyStep?: number;
}> = ({ pageType, hideAccountMenu, quoteAndBuyStep }) => {
  // Maps the current quote and buy step (out of 8) into a progress bar step (out of 7) to account for the inclusion of a half step in the progress bar
  // The About you and About your cover pages are both part of step 2 in the progress bar, and are displayed slightly differently
  const progressBarStep = getProgressBarStepFromQuoteAndBuyStep(quoteAndBuyStep);

  switch (pageType) {
    case 'quoteAndBuy':
    case 'error':
      return <QuoteAndBuyHeader currentStep={progressBarStep} />;
    case 'accountArea':
    case 'mtaAndRenewal':
      return <SelfServeHeader hideAccountMenu={hideAccountMenu} />;
    case 'interstitial':
    default:
      return null;
  }
};

const Footer: React.FC<{ pageType: PageType; quoteAndBuyStep?: number }> = ({
  pageType,
  quoteAndBuyStep,
}) => {
  switch (pageType) {
    case 'quoteAndBuy':
    case 'error':
    case 'mtaAndRenewal':
      return <QuoteAndBuyFooter quoteAndBuyStep={quoteAndBuyStep} />;
    case 'accountArea':
      return <AccountAreaFooter />;
    case 'interstitial':
    default:
      return null;
  }
};

const Layout: React.FC<{
  pageType: PageType;
  meta: CsMeta;
  hideAccountMenu?: boolean;
  quoteAndBuyStep?: number;
  sessionExpiryOption?: SessionExpiryOption;
}> = ({
  children,
  pageType,
  meta,
  hideAccountMenu,
  quoteAndBuyStep,
  sessionExpiryOption = SessionExpiryOption.DEFAULT_SESSION_EXPIRY,
}) => {
  useEffect(reloadOneTrust, []);

  return (
    <SessionExpiryWrapper pageType={pageType} sessionExpiryOption={sessionExpiryOption}>
      {activeEnv !== 'test' && <Analytics />}
      {activeEnv !== 'test' && <CookieBanner />}
      {activeEnv === 'preview' && /* istanbul ignore next */ <RefreshButton />}
      <ReCaptcha />
      <Meta meta={meta} />
      {pageType === 'interstitial' && children}
      {pageType !== 'interstitial' && (
        <GridContainer as="main">
          <Header
            pageType={pageType}
            hideAccountMenu={hideAccountMenu}
            quoteAndBuyStep={quoteAndBuyStep}
          />
          {children}
          <Footer pageType={pageType} quoteAndBuyStep={quoteAndBuyStep} />
        </GridContainer>
      )}
    </SessionExpiryWrapper>
  );
};

export default Layout;
