import { graphql } from 'gatsby';
import React from 'react';
import { SectionHeading } from './styles';

type LeftAlignHeadingBlockProps = {
  left_aligned_heading: {
    heading: string;
  };
};

export const query = graphql`
  fragment LeftAlignHeadingBlockPolicyManagement on cs__policy_management_blocks {
    left_aligned_heading {
      heading
    }
  }
`;

const LeftAlignHeadingBlock: React.FC<LeftAlignHeadingBlockProps> = ({
  left_aligned_heading,
}) => (
  <SectionHeading data-cy="LeftAlignHeading">
    {left_aligned_heading.heading}
  </SectionHeading>
);

export default React.memo(LeftAlignHeadingBlock);
