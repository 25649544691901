export const UPDATE_ERROR = 'UPDATE_ERROR';
export const RESET_ERROR = 'RESET_ERROR';

export type UpdateErrorAction = {
  type: typeof UPDATE_ERROR;
  errorType?: ErrorType;
  statusCode?: number;
};

export type ResetErrorAction = {
  type: typeof RESET_ERROR;
};

export enum ErrorType {
  API_ERROR = 'API_ERROR',
  AZURE_ERROR = 'AZURE_ERROR',
  RIGHTS_OF_RESERVATION = 'RIGHTS_OF_RESERVATION',
  NOT_FOUND = 'NOT_FOUND',
  ACCOUNT_UNAVAILABLE = 'ACCOUNT_UNAVAILABLE',
  FORBIDDEN = 'FORBIDDEN',
  UNAUTHORIZED = 'UNAUTHORIZED',
  QUOTE_INELIGIBLE = 'QUOTE_INELIGIBLE',
  QUOTE_FRAUDULENT = 'QUOTE_FRAUDULENT',
  QUOTE_EARNIX_ERROR = 'QUOTE_EARNIX_ERROR',
  QUOTE_EXPIRED = 'QUOTE_EXPIRED',
  QUOTE_NOT_FOUND = 'QUOTE_NOT_FOUND',
  QUOTE_TIER_INVALID = 'QUOTE_TIER_INVALID',
  RECAPTCHA_ERROR = 'RECAPTCHA_ERROR',
  MTA_QUOTE_INELIGIBLE = 'MTA_QUOTE_INELIGIBLE',
  RENEWAL_QUOTE_INELIGIBLE = 'RENEWAL_QUOTE_INELIGIBLE',
  MTA_TIER_INVALID = 'MTA_TIER_INVALID',
  UNKNOWN = 'UNKNOWN',
}
