import { AdditionalDriver } from 'state/formData/additionalDriversDetails';
import { DriverDetails } from 'state/formData/driverDetails';

const genderedTitles = [
  'B53 002', // Miss
  'B53 003', // Mr
  'B53 004', // Mrs
  'B53 005', // Ms
  'B53 007', // Sir
  'B53 014', // Dame
  'B53 019', // Lady
  'B53 021', // Lord
  'B53 035', // Father
];

/**
 * Returns whether gender can be infered from the chosen title, so that gender
 * can be automatically set rather than asking the user
 */
export const hasGenderlessTitle = (data: DriverDetails | AdditionalDriver): boolean => {
  return data.title !== '' && !genderedTitles.includes(data.title);
};

export const getInferedGenderFromTitle = (title: string): string | undefined => {
  switch (title) {
    case 'B53 003':
    case 'B53 007':
    case 'B53 021':
    case 'B53 035':
      return 'M';
    case 'B53 002':
    case 'B53 004':
    case 'B53 005':
    case 'B53 014':
    case 'B53 019':
      return 'F';
    default:
      return undefined;
  }
};
