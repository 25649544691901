import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  AddonSelection,
  CoverageSelection,
  getLatestTierSelection,
  LocalBreakdown,
} from 'state/addonsSelection/addonsSelection';
import { RootState } from 'state/createStore';
import {
  AddOn,
  BreakdownCover,
  BreakdownCoverLevel,
  LegalCoverageType,
  PaymentDetails,
  Quote,
  Renewal,
  SelectedBreakdown,
  TierOptions,
} from 'state/quote/quote';
import getTieredData from './getTieredData';

type MonthlyPremiums = {
  total?: number;
  deposit?: number;
  installments?: {
    amount?: number;
    dates?: string[];
  };
  finalInstallment?: {
    amount?: number;
    date?: string;
  };
};

type PaymentPremiums = {
  annualPayment: {
    total?: number;
  };
  monthlyPayment: MonthlyPremiums;
};

type AddOnPaymentPremiums = {
  annualPremium: number;
  monthlyInstallmentPremium: number;
  monthlyDepositPremium: number;
  monthlyTotalPremium: number;
  monthlyFinalInstallmentPremium: number;
};

const isCoverageIncludedInBasePrice = (
  selectedTier: TierOptions | null,
  coverage: CoverageSelection
): boolean => selectedTier === TierOptions.Extra && coverage.type === LegalCoverageType;

enum PaymentPremiumsOperators {
  Add = 1,
  Subtract = -1,
}

const updatePaymentPremiumsWithAddon = (
  addOnPaymentPremiums: AddOnPaymentPremiums,
  quotePaymentPremiums: PaymentPremiums,
  paymentPremiumsOperator: PaymentPremiumsOperators
): PaymentPremiums => {
  const sign = paymentPremiumsOperator;

  return {
    annualPayment: {
      total:
        (quotePaymentPremiums.annualPayment.total ?? 0) +
        addOnPaymentPremiums.annualPremium * sign,
    },
    monthlyPayment: {
      total:
        (quotePaymentPremiums.monthlyPayment.total ?? 0) +
        addOnPaymentPremiums.monthlyTotalPremium * sign,
      deposit:
        (quotePaymentPremiums.monthlyPayment.deposit ?? 0) +
        addOnPaymentPremiums.monthlyDepositPremium * sign,
      installments: {
        amount:
          (quotePaymentPremiums.monthlyPayment.installments?.amount ?? 0) +
          addOnPaymentPremiums.monthlyInstallmentPremium * sign,
        dates: quotePaymentPremiums.monthlyPayment.installments?.dates,
      },
      finalInstallment: {
        amount:
          (quotePaymentPremiums.monthlyPayment.finalInstallment?.amount ?? 0) +
          addOnPaymentPremiums.monthlyFinalInstallmentPremium * sign,
        date: quotePaymentPremiums.monthlyPayment.finalInstallment?.date,
      },
    },
  };
};

const getBreakdownCover = (
  covers: BreakdownCover[],
  breakdown: BreakdownCoverLevel | null
): BreakdownCover | undefined => covers.find((cover) => cover.type === breakdown);

const updatePaymentPremiumsWithCoverage = (
  selectedTierOption: TierOptions | null,
  localCoverageSelection: CoverageSelection,
  apiCoveragesData: AddOn[],
  paymentPremiumsWithApiCoverageSelection: PaymentPremiums
): PaymentPremiums => {
  const apiCoverageData = apiCoveragesData.find(
    (coverage) => coverage.type === localCoverageSelection.type
  );

  if (!apiCoverageData) {
    return paymentPremiumsWithApiCoverageSelection;
  }

  // The payment premiums include the price of any committed coverage, so we first subtract the cost
  // if it is committed or included in the tier to get the price without the coverage, and then add
  // the price when selected locally or on aggregator.
  const isCoveragePriceInPaymentPremium =
    apiCoverageData.isSelected === true ||
    isCoverageIncludedInBasePrice(selectedTierOption, localCoverageSelection);

  const paymentPremiumsWithCoverageNotIncluded = isCoveragePriceInPaymentPremium
    ? updatePaymentPremiumsWithAddon(
        apiCoverageData,
        paymentPremiumsWithApiCoverageSelection,
        PaymentPremiumsOperators.Subtract
      )
    : paymentPremiumsWithApiCoverageSelection;

  const shouldIncludeCoveragePrice =
    localCoverageSelection.isSelected ||
    (localCoverageSelection.isSelected === null &&
      apiCoverageData?.isSelectedOnAggregator === true) ||
    isCoverageIncludedInBasePrice(selectedTierOption, localCoverageSelection);

  return shouldIncludeCoveragePrice
    ? updatePaymentPremiumsWithAddon(
        apiCoverageData,
        paymentPremiumsWithCoverageNotIncluded,
        PaymentPremiumsOperators.Add
      )
    : paymentPremiumsWithCoverageNotIncluded;
};

const updatePaymentPremiumsWithCoverages = (
  selectedTierOption: TierOptions | null,
  localCoverageSelections: CoverageSelection[] | undefined,
  apiCoveragesData: AddOn[],
  paymentPremiumsWithApiCoverageSelections: PaymentPremiums
): PaymentPremiums => {
  if (!localCoverageSelections) {
    return paymentPremiumsWithApiCoverageSelections;
  }

  return localCoverageSelections.reduce(
    (currentPaymentPremiums, coverageSelection) =>
      updatePaymentPremiumsWithCoverage(
        selectedTierOption,
        coverageSelection,
        apiCoveragesData,
        currentPaymentPremiums
      ),
    paymentPremiumsWithApiCoverageSelections
  );
};

const getBreakdownCoverIncludedInPrice = (
  selectedTierOption: TierOptions | null,
  quoteBreakdown: SelectedBreakdown
): BreakdownCoverLevel | null => {
  if (quoteBreakdown.isSelected === true) {
    return quoteBreakdown.coverLevel;
  }

  if (selectedTierOption === TierOptions.Extra) {
    return BreakdownCoverLevel.Roadside;
  }

  return null;
};

const getAddedBreakdownCoverLevel = (
  selectedTierOption: TierOptions | null,
  localBreakdownSelection: LocalBreakdown,
  apiBreakdownSelection: SelectedBreakdown
): BreakdownCoverLevel | null => {
  if (localBreakdownSelection.isSelected === true) {
    return localBreakdownSelection.coverLevel ?? null;
  }

  if (localBreakdownSelection.isSelected === false) {
    // If we've rejected the breakdown locally but are on Extra tier, we still need to include
    // the breakdown price. We include Breakdown1 as that's the default. This ignores which
    // breakdown is selected on the aggregator
    if (selectedTierOption === TierOptions.Extra) {
      return BreakdownCoverLevel.Roadside;
    }
    return null;
  }

  if (apiBreakdownSelection.isSelectedOnAggregator) {
    return apiBreakdownSelection.coverLevel;
  }

  if (selectedTierOption === TierOptions.Extra) {
    return BreakdownCoverLevel.Roadside;
  }

  return null;
};

const updatePaymentPremiumsWithBreakdown = (
  selectedTierOption: TierOptions | null,
  localBreakdownSelection: LocalBreakdown,
  apiBreakdownSelection: SelectedBreakdown,
  apiBreakdownData: BreakdownCover[],
  paymentPremiumsWithApiBreakdownSelection: PaymentPremiums
): PaymentPremiums => {
  const apiSelectedBreakdownCover = getBreakdownCover(
    apiBreakdownData,
    getBreakdownCoverIncludedInPrice(selectedTierOption, apiBreakdownSelection)
  );

  // The payment premiums include the price of any committed breakdown, so we first subtract the cost
  // of the breakdown that is comitted or included in the tier to get the price without the breakdown,
  // and then add the price when selected locally or on aggregator
  const paymentPremiumsWithBreakdownNotIncluded = apiSelectedBreakdownCover
    ? updatePaymentPremiumsWithAddon(
        apiSelectedBreakdownCover,
        paymentPremiumsWithApiBreakdownSelection,
        PaymentPremiumsOperators.Subtract
      )
    : paymentPremiumsWithApiBreakdownSelection;

  const localSelectedBreakdownCover = getBreakdownCover(
    apiBreakdownData,
    getAddedBreakdownCoverLevel(
      selectedTierOption,
      localBreakdownSelection,
      apiBreakdownSelection
    )
  );

  return localSelectedBreakdownCover
    ? updatePaymentPremiumsWithAddon(
        localSelectedBreakdownCover,
        paymentPremiumsWithBreakdownNotIncluded,
        PaymentPremiumsOperators.Add
      )
    : paymentPremiumsWithBreakdownNotIncluded;
};

const updatePaymentDetails = (
  paymentDetailsToUpdate: PaymentDetails,
  updatedPaymentPremiums: PaymentPremiums
): PaymentDetails => ({
  ...paymentDetailsToUpdate,
  annualPayment: updatedPaymentPremiums.annualPayment,
  monthlyPayment: {
    ...paymentDetailsToUpdate.monthlyPayment,
    deposit: updatedPaymentPremiums.monthlyPayment.deposit,
    total: updatedPaymentPremiums.monthlyPayment.total,
    installments: updatedPaymentPremiums.monthlyPayment.installments,
    finalInstallment: updatedPaymentPremiums.monthlyPayment.finalInstallment,
  },
});

/**
 * A method for getting the updated payment details for a quote based off the existing quote and the
 * local add-on selection, including prices for any unconfirmed add-ons selected on the aggregator.
 *
 * @param localSelection The local add-on selection to use for calculating the new prices
 * @param apiQuote The quote gotten from the API, which contains the prices NOT including those selected
 * on aggregator
 *
 * @returns The payment details corresponding to the add-on selection, which includes prices for any
 * unconfirmed add-ons selected on the aggregator.
 */
export const updatePremiumUsingLocalAddOnSelection = (
  localSelection: AddonSelection,
  apiQuote: Quote | Renewal
): PaymentDetails => {
  const selectedTierOption = getLatestTierSelection(
    localSelection.localSelectedTier,
    apiQuote.tieringInfo?.selectedTier
  );

  const selectedTier =
    selectedTierOption && apiQuote.tieringInfo
      ? getTieredData(selectedTierOption, apiQuote.tieringInfo.tiers)
      : undefined;

  const paymentPremiumsFromApi = selectedTier
    ? selectedTier.paymentDetails
    : apiQuote.paymentDetails;

  const paymentPremiumsWithUpdatedCoverages = updatePaymentPremiumsWithCoverages(
    selectedTierOption,
    localSelection.localCoverages,
    selectedTier ? selectedTier.coverages : apiQuote.coverages,
    paymentPremiumsFromApi
  );

  const paymentPremiumsWithUpdatedCoveragesAndBreakdown = updatePaymentPremiumsWithBreakdown(
    selectedTierOption,
    localSelection.localBreakdown,
    apiQuote.breakdown.selectedBreakdown,
    selectedTier ? selectedTier.breakdownCovers : apiQuote.breakdown.covers,
    paymentPremiumsWithUpdatedCoverages
  );

  return updatePaymentDetails(
    apiQuote.paymentDetails,
    paymentPremiumsWithUpdatedCoveragesAndBreakdown
  );
};

export type PremiumCalculator = (localSelection: AddonSelection) => PaymentDetails | null;

/**
 * A hook providing a method for calculating payment details from an add-on selection.
 *
 * @returns A function for getting the updated payment details for a quote based off the existing quote and the
 * local add-on selection, including prices for any unconfirmed add-ons selected on the aggregator.
 */
const usePremiumCalculationHelper = (): PremiumCalculator => {
  const quote = useSelector((state: RootState) => state.quote);

  return useCallback(
    (localSelection: AddonSelection): PaymentDetails | null =>
      quote ? updatePremiumUsingLocalAddOnSelection(localSelection, quote) : null,
    [quote]
  );
};

export default usePremiumCalculationHelper;
