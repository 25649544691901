import sessionClient from 'api/sessionClient';
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import useResetReduxState from 'helpers/useResetReduxState';
import {
  IDLE_TIMER_DEBOUNCE_IN_MS,
  TOTAL_SESSION_EXPIRY_TIMEOUT_IN_SECONDS,
} from '../config';

const SilentSessionExpiryWrapper: React.FC = ({ children }) => {
  const resetReduxState = useResetReduxState();
  const expireSession = async (): Promise<void> => {
    await sessionClient.expireSession();
    resetReduxState();
  };
  useIdleTimer({
    timeout: TOTAL_SESSION_EXPIRY_TIMEOUT_IN_SECONDS * 1000,
    onIdle: expireSession,
    debounce: IDLE_TIMER_DEBOUNCE_IN_MS,
  });

  return <>{children}</>;
};

export default SilentSessionExpiryWrapper;
