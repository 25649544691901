import {
  ActionCard,
  ActionCardGrid,
} from '@rsa-digital/evo-shared-components/components/ActionCard';
import { graphql } from 'gatsby';
import React from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { handleLinkClick } from 'helpers/navigation';
import { CsIcon } from 'types/contentStack';

type ActionCardsBlockProps = {
  action_cards: {
    action_cards: {
      heading: string;
      body: string | null;
      action_link: string;
      icon: [CsIcon] | [];
    }[];
  };
};

export const query = graphql`
  fragment ActionCardsBlockError on cs__error_page_blocks {
    action_cards {
      action_cards {
        heading
        body
        action_link
        icon {
          icon_code
        }
      }
    }
  }
  fragment ActionCardsBlockSessionExpiredPage on cs__session_expired_page_blocks {
    action_cards {
      action_cards {
        heading
        body
        action_link
        icon {
          icon_code
        }
      }
    }
  }
  fragment ActionCardsBlockPolicyManagement on cs__policy_management_blocks {
    action_cards {
      action_cards {
        heading
        body
        action_link
        icon {
          icon_code
        }
      }
    }
  }
`;

const ActionCardsBlock: React.FC<ActionCardsBlockProps> = ({ action_cards }) => (
  <ActionCardGrid data-cy="ActionCards">
    {action_cards.action_cards.map(({ heading, body, action_link, icon }, index) => (
      <ActionCard
        data-cy={`ActionCard${index}`}
        key={heading}
        heading={heading}
        body={body ?? undefined}
        href={action_link}
        icon={unwrapSingleton(icon)?.icon_code}
        onClick={handleLinkClick(action_link)}
      />
    ))}
  </ActionCardGrid>
);

export default React.memo(ActionCardsBlock);
