import { dateToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { QuoteMotoringConviction } from 'api/quote/quoteRequest';
import { MotoringConviction } from '../../../state/formData/shared/motoringConviction';

const mapConviction = (driverClaim: QuoteMotoringConviction): MotoringConviction =>
  ({
    convictionDate: dateToDateValue(new Date(driverClaim.convictionDate)),
    pendingConviction: driverClaim.convictionPending,
    lengthOfBan: driverClaim.lengthOfBan.toString(),
    penaltyPoints: driverClaim.penaltyPoints.toString(),
    offenceType: driverClaim.typeOfOffence,
  } as MotoringConviction);

export default mapConviction;
