import React from 'react';
import QuoteAndBuyProgressBar from 'components/QuoteAndBuyProgressBar';
import { trackButtonClick } from 'helpers/eventTracking';
import { OptionalShadowHeader } from './styles';
import { useHeaderData } from './useHeaderData';

type QuoteAndBuyHeaderProps = {
  currentStep?: number;
};

const QuoteAndBuyHeader: React.FC<QuoteAndBuyHeaderProps> = ({ currentStep }) => {
  const headerData = useHeaderData();

  const trackedLogoLink = {
    ...headerData.logoLink,
    link: {
      ...headerData.logoLink.link,
      onClick: () => trackButtonClick('header', 'logoLink'),
    },
  };

  return (
    <>
      <OptionalShadowHeader
        logoLink={trackedLogoLink}
        data-cy="quoteAndBuy header"
        displayShadow={!currentStep}
      />
      {currentStep && <QuoteAndBuyProgressBar currentStep={currentStep} />}
    </>
  );
};

export default QuoteAndBuyHeader;
