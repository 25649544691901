import { FieldErrors } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import debounce from 'lodash/debounce';
import { version } from '../../package.json';

declare global {
  interface Window {
    dataLayer?: TrackingEvent[];
  }
}

// Max length of a label to include
const MAX_LENGTH = 100;

export type TrackingEvent = {
  event: string;
  eventCategory?: string | undefined;
  eventAction?: string | undefined;
  eventLabel?: string | undefined;
  statusCode?: number | undefined;
  vpvPath?: string;
  dimension6?: string;
  pageTitle?: string;
  siteVersion?: string;
  isException?: boolean;
  reCaptchaAnswer?: 'Success' | 'Failure';
  reCaptchaScore?: number;
  errorDescription?: string;
};

export const trackEvent = (event: TrackingEvent): void => {
  if (event.eventLabel) {
    // eslint-disable-next-line no-param-reassign
    event.eventLabel = event.eventLabel.substring(0, MAX_LENGTH);
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

export const trackPageView = (
  path: string,
  pageTitle: string,
  errorDescription?: string,
  statusCode?: number,
  dimension6?: string
): void => {
  trackEvent({
    event: 'newPage',
    vpvPath: path,
    dimension6,
    pageTitle,
    siteVersion: version,
    statusCode,
    errorDescription,
    // Explicitly flush other variables
    eventCategory: undefined,
    eventAction: undefined,
    eventLabel: undefined,
    isException: undefined,
  });
};

export const trackIconClick = (label: string): void => {
  trackEvent({
    event: 'iconClick',
    eventCategory: 'Icon',
    eventAction: 'Click',
    eventLabel: `${label}`,
  });
};

export const trackButtonClick = (style: string, label: string): void => {
  trackEvent({
    event: 'buttonClick',
    eventCategory: 'Button',
    eventAction: 'Click',
    eventLabel: `${style} - ${label}`,
  });
};

export const trackTextInputFocus = (label: string) => (): void => {
  trackEvent({
    event: 'formField',
    eventCategory: 'Form Field',
    eventAction: 'Focus',
    eventLabel: label,
  });
};

export const trackSelectOrTypeaheadFocus = (label: string) => (): void => {
  trackEvent({
    event: 'primarySelector',
    eventCategory: 'Selector Primary',
    eventAction: 'Select',
    eventLabel: label,
  });
};

// The typeahead library typically triggers onChange twice. We use debounce to ensure the
// event is only tracked once
export const trackTypeaheadFocusWithDebounce = (label: string): (() => void) =>
  debounce(trackSelectOrTypeaheadFocus(label), 100);

export const trackRadioButtonClick = (label: string, value: string): void => {
  trackEvent({
    event: 'radioButtonClick',
    eventCategory: 'Radio Button',
    eventAction: 'Select',
    eventLabel: `${label} - ${value}`,
  });
};

export const trackDropdownSelect = (label: string, value: string): void => {
  trackEvent({
    event: 'dropdownSelector',
    eventCategory: 'Selector Dropdown',
    eventAction: 'Select',
    eventLabel: `${label} - ${value}`,
  });
};

export const trackTooltipClick = (
  eventAction: string,
  linkLabel: string,
  fieldLabel: string
): void => {
  trackEvent({
    event: 'tooltipClick',
    eventCategory: 'Tooltip',
    eventAction,
    eventLabel: `${linkLabel} - ${fieldLabel}`,
  });
};

export const trackCalendarClick = (label: string): void => {
  trackEvent({
    event: 'calendar',
    eventCategory: 'Calendar',
    eventAction: 'Click',
    eventLabel: label,
  });
};

export const trackFormErrors = (errors: FieldErrors): void => {
  trackEvent({
    event: 'formErrors',
    eventCategory: 'Form Field',
    eventAction: 'Error',
    eventLabel: Object.keys(errors).join(','),
  });
};

export const trackFieldError = (fieldName: string, errorName: string): void => {
  trackEvent({
    event: 'fieldError',
    isException: false,
    eventCategory: 'Form Field',
    eventAction: 'Error',
    eventLabel: `${fieldName} - ${errorName}`,
  });
};

export const trackSystemError = (errorCode: number, label: string): void => {
  trackEvent({
    event: 'systemError',
    eventAction: 'Error',
    eventLabel: `${errorCode} - ${label}`,
    statusCode: errorCode,
  });
};

export const trackIneligible = (errorCode: number, label: string): void => {
  trackEvent({
    event: 'ineligibleError',
    eventAction: 'Declined',
    eventLabel: `${errorCode} - ${label}`,
    statusCode: errorCode,
  });
};

export const trackAPIError = (errorCode: number, label: string): void => {
  trackEvent({
    event: 'APIerror',
    eventAction: 'Error',
    eventLabel: `${errorCode} - ${label}`,
    statusCode: errorCode,
  });
};

export const trackModalOpen = (label: string): void => {
  trackEvent({
    event: 'modalWindow',
    eventCategory: 'Modal',
    eventAction: 'Appear',
    eventLabel: label,
  });
};

export const trackAccordionExpand = (label: string) => (): void => {
  trackEvent({
    event: 'accordionClick',
    eventCategory: 'Accordion',
    eventAction: 'Expand',
    eventLabel: label,
  });
};

export const trackAccordionCollapse = (label: string) => (): void => {
  trackEvent({
    event: 'accordionClick',
    eventCategory: 'Accordion',
    eventAction: 'Collapse',
    eventLabel: label,
  });
};

export const trackPopUp = (label: string): void => {
  trackEvent({
    event: 'popUpAppearance',
    eventCategory: 'Pop Up',
    eventAction: 'Triggered',
    eventLabel: label,
  });
};

export const trackReCaptchaScore = (
  reCaptchaStatus: 'Success' | 'Failure',
  reCaptchaScore: number
): void => {
  trackEvent({
    event: 'recaptcha',
    reCaptchaAnswer: reCaptchaStatus,
    reCaptchaScore,
  });
};

export const trackPaysafeError = (): void => {
  trackEvent({
    event: 'paysafeTokenize',
    eventAction: 'Error',
    eventLabel: 'Failed to tokenize',
  });
};

export const trackAlertBanner = (label: string): void => {
  trackEvent({
    event: 'alertBanner',
    eventCategory: 'Alert Banner',
    eventAction: 'Triggered',
    eventLabel: label,
  });
};

export const trackPlaintextLink = (
  style: string,
  label: string,
  action: string
): void => {
  trackEvent({
    event: 'plaintextLink',
    eventCategory: 'Plaintext Link',
    eventAction: action,
    eventLabel: `${style} - ${label}`,
  });
};

export const trackRecognisedCustomer = (): void => {
  trackEvent({
    event: 'renewalCustomerRecognised',
    eventCategory: 'quote summary',
    eventAction: 'customer status',
    eventLabel: 'quote summary - policy due for renewal',
  });
};
