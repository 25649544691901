import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { AdditionalDriver, initialAdditionalDriver } from '../additionalDriversDetails';

export const UPDATE_MTA_ADD_DRIVER_DETAILS = 'UPDATE_MTA_ADD_DRIVER_DETAILS';

export type UpdateMtaAddDriverAction = {
  type: typeof UPDATE_MTA_ADD_DRIVER_DETAILS;
  update: Partial<AddDriverMtaDetails>;
};

export type AddDriverMtaDetails = AdditionalDriver;

export const initialAddDriverMtaDetails: AddDriverMtaDetails = initialAdditionalDriver;

export const useAddDriverMtaDetails = (): [
  AddDriverMtaDetails,
  (update: Partial<AddDriverMtaDetails>) => void
] => {
  const mtaState = useSelector((state: RootState) => state.mtaState);
  const dispatch = useDispatch();

  const updateMtaFormState = useCallback(
    (update: Partial<AddDriverMtaDetails>): void => {
      dispatch({ type: UPDATE_MTA_ADD_DRIVER_DETAILS, update });
    },
    [dispatch]
  );

  return [mtaState.addDriver, updateMtaFormState];
};
