import SingleSectionHeader from '@rsa-digital/evo-shared-components/components/Header/SingleSectionHeader';
import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import React from 'react';
import { trackButtonClick } from 'helpers/eventTracking';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { useHeaderData } from './useHeaderData';

const SelfServeHeader: React.FC<{ hideAccountMenu?: boolean }> = ({
  hideAccountMenu,
}) => {
  const { requestHandler, isLoading, loadingMessage } = useApiRequestHandler();
  const { logoLink, navItem } = useHeaderData(requestHandler, hideAccountMenu);

  return (
    <>
      {isLoading && (
        <LoadingOverlay loadingMessage={loadingMessage || 'Fetching account data'} />
      )}
      <SingleSectionHeader
        logoLink={logoLink}
        navItem={navItem}
        onOpen={() => trackButtonClick('selfServeHeader', `${navItem?.name} (open)`)}
        onClose={() => trackButtonClick('selfServeHeader', `${navItem?.name} (close)`)}
        data-cy="selfServe header"
      />
    </>
  );
};

export default SelfServeHeader;
