import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import ImageComponent from '@rsa-digital/evo-shared-components/components/Image';
import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/Layout';
import { SessionExpiryOption } from 'components/SessionExpiryWrapper';
import { CsBlock, mapBlocks } from 'helpers/blockMapper';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { usePageTracking } from 'helpers/usePageTracking';
import { ErrorType } from 'state/error/actions';
import { CsAsset } from 'types/contentStack';
import {
  Container,
  DesktopHeadingItem,
  Heading,
  MobileHeadingItem,
  Subhead,
} from './styles';

export type ErrorPageProps = {
  data: ErrorPageData;
  errorType: ErrorType | 'MAINTENANCE';
  statusCode: number;
  sessionExpiryOption?: SessionExpiryOption;
};

export type ErrorPageData = {
  csErrorPage: {
    meta_title: string;
    heading: string;
    subhead?: string;
    image: CsAsset | null;
    blocks: CsBlock[];
  };
};

export const query = graphql`
  fragment ErrorPage on cs__error_page {
    meta_title
    heading
    subhead
    image {
      ...CsAsset
    }
    blocks {
      ...IntroBlockError
      ...ActionCardsBlockError
      ...CtaBannerBlockError
      ...ProductCardsBlockError
      ...AccordionBlockError
    }
  }
`;

const ErrorPage: React.FC<ErrorPageProps> = ({
  data,
  errorType,
  statusCode,
  sessionExpiryOption = SessionExpiryOption.DEFAULT_SESSION_EXPIRY,
}) => {
  const page = data.csErrorPage;
  const processedImage = processImageAsset(page.image);
  const headings = (
    <>
      <Heading data-cy="ErrorHeading">{page.heading}</Heading>
      {page.subhead && <Subhead>{page.subhead}</Subhead>}
    </>
  );

  usePageTracking(page.meta_title, true, statusCode, errorType);

  return (
    <Layout
      meta={{ meta_title: page.meta_title }}
      pageType="error"
      sessionExpiryOption={sessionExpiryOption}>
      {processedImage ? (
        <Container>
          <MobileHeadingItem>{headings}</MobileHeadingItem>
          <GridItem desktop={7} tabletLandscape={7}>
            <ImageComponent image={processedImage} />
          </GridItem>
          <DesktopHeadingItem desktop={5} tabletLandscape={5}>
            {headings}
          </DesktopHeadingItem>
        </Container>
      ) : (
        headings
      )}
      {page.blocks && mapBlocks(page.blocks, { useCompactProductCards: true })}
    </Layout>
  );
};

export default ErrorPage;
