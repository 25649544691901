import { useLocation } from '@reach/router';
import { useEffect, useState } from 'react';
import { trackPageView } from './eventTracking';
import usePageTitle from './usePageTitle';

const getErrorDescription = (
  statusCode?: number,
  errorType?: string
): string | undefined => {
  if (errorType && statusCode) {
    return `${statusCode} - ${errorType}`;
  }
  return undefined;
};

/** Hook to track once when the page has finished loading/redirecting
 *
 * Pass in pageLoaded as an object populated asynchronously/from state to ensure any
 * redirecting/erroring has happened before tracking a page view
 * example: !!quote is used for the quote summary page. If there was no quote,
 * the user would be redirected which should not fire a pageView event.
 */
export const usePageTracking = (
  pageTitle: string,
  pageLoaded = true,
  statusCode?: number,
  errorDescription?: string,
  dimension6?: string
): void => {
  const [tracked, setTracked] = useState(false);

  const fullPageTitle = usePageTitle(pageTitle);
  const currentPath = useLocation().pathname;

  const formattedErrorDescription = getErrorDescription(statusCode, errorDescription);

  useEffect(() => {
    if (!tracked && pageLoaded) {
      trackPageView(
        currentPath,
        fullPageTitle,
        formattedErrorDescription,
        statusCode,
        dimension6
      );
      setTracked(true);
    }
  }, [
    tracked,
    pageLoaded,
    currentPath,
    pageTitle,
    formattedErrorDescription,
    statusCode,
    fullPageTitle,
    dimension6,
  ]);
};
