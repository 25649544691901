import { mapApiOption } from 'api/referenceData/mapApiOption';
import { ApiOption, ReferenceDataOption } from 'types/referenceData';

export type OccupationsReferenceDataResponse = {
  'DriverInput.PrimaryOccupation': ApiOption[];
  'DriverInput.PrimaryOccupationIndustry': ApiOption[];
};

export type OccupationsReferenceData = {
  occupation: ReferenceDataOption[];
  occupationIndustry: ReferenceDataOption[];
};

export const mapOccupationsRefData = (
  data: OccupationsReferenceDataResponse
): OccupationsReferenceData => ({
  occupation: data['DriverInput.PrimaryOccupation'].map(mapApiOption),
  occupationIndustry: data['DriverInput.PrimaryOccupationIndustry'].map(mapApiOption),
});
