import { mapApiOption } from 'api/referenceData/mapApiOption';
import { ApiOption, ReferenceDataOption } from 'types/referenceData';

export type VehicleDetailsReferenceDataResponse = {
  'VehicleDetailInput.AnnualPersonalMileage': ApiOption[];
  'VehicleDetailInput.AnnualBusinessMileage': ApiOption[];
  'VehicleDetailInput.ThatchamAlarmOrImmobiliser': ApiOption[];
  'VehicleDetailInput.ProfessionallyFittedTracker': ApiOption[];
  'VehicleDetailInput.LegalOwnerOfVehicle': ApiOption[];
  'VehicleDetailInput.RegisteredKeeperOfVehicle': ApiOption[];
  'VehicleDetailInput.OwnershipOfCarLength': ApiOption[];
  'VehicleDetailInput.VehicleKeptOvernight': ApiOption[];
  'VehicleDetailInput.CarKeptDuringDay': ApiOption[];
  'VehicleDetailInput.CurrentNoClaimsBonus': ApiOption[];
  'VehicleDetailInput.LengthHeldCompanyCar': ApiOption[];
  'ManualSearchOutput.VehicleMakes': ApiOption[];
};

export type VehicleDetailsReferenceData = {
  annualPersonalMileage: ReferenceDataOption[];
  annualBusinessMileage: ReferenceDataOption[];
  thatchamAlarmOrImmobiliser: ReferenceDataOption[];
  professionallyFittedTracker: ReferenceDataOption[];
  legalOwnerOfVehicle: ReferenceDataOption[];
  registeredKeeperOfVehicle: ReferenceDataOption[];
  ownershipOfCarLength: ReferenceDataOption[];
  vehicleKeptOvernight: ReferenceDataOption[];
  carKeptDuringDay: ReferenceDataOption[];
  currentNoClaimsBonus: ReferenceDataOption[];
  lengthHeldCompanyCar: ReferenceDataOption[];
  vehicleMakes: ReferenceDataOption[];
};

export const mapVehicleDetailsRefData = (
  data: VehicleDetailsReferenceDataResponse
): VehicleDetailsReferenceData => ({
  annualPersonalMileage: data['VehicleDetailInput.AnnualPersonalMileage'].map(
    mapApiOption
  ),
  annualBusinessMileage: data['VehicleDetailInput.AnnualBusinessMileage'].map(
    mapApiOption
  ),
  thatchamAlarmOrImmobiliser: data['VehicleDetailInput.ThatchamAlarmOrImmobiliser'].map(
    mapApiOption
  ),
  professionallyFittedTracker: data['VehicleDetailInput.ProfessionallyFittedTracker'].map(
    mapApiOption
  ),
  legalOwnerOfVehicle: data['VehicleDetailInput.LegalOwnerOfVehicle'].map(mapApiOption),
  registeredKeeperOfVehicle: data['VehicleDetailInput.RegisteredKeeperOfVehicle'].map(
    mapApiOption
  ),
  ownershipOfCarLength: data['VehicleDetailInput.OwnershipOfCarLength'].map(mapApiOption),
  vehicleKeptOvernight: data['VehicleDetailInput.VehicleKeptOvernight'].map(mapApiOption),
  carKeptDuringDay: data['VehicleDetailInput.CarKeptDuringDay'].map(mapApiOption),
  currentNoClaimsBonus: data['VehicleDetailInput.CurrentNoClaimsBonus'].map(mapApiOption),
  lengthHeldCompanyCar: data['VehicleDetailInput.LengthHeldCompanyCar'].map(mapApiOption),
  vehicleMakes: data['ManualSearchOutput.VehicleMakes'].map(mapApiOption),
});
