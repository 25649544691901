import { graphql, useStaticQuery } from 'gatsby';

const titleQuery = graphql`
  query {
    csSiteWideSeo {
      title
    }
    csMotorGlobalConfig {
      meta_title
    }
  }
`;

type MetaTitleGlobalConifg = {
  csSiteWideSeo: { title: string };
  csMotorGlobalConfig: { meta_title: string };
};

const usePageTitle = (pageTitle: string): string => {
  const titleData = useStaticQuery<MetaTitleGlobalConifg>(titleQuery);
  return `${pageTitle} ${titleData.csMotorGlobalConfig.meta_title} ${titleData.csSiteWideSeo.title}`;
};

export default usePageTitle;
