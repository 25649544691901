import quoteProgressClient from 'api/quoteInProgressClient';
import {
  call,
  CallEffect,
  debounce,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
} from 'redux-saga/effects';
import { isAxiosError } from 'helpers/axiosResponseHelpers';
import logApiError from 'helpers/logApiError';
import { RootState } from 'state/createStore';
import { ErrorType, UPDATE_ERROR, UpdateErrorAction } from 'state/error/actions';
import { Quote } from 'state/quote/quote';
import { UPDATE_QUOTE_PARAMETERS } from 'state/quoteParameters/quoteParameters';
import { UPDATE_ADDITIONAL_DRIVERS_DETAILS } from './additionalDriversDetails';
import { UPDATE_COVER_DETAILS } from './coverDetails';
import { UPDATE_DRIVER_DETAILS } from './driverDetails';
import { QuoteInProgress } from './quoteInProgress';
import { UPDATE_VEHICLE_DETAILS } from './vehicleDetails';

const QUOTE_IN_PROGRESS_DEBOUNCE_TIME_MS = 4000;

function* updateQuoteInProgress(): Generator<
  SelectEffect | CallEffect<void> | PutEffect<UpdateErrorAction>
> {
  // QuoteInProgress is only for use before the quote has been generated - if there is a quote
  // loaded then we should NOT be syncing this data. (e.g. we don't want a saved quote's data
  // to stomp the quote in progress).
  const quote = yield select((state: RootState): Quote | null => state.quote);
  if (quote) {
    return;
  }
  const quoteProgress = yield select(
    (state: RootState): QuoteInProgress => ({
      vehicleDetails: state.vehicleDetails,
      policyDetails: state.coverDetails,
      driverDetails: state.driverDetails,
      additionalDriversDetails: state.additionalDriversDetails,
      quoteParameters: state.quoteParameters,
    })
  );
  try {
    yield call(() =>
      quoteProgressClient.saveQuoteInProgress(quoteProgress as QuoteInProgress)
    );
  } catch (error) {
    if (isAxiosError(error)) {
      logApiError(error);
    } else {
      console.error('Error when making API request: ', error.message);
    }
    const errorAction: UpdateErrorAction = {
      type: UPDATE_ERROR,
      errorType: ErrorType.API_ERROR,
      statusCode: error.response?.status,
    };
    yield put(errorAction);
  }
}

function* handleQuoteInProgress(): Generator<ForkEffect<never>> {
  yield debounce(
    QUOTE_IN_PROGRESS_DEBOUNCE_TIME_MS,
    [
      UPDATE_COVER_DETAILS,
      UPDATE_DRIVER_DETAILS,
      UPDATE_VEHICLE_DETAILS,
      UPDATE_ADDITIONAL_DRIVERS_DETAILS,
      UPDATE_QUOTE_PARAMETERS,
    ],
    updateQuoteInProgress
  );
}

export default handleQuoteInProgress;
