import { navigate } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import QuoteEarnixErrorPage from 'templates/ErrorPage/400-earnix-error-quote';
import QuoteExpiredErrorPage from 'templates/ErrorPage/400-expired-quote';
import QuoteFraudulentErrorPage from 'templates/ErrorPage/400-fraudulent-quote';
import ReCaptchaFailureErrorPage from 'templates/ErrorPage/401-recaptcha-failure';
import NotAuthorisedPage from 'templates/ErrorPage/403';
import AccountUnavailableErrorPage from 'templates/ErrorPage/403-account-unavailable';
import NotFoundPage from 'templates/ErrorPage/404';
import QuoteNotFoundErrorPage from 'templates/ErrorPage/404-quote-not-found';
import UnknownErrorPage from 'templates/ErrorPage/500';
import { trackAPIError, trackIneligible, trackSystemError } from 'helpers/eventTracking';
import {
  accountRoutes,
  mtaRoutes,
  quoteAndBuyRoutes,
  renewalRoutes,
} from 'helpers/routingHelper';
import { RootState } from 'state/createStore';
import { ErrorType, RESET_ERROR } from 'state/error/actions';
import { UPDATE_USER } from 'state/user/state';

type Props = {
  children: React.ReactNode;
  location: Location;
  reduxError?: ErrorType;
  statusCode?: number;
} & DispatchProps;

type DispatchProps = {
  resetErrorState: () => void;
  setUserLoggedOut: () => void;
};

type State = {
  thrownError: Error | null;
};

/**
 * This component catches any errors in its child component tree, logs those errors
 * to the console, and displays an error page instead.
 * https://reactjs.org/docs/error-boundaries.html
 *
 * This must be a class component as hooks don't support error boundaries
 */
/* istanbul ignore next */
class ErrorBoundary extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { thrownError: null };
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    const {
      location,
      reduxError,
      statusCode,
      resetErrorState,
      setUserLoggedOut,
    } = this.props;
    // Clear error if the location changes
    if (location.pathname !== prevProps.location.pathname) {
      if (prevState.thrownError) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          thrownError: null,
        });
      }
      resetErrorState();
    }
    if (reduxError === ErrorType.API_ERROR && statusCode === 401) {
      setUserLoggedOut();
      trackAPIError(statusCode, ErrorType.UNAUTHORIZED);
      navigate(accountRoutes.login);
      resetErrorState();
    }
    if (reduxError === ErrorType.QUOTE_INELIGIBLE && statusCode === 400) {
      trackIneligible(statusCode ?? 400, ErrorType.QUOTE_INELIGIBLE);
      navigate(quoteAndBuyRoutes.ineligibleQuote);
      resetErrorState();
    }
    if (reduxError === ErrorType.QUOTE_TIER_INVALID) {
      trackIneligible(statusCode ?? 400, ErrorType.QUOTE_TIER_INVALID);
      navigate(quoteAndBuyRoutes.ineligibleTier);
      resetErrorState();
    }
    if (reduxError === ErrorType.MTA_QUOTE_INELIGIBLE) {
      trackIneligible(statusCode ?? 400, ErrorType.MTA_QUOTE_INELIGIBLE);
      navigate(mtaRoutes.ineligibleQuote);
      resetErrorState();
    }
    if (reduxError === ErrorType.MTA_TIER_INVALID) {
      trackIneligible(statusCode ?? 400, ErrorType.MTA_TIER_INVALID);
      navigate(mtaRoutes.ineligibleTier);
      resetErrorState();
    }
    if (reduxError === ErrorType.RENEWAL_QUOTE_INELIGIBLE) {
      trackIneligible(statusCode ?? 400, ErrorType.RENEWAL_QUOTE_INELIGIBLE);
      navigate(renewalRoutes.ineligibleQuote);
      resetErrorState();
    }
  }

  public static getDerivedStateFromError(error: Error): State {
    return { thrownError: error };
  }

  // eslint-disable-next-line class-methods-use-this
  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    console.error('Caught error: ', error);
    console.error('Component Trace: ', info.componentStack);
  }

  public render(): React.ReactNode {
    const { thrownError }: State = this.state;
    const { children, reduxError, statusCode }: Props = this.props;

    if (!thrownError && !reduxError) {
      return children;
    }

    switch (reduxError) {
      case ErrorType.ACCOUNT_UNAVAILABLE:
        trackAPIError(statusCode ?? 403, ErrorType.ACCOUNT_UNAVAILABLE);
        return <AccountUnavailableErrorPage />;
      case ErrorType.API_ERROR:
        switch (statusCode) {
          case 401:
            // The navigation to login in this case is handled by the componentDidUpdate block.
            return children;
          case 403:
            trackAPIError(403, ErrorType.FORBIDDEN);
            return <NotAuthorisedPage />;
          case 404: {
            trackAPIError(404, ErrorType.NOT_FOUND);
            return <NotFoundPage />;
          }
          default:
            trackAPIError(statusCode ?? 500, ErrorType.API_ERROR);
            return <UnknownErrorPage />;
        }
      case ErrorType.NOT_FOUND:
        trackSystemError(statusCode ?? 404, ErrorType.API_ERROR);
        return <NotFoundPage />;
      case ErrorType.QUOTE_INELIGIBLE:
        // The navigation in these cases is handled by the componentDidUpdate block.
        return children;
      case ErrorType.MTA_QUOTE_INELIGIBLE:
        // The navigation in these cases is handled by the componentDidUpdate block.
        return children;
      case ErrorType.RENEWAL_QUOTE_INELIGIBLE:
        // The navigation in these cases is handled by the componentDidUpdate block.
        return children;
      case ErrorType.QUOTE_EARNIX_ERROR:
        trackAPIError(statusCode ?? 400, ErrorType.QUOTE_EARNIX_ERROR);
        return <QuoteEarnixErrorPage />;
      case ErrorType.QUOTE_FRAUDULENT:
        trackAPIError(statusCode ?? 400, ErrorType.QUOTE_FRAUDULENT);
        return <QuoteFraudulentErrorPage />;
      case ErrorType.QUOTE_EXPIRED:
        trackAPIError(statusCode ?? 400, ErrorType.QUOTE_EXPIRED);
        return <QuoteExpiredErrorPage />;
      case ErrorType.QUOTE_NOT_FOUND:
        trackAPIError(statusCode ?? 404, ErrorType.QUOTE_NOT_FOUND);
        return <QuoteNotFoundErrorPage />;
      case ErrorType.RECAPTCHA_ERROR:
        trackAPIError(statusCode ?? 400, ErrorType.RECAPTCHA_ERROR);
        return <ReCaptchaFailureErrorPage />;
      default:
        trackSystemError(statusCode ?? 500, 'unknown');
        return <UnknownErrorPage />;
    }
  }
}

const mapStateToProps = (state: RootState): Pick<Props, 'reduxError' | 'statusCode'> => ({
  reduxError: state.error.errorType,
  statusCode: state.error.statusCode,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  resetErrorState: () => dispatch({ type: RESET_ERROR }),
  setUserLoggedOut: () => dispatch({ type: UPDATE_USER, user: { isLoggedIn: false } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
