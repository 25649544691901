import React from 'react';
import { CustomUserData } from 'helpers/customUserDataHelper';
import { booleanAttribute } from './booleanAttribute';

/**
 * AppDynamics integration works in three parts:
 * 1 - We set some config on the window that can be read by the scripts
 * 2 - Load adrum.js to bootstrap the agent
 * 3 - The agent will load adrum-ext.xxx.js asynchronously to begin instrumentation
 *
 * https://docs.appdynamics.com/display/PRO21/Inject+the+JavaScript+Agent
 */

declare global {
  interface Window {
    getCustomUserData?: () => CustomUserData;
    updateCustomUserData?: (customUserData: CustomUserData) => void;
  }
}

const script = `
window["adrum-start-time"] = new Date().getTime();
var customUserData = {};
function updateCustomUserData(data) {
  customUserData = data;
}
function getCustomUserData() {
  return customUserData;
}
window['adrum-config'] = {
  userEventInfo: {
    "Ajax": function (context) {
      return {
        userData: getCustomUserData(),
      }
    },
    "VPageView": function (context) {
      return {
        userData: getCustomUserData(),
      }
    }
  }
};
(function(config){
  config.appKey = "${process.env.GATSBY_APPDYNAMICS_KEY}";
  config.adrumExtUrlHttps = "${process.env.GATSBY_SITE_URL}/scripts";
  config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com";
  config.useHTTPSAlways = true;
  config.xd = {"enable":true};
  config.resTiming = {"bufSize":200,"clearResTimingOnBeaconSend":true};
  config.maxUrlLength = 512;
  config.spa = {"spa2":true};
  config.xhr = {"exclude":{"urls":[{"pattern": ".*\\.json"}]}};
})(window["adrum-config"] || (window["adrum-config"] = {}));
`;

const appDynamicsScripts: JSX.Element[] = [
  <script key="config">{script}</script>,
  <script
    className="optanon-category-C0002"
    type="text/plain"
    key="adrum"
    src="/scripts/adrum.js"
    async={booleanAttribute}
    defer={booleanAttribute}
  />,
];

export default process.env.GATSBY_APPDYNAMICS_KEY ? appDynamicsScripts : [];
