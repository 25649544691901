import { AxiosErrorWithResponse } from 'helpers/axiosResponseHelpers';
import { ErrorType, UPDATE_ERROR, UpdateErrorAction } from '../../../state/error/actions';

const INVALID_QUOTE_MAPPING: {
  errorCode: string;
  errorType: ErrorType;
}[] = [
  {
    errorCode: 'EVO_BUS_MM230',
    errorType: ErrorType.QUOTE_FRAUDULENT,
  },
  {
    errorCode: 'EVO_BUS_MM250',
    errorType: ErrorType.QUOTE_INELIGIBLE,
  },
  {
    errorCode: 'EVO_BUS_MM251',
    errorType: ErrorType.QUOTE_EARNIX_ERROR,
  },
  {
    errorCode: 'EVO_BUS_MM252',
    errorType: ErrorType.QUOTE_FRAUDULENT,
  },
  {
    errorCode: 'EVO_BUS_MM253',
    errorType: ErrorType.QUOTE_EXPIRED,
  },
];

export const isInvalidQuoteError = (error: AxiosErrorWithResponse): boolean =>
  error.response.status === 400 &&
  INVALID_QUOTE_MAPPING.some(
    (mapping) => error.response.data?.Code === mapping.errorCode
  );

export const handleInvalidQuote = (
  error: AxiosErrorWithResponse,
  dispatch: (value: UpdateErrorAction) => void
): void => {
  const invalidQuoteError = INVALID_QUOTE_MAPPING.find(
    (mapping) => mapping.errorCode === error.response.data?.Code
  );
  if (invalidQuoteError) {
    dispatch({
      type: UPDATE_ERROR,
      errorType: invalidQuoteError.errorType,
      statusCode: error.response.status,
    });
  }
};

// This is expected to occur when the charge plan has been manually set through the call centre.
export const isIneligibleQuoteTier = (error: AxiosErrorWithResponse): boolean =>
  error.response.status === 400 && error.response.data?.Code === 'EVO_BUS_MM137';
