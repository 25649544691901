import {
  InfoCard,
  InfoCardGrid,
} from '@rsa-digital/evo-shared-components/components/InfoCard';
import { graphql } from 'gatsby';
import React from 'react';
import {
  processImageAsset,
  processOptionalCta,
  unwrapSingleton,
} from 'helpers/csTypeProcessors';
import { InfoCardsBlockProps } from './types';

export const query = graphql`
  fragment InfoCardsBlockPolicyManagement on cs__policy_management_blocks {
    info_cards {
      info_cards {
        header_icon {
          icon_code
        }
        header_text
        image {
          ...CsAsset
        }
        body_text
        cta {
          display_text
          screen_reader_text
          url
        }
      }
    }
  }
`;

const InfoCardsBlock: React.FC<InfoCardsBlockProps> = ({ info_cards }) => (
  <InfoCardGrid data-cy="InfoCards">
    {info_cards.info_cards.map(
      ({ header_text, header_icon, body_text, cta, image }, index) => (
        <InfoCard
          data-cy={`InfoCard${index}`}
          key={header_text}
          headerIcon={unwrapSingleton(header_icon)?.icon_code}
          heading={header_text}
          body={body_text}
          cta={processOptionalCta(cta)}
          logo={processImageAsset(image)}
        />
      )
    )}
  </InfoCardGrid>
);

export default React.memo(InfoCardsBlock);
