import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore as reduxCreateStore,
  Store,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { ResetStateAction } from './actions';
import { UpdateAddonSelectionAction } from './addonsSelection/addonsSelection';
import { addonsSelectionReducer } from './addonsSelection/addonsSelectionReducer';
import { ResetErrorAction, UpdateErrorAction } from './error/actions';
import errorReducer from './error/reducer';
import { UpdateAdditionalDriversDetailsAction } from './formData/additionalDriversDetails';
import { additionalDriversDetailsReducer } from './formData/additionalDriversDetailsReducer';
import { UpdateCoverDetailsAction } from './formData/coverDetails';
import { coverDetailsReducer } from './formData/coverDetailsReducer';
import { UpdateDriverDetailsAction } from './formData/driverDetails';
import { driverDetailsReducer } from './formData/driverDetailsReducer';
import { UpdatePreferenceDetailsAction } from './formData/preferenceDetails';
import { preferenceDetailsReducer } from './formData/preferenceDetailsReducer';
import { UpdateQuoteInProgressAction } from './formData/quoteInProgress';
import quoteInProgressSaga from './formData/quoteInProgressSaga';
import { UpdateVehicleDetailsAction } from './formData/vehicleDetails';
import { vehicleDetailsReducer } from './formData/vehicleDetailsReducer';
import { mtaDataReducer, UpdateMtaStateActions } from './mta/mta';
import { mtaQuoteReducer, UpdateMtaQuoteAction } from './mta/mtaQuote';
import { policyDataReducer, UpdatePolicyAction } from './policy/policy';
import { quoteReducer, ResetQuoteAction, UpdateQuoteAction } from './quote/quote';
import { UpdateQuoteParametersAction } from './quoteParameters/quoteParameters';
import { quoteParametersReducer } from './quoteParameters/quoteParametersReducer';
import { ReferenceDataAction } from './referenceData/actions';
import referenceDataReducer from './referenceData/reducer';
import referenceDataSaga from './referenceData/saga';
import { userReducer } from './user/reducer';
import { UpdateUserAction } from './user/state';

const rootReducer = combineReducers({
  error: errorReducer,
  vehicleDetails: vehicleDetailsReducer,
  driverDetails: driverDetailsReducer,
  coverDetails: coverDetailsReducer,
  additionalDriversDetails: additionalDriversDetailsReducer,
  quote: quoteReducer,
  preferenceDetails: preferenceDetailsReducer,
  referenceData: referenceDataReducer,
  policyData: policyDataReducer,
  mtaQuote: mtaQuoteReducer,
  mtaState: mtaDataReducer,
  quoteParameters: quoteParametersReducer,
  user: userReducer,
  addonSelection: addonsSelectionReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type RootAction =
  | UpdateErrorAction
  | ResetErrorAction
  | UpdateAdditionalDriversDetailsAction
  | UpdateCoverDetailsAction
  | UpdateDriverDetailsAction
  | UpdateMtaStateActions
  | UpdateMtaQuoteAction
  | UpdatePolicyAction
  | UpdateVehicleDetailsAction
  | UpdateQuoteInProgressAction
  | UpdateQuoteAction
  | UpdatePreferenceDetailsAction
  | ReferenceDataAction
  | UpdateQuoteParametersAction
  | UpdateUserAction
  | ResetQuoteAction
  | ResetStateAction
  | UpdateAddonSelectionAction;

const createStore = (): Store<RootState, RootAction> => {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    (typeof window !== 'undefined' &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const store = reduxCreateStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(referenceDataSaga);
  sagaMiddleware.run(quoteInProgressSaga);
  return store;
};

export default createStore;
