import { RESET_STATE, ResetStateAction } from 'state/actions';
import {
  UPDATE_QUOTE_IN_PROGRESS,
  UpdateQuoteInProgressAction,
} from 'state/formData/quoteInProgress';
import { RESET_QUOTE, ResetQuoteAction } from 'state/quote/quote';
import {
  QuoteParameters,
  UPDATE_QUOTE_PARAMETERS,
  UpdateQuoteParametersAction,
} from './quoteParameters';

export const quoteParametersReducer = (
  quoteParameters: QuoteParameters = {},
  action:
    | UpdateQuoteParametersAction
    | UpdateQuoteInProgressAction
    | ResetQuoteAction
    | ResetStateAction
): QuoteParameters => {
  switch (action.type) {
    case UPDATE_QUOTE_PARAMETERS:
      return action.update;
    case UPDATE_QUOTE_IN_PROGRESS:
      return action.quote.quoteParameters ?? quoteParameters;
    case RESET_QUOTE:
    case RESET_STATE:
      return {};
    default:
      return quoteParameters;
  }
};
