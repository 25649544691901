import { mapApiOption } from 'api/referenceData/mapApiOption';
import { ApiOption, ReferenceDataOption } from 'types/referenceData';

export type PolicyDetailsReferenceDataResponse = {
  'LineInput.CoverWanted': ApiOption[];
  'PolicyInput.NumberOfCarsInHousehold': ApiOption[];
  'PolicyInput.NumberOfChildrenInHomeUnder16': ApiOption[];
  'PolicyInput.PaymentPreference': ApiOption[];
  'PolicyInput.PreferredDayOfPayment': ApiOption[];
  'PolicyInput.Excess': ApiOption[];
};

export type PolicyDetailsReferenceData = {
  coverWanted: ReferenceDataOption[];
  numberOfCarsInHousehold: ReferenceDataOption[];
  numberOfChildrenInHomeUnder16: ReferenceDataOption[];
  paymentPreference: ReferenceDataOption[];
  preferredDayOfPayment: ReferenceDataOption[];
  voluntaryExcess: ReferenceDataOption[];
};

export const mapPolicyDetailsRefData = (
  data: PolicyDetailsReferenceDataResponse
): PolicyDetailsReferenceData => ({
  coverWanted: data['LineInput.CoverWanted'].map(mapApiOption),
  numberOfCarsInHousehold: data['PolicyInput.NumberOfCarsInHousehold'].map(mapApiOption),
  numberOfChildrenInHomeUnder16: data['PolicyInput.NumberOfChildrenInHomeUnder16'].map(
    mapApiOption
  ),
  paymentPreference: data['PolicyInput.PaymentPreference'].map(mapApiOption),
  preferredDayOfPayment: data['PolicyInput.PreferredDayOfPayment'].map(mapApiOption),
  voluntaryExcess: data['PolicyInput.Excess'].map(mapApiOption),
});
