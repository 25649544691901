import { isAxiosError } from './axiosResponseHelpers';

/* istanbul ignore next */
const logApiError = (error: Error): void => {
  if (isAxiosError(error)) {
    console.error(
      `${error.response.data?.Code || ''} API request: server returned ${
        error.response.status
      }`,
      ...(error.response.data ? [' with data ', error.response.data] : [])
    );
  } else {
    console.error(error);
  }
};

export default logApiError;
