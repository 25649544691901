import { AccountDetailsReferenceData } from 'api/referenceData/accountDetails';
import { ConvictionsReferenceData } from 'api/referenceData/convictions';
import { DriverDetailsReferenceData } from 'api/referenceData/driverDetails';
import { ModificationsReferenceData } from 'api/referenceData/modificationDetails';
import { OccupationsReferenceData } from 'api/referenceData/occupations';
import { PolicyDetailsReferenceData } from 'api/referenceData/policyDetails';
import { VehicleDetailsReferenceData } from 'api/referenceData/vehicleDetails';

/* Redux types */
export enum ReferenceDataStatus {
  NotLoaded = 1,
  Loading,
  Loaded,
}

export type ReferenceDataItemWithStatus<T extends ReferenceData[ReferenceDataKey]> = {
  status: ReferenceDataStatus;
  data?: T;
};

export type ReferenceData = {
  accountDetails: AccountDetailsReferenceData;
  convictions: ConvictionsReferenceData;
  vehicleDetails: VehicleDetailsReferenceData;
  driverDetails: DriverDetailsReferenceData;
  modifications: ModificationsReferenceData;
  occupations: OccupationsReferenceData;
  policyDetails: PolicyDetailsReferenceData;
};

export type ReferenceDataKey = keyof ReferenceData;

export type ReferenceDataState = {
  [TKey in ReferenceDataKey]: ReferenceDataItemWithStatus<ReferenceData[TKey]>;
};
