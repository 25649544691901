import {
  ImageLink,
  NonTextLink,
} from '@rsa-digital/evo-shared-components/commonTypes/links';
import { graphql, useStaticQuery } from 'gatsby';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { CsAsset } from 'types/contentStack';

type HeaderProps = {
  logoLink: ImageLink;
};

type HeaderData = {
  csQuoteAndBuyHeader: CsHeader;
};

type CsHeader = {
  logo: {
    image: CsAsset;
    link_screen_reader_text: string;
    url: string;
  };
};

const headerQuery = graphql`
  query {
    csQuoteAndBuyHeader {
      logo {
        image {
          filename
          description
          localAsset {
            publicURL
          }
        }
        link_screen_reader_text
        url
      }
    }
  }
`;

const mapLink = (csHeader: CsHeader): NonTextLink => ({
  url: csHeader.logo.url,
  screenReaderText: csHeader.logo.link_screen_reader_text,
});

export const useHeaderData = (): HeaderProps => {
  const { csQuoteAndBuyHeader } = useStaticQuery<HeaderData>(headerQuery);

  const logo = processImageAsset(csQuoteAndBuyHeader.logo.image);
  /* istanbul ignore if */
  if (!logo) {
    throw new Error('Header logo missing!');
  }

  return {
    logoLink: {
      image: logo,
      link: mapLink(csQuoteAndBuyHeader),
    },
  };
};
