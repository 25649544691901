import { RESET_STATE, ResetStateAction } from 'state/actions';
import { UPDATE_USER, UpdateUserAction, User } from './state';

export const userReducer = (
  user: User | null = null,
  action: UpdateUserAction | ResetStateAction
): User | null => {
  switch (action.type) {
    case UPDATE_USER:
      return action.user;
    case RESET_STATE:
      return null;
    default:
      return user;
  }
};
