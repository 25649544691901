import React from 'react';
import { Helmet } from 'react-helmet-async';
import appdynamicsScripts from './appdynamics';
import { gtmNoscript, gtmScript } from './gtm';
import optimizelyScript from './optimizely';
import qubitScripts from './qubit';

const Analytics: React.FC = () => {
  return (
    <>
      <Helmet>
        {optimizelyScript}
        {appdynamicsScripts}
        {gtmScript}
        {qubitScripts}
      </Helmet>
      {gtmNoscript}
    </>
  );
};

export default Analytics;
