import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';

export type User = {
  isLoggedIn: boolean;
};

export const UPDATE_USER = 'UPDATE_USER';

export type UpdateUserAction = {
  type: typeof UPDATE_USER;
  user: User;
};

export const useUser = (): [User | null, (user: User) => void] => {
  const user = useSelector((state: RootState) => {
    return state.user;
  });
  const dispatch = useDispatch();

  const setUser = useCallback(
    (updatedUser: User): void => {
      dispatch({ type: UPDATE_USER, user: updatedUser });
    },
    [dispatch]
  );

  return [user, setUser];
};
