import { User } from 'state/user/state';
import { LoginResponse } from './account/loginResponse';
import { MyAccountResult } from './account/myAccountResult';
import { NonceResponse } from './account/nonceResponse';
import { RetrieveEmailRequest } from './account/retrieveEmailRequest';
import apiService from './apiService';

export type AccountClient = {
  login: (token: string) => Promise<LoginResponse>;
  myAccount: () => Promise<MyAccountResult>;
  logout: () => Promise<void>;
  getUser: () => Promise<User>;
  getNonce: () => Promise<NonceResponse>;
  retrieveEmail: (
    retrieveEmailRequest: RetrieveEmailRequest
  ) => Promise<{ email: string }>;
};

const accountClient: AccountClient = {
  login: (token: string) => apiService.post('account/login', { token }),
  myAccount: () => apiService.get('account/myaccount'),
  getUser: () => apiService.get('account/user'),
  getNonce: () => apiService.get('account/nonce'),
  logout: () => apiService.put('account/logout', {}),
  retrieveEmail: (retrieveEmailRequest: RetrieveEmailRequest) =>
    apiService.post('account/retrieveemail', retrieveEmailRequest),
};

export default accountClient;
