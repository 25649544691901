import { TierInfo, TierOptions } from 'state/quote/quote';

const getTieredData = (
  selectedTier: TierOptions,
  tiers: TierInfo[]
): TierInfo | undefined => {
  return tiers.length > 0 ? tiers.find((tier) => tier.name === selectedTier) : undefined;
};

export default getTieredData;
