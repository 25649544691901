import mapVehicleDetails from 'api/quote/quoteToFormMappings/mapVehicleDetails';
import { RESET_STATE, ResetStateAction } from 'state/actions';
import {
  RESET_QUOTE,
  ResetQuoteAction,
  UPDATE_QUOTE,
  UPDATE_RENEWAL,
  UpdateQuoteAction,
} from 'state/quote/quote';
import { mergeFormData } from './mergeFormData';
import { UPDATE_QUOTE_IN_PROGRESS, UpdateQuoteInProgressAction } from './quoteInProgress';
import {
  initialVehicleDetails,
  UPDATE_VEHICLE_DETAILS,
  UpdateVehicleDetailsAction,
  VehicleDetails,
} from './vehicleDetails';

export const vehicleDetailsReducer = (
  vehicleDetails: VehicleDetails = initialVehicleDetails,
  action:
    | UpdateVehicleDetailsAction
    | UpdateQuoteInProgressAction
    | UpdateQuoteAction
    | ResetQuoteAction
    | ResetStateAction
): VehicleDetails => {
  switch (action.type) {
    case UPDATE_QUOTE:
    case UPDATE_RENEWAL:
      return action.quote ? mapVehicleDetails(action.quote) : vehicleDetails;
    case UPDATE_VEHICLE_DETAILS:
      return {
        ...vehicleDetails,
        ...action.update,
      };
    case UPDATE_QUOTE_IN_PROGRESS:
      if (action.quote.vehicleDetails) {
        return mergeFormData(
          vehicleDetails,
          action.quote.vehicleDetails,
          initialVehicleDetails
        );
      }
      return vehicleDetails;
    case RESET_QUOTE:
    case RESET_STATE:
      return initialVehicleDetails;
    default:
      return vehicleDetails;
  }
};
