import {
  QuoteAccountDetails,
  QuoteAdditionalDriverDetails,
  QuoteBaseDriverDetails,
  QuoteDriverDetails,
  QuotePolicyDetails,
  QuoteVehicleDetails,
} from 'api/quote/quoteRequest';
import { useSelector } from 'react-redux';
import { updateQuoteCustomUserData } from 'helpers/customUserDataHelper';
import getTieredData from 'helpers/getTieredData';
import usePremiumCalculationHelper, {
  PremiumCalculator,
} from 'helpers/premiumCalculationHelper';
import { RESET_STATE, ResetStateAction } from 'state/actions';
import {
  AddonSelection,
  CoverageSelection,
  getLatestBreakdownSelection,
  getLatestTierSelection,
  LocalBreakdown,
  useAddonSelection,
} from 'state/addonsSelection/addonsSelection';
import { RootState } from 'state/createStore';
import { StoredPaymentDetails } from './storedPaymentDetails';

export const UPDATE_QUOTE = 'UPDATE_QUOTE';
export const UPDATE_RENEWAL = 'UPDATE_RENEWAL';
export const RESET_QUOTE = 'RESET_QUOTE';

// TODO: DTN-445 - Log if these don't match the reference data options
export enum FormatOptions {
  Online = 'Standard',
  Paper = 'Standard',
  Braille = 'Braille',
  AudioCD = 'AudioCD',
  Largeprint = 'Largeprint',
}

export enum TierOptions {
  Essentials = 'Tier1',
  Standard = 'Tier2',
  Extra = 'Tier3',
}

export type Endorsements = {
  hasSecurityTracker: boolean;
  hasDrivingOtherCars: boolean;
};

export type TieringInfo = {
  selectedTier: TierOptions | null;
  tiers: TierInfo[];
};

export type TierInfo = {
  description: string;
  name: TierOptions;
  compulsoryExcess: number;
  additionalCompulsoryExcess: number;
  paymentDetails: PaymentDetails;
  breakdownCovers: BreakdownCover[];
  coverages: AddOn[];
  totalExcesses: TotalExcesses;
};

export type Preferences = {
  contactEmail?: string | null;
  contactLandline?: string | null;
  contactMobile?: string | null;
  documentPreference?: string | null;
  documentFormat?: FormatOptions | null;
  receiveExclusiveOffers?: boolean | null;
};

export type PaymentDetails = {
  annualPaymentOnly: boolean;
  annualPayment: {
    total?: number;
  };
  isCat: boolean;
  monthlyPayment: MonthlyPayment;
  discounts?: Discount[];
  discountAmount: number;
  costOfCredit: number;
  isAutoRenewal?: boolean;
};

export type MonthlyPayment = {
  total?: number;
  deposit?: number;
  installments?: {
    amount?: number;
    dates?: string[];
  };
  finalInstallment?: {
    amount?: number;
    date?: string;
  };
  annualRateOfInterest?: string;
  aprVariable?: string;
  totalInterestPayable?: number;
};

export type Discount = {
  type?: string;
  name?: string;
};

export type Breakdown = {
  selectedBreakdown: SelectedBreakdown;
  covers: BreakdownCover[];
};

// Complete list of possible breakdown cover options in the API
// Each of the four levels of breakdown cover includes the coverage of all previous levels.

export enum BreakdownCoverLevel {
  Roadside = 'Breakdown1',
  Homecall = 'Breakdown2',
  Recovery = 'Breakdown3',
  European = 'Breakdown4',
}

export type SelectedBreakdown = {
  isSelected: boolean | null;
  isSelectedOnAggregator: boolean;
  coverLevel: BreakdownCoverLevel | null;
};

export type BreakdownCover = {
  description?: string;
  type: BreakdownCoverLevel;
  annualPremium: number;
  monthlyInstallmentPremium: number;
  monthlyDepositPremium: number;
  monthlyTotalPremium: number;
  monthlyFinalInstallmentPremium: number;
};

export type AddOn = {
  type: string;
  isSelected: boolean | null;
  isSelectedOnAggregator: boolean;
  monthlyInstallmentPremium: number;
  annualPremium: number;
  monthlyDepositPremium: number;
  monthlyTotalPremium: number;
  monthlyFinalInstallmentPremium: number;
};

type Message = {
  body?: string;
  flag?: string;
  ruleId?: string;
};

export enum ExcessTypes {
  Misfuelling = 'Misfuelling',
  WindscreenReplacement = 'WindscreenReplacement',
  NonRecommendedRepairer = 'NonRecommendedRepairer',
  // Accidental damage excess must be retrieved from QuoteDriverDetails
  AccidentalDamage = 'AccidentalDamage',
  // Fire and Theft will always be the same value. Combine them and use 'ExcessTypes.Theft' for both.
  Fire = 'Fire',
  Theft = 'Theft',
}

export const LegalCoverageType = 'LegalAssistancePlan';

export type TotalExcesses = { type: ExcessTypes; amount: number }[];

// accidentalDamageExcess is within QuoteDriverDetails
export type OtherExcesses = {
  totalExcesses: TotalExcesses;
  voluntaryExcess: number;
};

export type NoClaimsBonusTable = {
  noProtectionOneClaim: number;
  noProtectionTwoClaims: number;
  noProtectionThreeOrMoreClaims: number;
  protectionOneClaim: number;
  protectionTwoClaims: number;
  protectionThreeOrMoreClaims: number;
};

export type AggregatorName = 'MSM' | 'CTM' | 'GoCompare' | 'Confused';

export type Quote = {
  quoteNumber: string;
  status: 'Quote' | 'Cancelled' | 'Declined' | 'Expired' | 'InForce';
  effectiveFromDate: string;
  expirationDate: string;
  quoteValidUntil: string;
  createdDate: string;
  tieringInfo: TieringInfo | null;
  renewalDate: string;
  endorsements: Endorsements;
  vehicle: QuoteVehicleDetails;
  account: QuoteAccountDetails;
  policyHolder: QuoteDriverDetails;
  additionalDrivers: QuoteAdditionalDriverDetails[];
  policy: QuotePolicyDetails;
  preferences: Preferences;
  paymentDetails: PaymentDetails;
  excess: OtherExcesses;
  breakdown: Breakdown;
  coverages: AddOn[];
  noClaimsBonusTable: NoClaimsBonusTable;
  messages: Message[];
  hasInvalidPaymentOption: boolean;
  aggregatorName: AggregatorName | null;
  aggregatorAutoUpgradeFromTier: TierOptions | null;
  directRenewalCustomerRecognised: boolean;
};

export type RenewalDetails = {
  previouslyNonTiered: boolean;
  previouslyTpftCoverWanted: boolean;
};

export type Renewal = Quote & {
  policyNumber: string;
  renewalEffectiveFromDate: string;
  renewalStatus: 'None' | 'Pending' | 'Ready' | 'InProgress' | 'Confirmed';
  storedPaymentDetails: StoredPaymentDetails;
  renewalDetails: RenewalDetails;
};

export const isTieredQuote = (quote: Quote | Renewal): boolean => !!quote.tieringInfo;

export const isRenewal = (quote: Quote | Renewal): quote is Renewal =>
  Object.prototype.hasOwnProperty.call(quote, 'renewalEffectiveFromDate');

export const isAggregatorQuote = (quote: Quote | Renewal): boolean =>
  !!quote.aggregatorName;

export const isGoCompareQuote = (quote: Quote | Renewal): boolean =>
  quote.aggregatorName === 'GoCompare' && !isRenewal(quote);

export type UpdateQuoteAction =
  | {
      type: typeof UPDATE_QUOTE;
      quote: Quote | null;
    }
  | {
      type: typeof UPDATE_RENEWAL;
      quote: Renewal | null;
    };

export type ResetQuoteAction = {
  type: typeof RESET_QUOTE;
};

export const getCurrentTier = (quote: Quote): TierInfo | undefined => {
  const selectedTier = quote.tieringInfo?.selectedTier;
  const tier =
    selectedTier &&
    quote.tieringInfo &&
    getTieredData(selectedTier, quote.tieringInfo.tiers);
  return tier || undefined;
};

// The use of (typeof window !== 'undefined' && window.Cypress && window.initialQuoteState)
// allows for pre-populating policy state during testing
// The property initialQuoteState in window is targeted during 'cy.visit -> onBeforeLoad'
export const initialQuoteState =
  (typeof window !== 'undefined' && window.Cypress && window.initialQuoteState) || null;

export const quoteReducer = (
  quote: Quote | Renewal | null = initialQuoteState,
  action: UpdateQuoteAction | ResetQuoteAction | ResetStateAction
): Quote | Renewal | null => {
  switch (action.type) {
    case UPDATE_QUOTE:
    case UPDATE_RENEWAL:
      updateQuoteCustomUserData(action.quote);
      return (
        action.quote && {
          ...action.quote,
        }
      );
    case RESET_QUOTE:
    case RESET_STATE:
      return null;
    default:
      return quote;
  }
};

const applyLocalSelectionToTierPrice = (
  tier: TierInfo,
  addonSelection: AddonSelection,
  premiumCalculator: PremiumCalculator
): PaymentDetails | null =>
  premiumCalculator({
    ...addonSelection,
    localSelectedTier: tier.name,
  });

const applyLocalSelectionToCoverages = (
  coverages: AddOn[],
  coverageSelection: CoverageSelection[] | undefined
): AddOn[] => {
  const coverageByType = Object.fromEntries(coverages.map((c) => [c.type, c]));
  return (coverageSelection ?? [])
    .filter((c) => c.type in coverageByType)
    .map((c) => ({
      ...coverageByType[c.type],
      isSelected: c.isSelected,
    }));
};

const applyLocalSelectionToBreakdown = (
  breakdown: Breakdown,
  localBreakdown: LocalBreakdown
): Breakdown => ({
  ...breakdown,
  selectedBreakdown: {
    ...breakdown.selectedBreakdown,
    ...getLatestBreakdownSelection(localBreakdown, breakdown.selectedBreakdown),
  },
});

const applyLocalSelectionToTieringInfo = (
  quote: Quote | Renewal,
  addonSelection: AddonSelection,
  premiumCalculator: PremiumCalculator
): TieringInfo | null =>
  quote.tieringInfo
    ? {
        ...quote.tieringInfo,
        selectedTier: getLatestTierSelection(
          addonSelection.localSelectedTier,
          quote.tieringInfo.selectedTier
        ),
        tiers: quote.tieringInfo.tiers.map((tier) => ({
          ...tier,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          paymentDetails: applyLocalSelectionToTierPrice(
            tier,
            addonSelection,
            premiumCalculator
          )!,
          coverages: applyLocalSelectionToCoverages(
            tier.coverages,
            addonSelection.localCoverages
          ),
        })),
      }
    : null;

const applyLocalSelectionToDriverExcess = (
  quote: Quote | Renewal,
  addonSelection: AddonSelection,
  driver: QuoteBaseDriverDetails
): QuoteBaseDriverDetails => {
  if (!quote.tieringInfo) {
    return driver;
  }

  // The driver total excess includes the additional excess of the committed tier, so we first subtract
  // the additional compulsory excess of the committed tier to get the base excess price without the tier,
  // and then add the additional compulsory excess of the tier selected locally

  const apiSelectedTierInfo = getCurrentTier(quote);
  const localSelectedTier = getLatestTierSelection(
    addonSelection.localSelectedTier,
    quote.tieringInfo.selectedTier
  );
  const localSelectedTierInfo =
    localSelectedTier && getTieredData(localSelectedTier, quote.tieringInfo.tiers);

  const apiSelectedTierAdditionalExcess =
    apiSelectedTierInfo?.additionalCompulsoryExcess ?? 0;
  const localSelectedTierAdditionalExcess =
    localSelectedTierInfo?.additionalCompulsoryExcess ?? 0;

  return {
    ...driver,
    accidentalDamageExcess:
      driver.accidentalDamageExcess -
      apiSelectedTierAdditionalExcess +
      localSelectedTierAdditionalExcess,
  };
};

/**
 * Returns the active quote for the user. If no quote has been requested, or the user
 * is not eligible, then this returns null.
 */
export const useQuote = (useLocalAddonSelection?: boolean): Quote | Renewal | null => {
  const quote = useSelector((state: RootState) => state.quote);
  const [addonSelection] = useAddonSelection();
  const premiumCalculator = usePremiumCalculationHelper();

  if (!quote || !useLocalAddonSelection) {
    return quote;
  }

  return {
    ...quote,
    tieringInfo: applyLocalSelectionToTieringInfo(
      quote,
      addonSelection,
      premiumCalculator
    ),
    coverages: applyLocalSelectionToCoverages(
      quote.coverages,
      addonSelection.localCoverages
    ),
    breakdown: applyLocalSelectionToBreakdown(
      quote.breakdown,
      addonSelection.localBreakdown
    ),
    policyHolder: {
      ...quote.policyHolder,
      ...applyLocalSelectionToDriverExcess(quote, addonSelection, quote.policyHolder),
    },
    additionalDrivers: quote.additionalDrivers.map((driver) => ({
      ...driver,
      ...applyLocalSelectionToDriverExcess(quote, addonSelection, driver),
    })),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    paymentDetails: premiumCalculator(addonSelection)!,
  };
};
