import React from 'react';
import { Helmet } from 'react-helmet-async';

const cookieBannerEnv = process.env.GATSBY_COOKIE_BANNER_ENV;

const productionDataDomainScript = '4b9d9df5-0c33-4601-a173-683a04bc52b9';
const stagingDataDomainScript = '4b9d9df5-0c33-4601-a173-683a04bc52b9-test';

const CookieBanner: React.FC = () => {
  return (
    <Helmet>
      <script
        src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script={
          cookieBannerEnv === 'production'
            ? productionDataDomainScript
            : stagingDataDomainScript
        }
      />
      <script type="text/javascript">function OptanonWrapper() &#123; &#125;</script>
    </Helmet>
  );
};

export default CookieBanner;
