import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';

export const urlParamToSessionStorage = (parameter: string): void => {
  const urlParams = new URLSearchParams(window.location.search);
  const paramValue = urlParams.get(parameter);
  if (paramValue) {
    window.sessionStorage.setItem(parameter, paramValue);
  }
};

export type QuoteParameters = {
  cdwAffiliateCode?: string;
  campaignCode?: string;
  // refToken is used to apply staff discount and is passed to the API via a query param
  refToken?: string;
  // This override key is only expected to be defined as a query param for the smoke test script so that it can bypass
  // the recaptcha check in the backend. In all other cases, we should validate recaptcha.
  recaptchaOverrideKey?: string;
};

export const UPDATE_QUOTE_PARAMETERS = 'UPDATE_QUOTE_PARAMETERS';

export type UpdateQuoteParametersAction = {
  type: typeof UPDATE_QUOTE_PARAMETERS;
  update: QuoteParameters;
};

export const useQuoteParameters = (): [
  QuoteParameters,
  (update: Partial<QuoteParameters>) => void
] => {
  const quoteParameters = useSelector((state: RootState) => {
    return state.quoteParameters;
  });
  const dispatch = useDispatch();

  const updateQuoteParameters = useCallback(
    (update: Partial<QuoteParameters>): void => {
      dispatch({ type: UPDATE_QUOTE_PARAMETERS, update });
    },
    [dispatch]
  );

  return [quoteParameters, updateQuoteParameters];
};
