import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { trackPlaintextLink } from 'helpers/eventTracking';
import { StyledProgressBar } from './styles';

type QuoteAndBuyProgressBarProps = {
  currentStep: number;
};

type ProgressBarContent = {
  csMotorProgressBar: {
    hide_progress_text: string;
    show_progress_text: string;
    about_your_car_label: string;
    your_details_label: string;
    additional_drivers_label: string;
    quote_summary_label: string;
    check_details_label: string;
    payment_label: string;
  };
};

export const query = graphql`
  query {
    csMotorProgressBar {
      hide_progress_text
      show_progress_text
      about_your_car_label
      your_details_label
      additional_drivers_label
      quote_summary_label
      check_details_label
      payment_label
    }
  }
`;
const QuoteAndBuyProgressBar: React.FC<QuoteAndBuyProgressBarProps> = ({
  currentStep,
}) => {
  const {
    csMotorProgressBar: {
      hide_progress_text,
      show_progress_text,
      about_your_car_label,
      your_details_label,
      additional_drivers_label,
      quote_summary_label,
      check_details_label,
      payment_label,
    },
  } = useStaticQuery<ProgressBarContent>(query);

  const steps = [
    about_your_car_label,
    your_details_label,
    additional_drivers_label,
    quote_summary_label,
    check_details_label,
    payment_label,
  ];

  return (
    <StyledProgressBar
      data-cy="QuoteAndBuyProgressBar"
      desktopWidthInPercentage={82}
      tabletWidthInPercentage={82}
      useIconOnStepDone
      currentStep={currentStep}
      totalSteps={6}
      stepName={steps}
      showProgressText={show_progress_text}
      hideProgressText={hide_progress_text}
      alignLeft
      progressBarColour={colors.selectorHighlight}
      stepNumberBeforeDescriptionOnMobile
      onToggleProgress={(progressShown) =>
        trackPlaintextLink(
          'Progress Bar',
          progressShown ? hide_progress_text : show_progress_text,
          'Click'
        )
      }
    />
  );
};

export default QuoteAndBuyProgressBar;
