import { RESET_STATE } from 'state/actions';
import {
  REFERENCE_DATA_ERRORED,
  REFERENCE_DATA_FETCHED,
  REFERENCE_DATA_LOADING,
  REFERENCE_DATA_REQUESTED,
  ReferenceDataAction,
} from './actions';
import { ReferenceDataState, ReferenceDataStatus } from './state';

const initialState: ReferenceDataState = {
  accountDetails: { status: ReferenceDataStatus.NotLoaded },
  convictions: { status: ReferenceDataStatus.NotLoaded },
  vehicleDetails: { status: ReferenceDataStatus.NotLoaded },
  driverDetails: { status: ReferenceDataStatus.NotLoaded },
  modifications: { status: ReferenceDataStatus.NotLoaded },
  occupations: { status: ReferenceDataStatus.NotLoaded },
  policyDetails: { status: ReferenceDataStatus.NotLoaded },
};

const referenceDataReducer = (
  state: ReferenceDataState = initialState,
  action: ReferenceDataAction
): ReferenceDataState => {
  switch (action.type) {
    case REFERENCE_DATA_LOADING:
      return { ...state, [action.dataType]: { status: ReferenceDataStatus.Loading } };
    case REFERENCE_DATA_FETCHED:
      return {
        ...state,
        [action.dataType]: { status: ReferenceDataStatus.Loaded, data: action.data },
      };
    case REFERENCE_DATA_ERRORED:
      return {
        ...state,
        [action.dataType]: { status: ReferenceDataStatus.NotLoaded },
      };
    case RESET_STATE:
      return initialState;
    case REFERENCE_DATA_REQUESTED:
    default:
      return state;
  }
};

export default referenceDataReducer;
