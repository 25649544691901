import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';

export const UPDATE_MTA_REMOVE_DRIVER_DETAILS = 'UPDATE_MTA_REMOVE_DRIVER_DETAILS';

export type UpdateMtaRemoveDriverAction = {
  type: typeof UPDATE_MTA_REMOVE_DRIVER_DETAILS;
  update: Partial<RemoveDriverMtaDetails>;
};

export type RemoveDriverMtaDetails = {
  driverId: string;
};

export const initialRemoveDriverMtaDetails: RemoveDriverMtaDetails = {
  driverId: '',
};

export const useRemoveDriverMtaDetails = (): [
  RemoveDriverMtaDetails,
  (update: Partial<RemoveDriverMtaDetails>) => void
] => {
  const mtaState = useSelector((state: RootState) => state.mtaState);
  const dispatch = useDispatch();

  const updateMtaFormState = useCallback(
    (update: Partial<RemoveDriverMtaDetails>): void => {
      dispatch({ type: UPDATE_MTA_REMOVE_DRIVER_DETAILS, update });
    },
    [dispatch]
  );

  return [mtaState.removeDriver, updateMtaFormState];
};
