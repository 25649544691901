import { mapApiOption } from 'api/referenceData/mapApiOption';
import { ApiOption, ReferenceDataOption } from 'types/referenceData';

export type AccountDetailsReferenceDataResponse = {
  'AccountInput.Title': ApiOption[];
  'AccountInput.LengthLivedAtAddress': ApiOption[];
  'AccountInput.Gender': ApiOption[];
  'AccountInput.MaritalStatus': ApiOption[];
};

export type AccountDetailsReferenceData = {
  title: ReferenceDataOption[];
  lengthLivedAtAddress: ReferenceDataOption[];
  gender: ReferenceDataOption[];
  maritalStatus: ReferenceDataOption[];
};

export const mapAccountDetailsRefData = (
  data: AccountDetailsReferenceDataResponse
): AccountDetailsReferenceData => ({
  title: data['AccountInput.Title'].map(mapApiOption),
  lengthLivedAtAddress: data['AccountInput.LengthLivedAtAddress'].map(mapApiOption),
  gender: data['AccountInput.Gender'].map(mapApiOption),
  maritalStatus: data['AccountInput.MaritalStatus'].map(mapApiOption),
});
