import { SimpleDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import Feature from '@rsa-digital/evo-shared-components/components/Feature';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql } from 'gatsby';
import React, { Fragment } from 'react';
import {
  processImageAsset,
  processOptionalCta,
  unwrapSingleton,
} from 'helpers/csTypeProcessors';
import { CsAsset, CsCta, CsIcon } from 'types/contentStack';

export type SingleFeatureProp = {
  title: string;
  body: string;
  image: CsAsset | null;
  cta: [CsCta] | null;
  invert?: boolean;
  invertOnMobile?: boolean;
  custom_bullet_point?: [CsIcon] | null;
};

export type FeaturesBlockProps = {
  features: {
    features: SingleFeatureProp[];
  };
} & ComponentProps;

export const query = graphql`
  fragment FeaturesBlockIneligibleQuotePage on cs__ineligible_quote_page_blocks {
    features {
      features {
        title
        body
        image {
          ...CsAsset
        }
        cta {
          display_text
          screen_reader_text
          url
        }
      }
    }
  }
  fragment FeaturesBlockSessionExpiredPage on cs__session_expired_page_blocks {
    features {
      features {
        title
        body
        image {
          ...CsAsset
        }
        cta {
          display_text
          screen_reader_text
          url
        }
      }
    }
  }
  fragment FeaturesBlockPolicyManagement on cs__policy_management_blocks {
    features {
      features {
        title
        body
        image {
          ...CsAsset
        }
        cta {
          display_text
          screen_reader_text
          url
        }
      }
    }
  }
`;

const FeaturesBlock: React.FC<FeaturesBlockProps> = ({ features, ...props }) => {
  const useZigzagLayout = features.features.length <= 3;
  return (
    <>
      {features.features?.map((feature, index, array) => {
        const invertContent = feature.invert ?? (useZigzagLayout && index % 2 === 1);
        const insertDivider = array.length - 1 !== index; // check for final feature

        const image = processImageAsset(feature.image);

        const bulletIcon = feature.custom_bullet_point
          ? unwrapSingleton(feature.custom_bullet_point)?.icon_code
          : undefined;

        return (
          <Fragment key={feature.title}>
            <Feature
              title={feature.title}
              body={feature.body}
              image={image}
              cta={processOptionalCta(feature.cta)}
              customBulletIcon={bulletIcon}
              invertContent={invertContent}
              invertContentOnMobile={feature.invertOnMobile}
              data-cy={`Feature${index}`}
              {...componentProps(props)}
            />
            {insertDivider && <SimpleDivider data-cy={`FeatureDivider${index}`} />}
          </Fragment>
        );
      })}
    </>
  );
};

export default React.memo(FeaturesBlock);
