import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import sessionClient from 'api/sessionClient';
import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { PageType } from 'components/Layout';
import SessionExpiryModal from 'components/SessionExpiryModal';
import { getAzureLogoutUrlWithRedirectPath } from 'helpers/azureRoutingHelper';
import { trackPopUp } from 'helpers/eventTracking';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import useResetReduxState from 'helpers/useResetReduxState';
import {
  IDLE_TIMER_DEBOUNCE_IN_MS,
  TIMEOUT_FOR_DISPLAYING_SESSION_EXPIRY_MODAL_IN_SECONDS,
} from '../config';

type DefaultSessionExpiryWrapperProps = {
  pageType: PageType;
};

const getExpiredSessionPageRoute = (pageType: PageType): string => {
  switch (pageType) {
    case 'accountArea':
    case 'mtaAndRenewal':
      return '/account/session-expired';
    case 'quoteAndBuy':
    case 'error':
    default:
      return '/quote/session-expired';
  }
};

const DefaultSessionExpiryWrapper: React.FC<DefaultSessionExpiryWrapperProps> = ({
  pageType,
  children,
}) => {
  const [showSessionExpiringModal, setShowSessionExpiringModal] = useState<boolean>(
    false
  );
  const { requestHandler, isLoading } = useApiRequestHandler();
  const resetReduxState = useResetReduxState();

  useIdleTimer({
    timeout: TIMEOUT_FOR_DISPLAYING_SESSION_EXPIRY_MODAL_IN_SECONDS * 1000,
    onIdle: () => {
      setShowSessionExpiringModal(true);
      trackPopUp('Your session is about to expire');
    },
    debounce: IDLE_TIMER_DEBOUNCE_IN_MS,
  });

  const expiredSessionPageRoute = getExpiredSessionPageRoute(pageType);

  const onExpiry = async (): Promise<void> => {
    await requestHandler(async () => {
      await sessionClient.expireSession();
      window.location.assign(getAzureLogoutUrlWithRedirectPath(expiredSessionPageRoute));
    });
    setShowSessionExpiringModal(false);
  };

  const onContinue = async (): Promise<void> => {
    await requestHandler(sessionClient.refreshSession);
    setShowSessionExpiringModal(false);
  };

  const onGoToHomepage = async (): Promise<void> => {
    await requestHandler(sessionClient.expireSession);
    resetReduxState();
  };

  return (
    <>
      {showSessionExpiringModal && (
        <SessionExpiryModal
          onExpiry={onExpiry}
          onContinue={onContinue}
          onGoToHomepage={onGoToHomepage}
          pageType={pageType}
        />
      )}
      {isLoading && <LoadingOverlay loadingMessage="Loading" />}
      {children}
    </>
  );
};

export default DefaultSessionExpiryWrapper;
