import { dateToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { isOtherLicenceType } from 'api/businessLogic/driverLicence';
import { getInferedGenderFromTitle } from 'api/businessLogic/gender';
import { isEmployed } from 'api/businessLogic/occupation';
import { conditionalValue } from 'helpers/mappingHelpers';
import { DriverDetails } from 'state/formData/driverDetails';
import { initialDateValue } from 'state/formData/shared/dateValue';
import { Quote, Renewal } from 'state/quote/quote';
import mapAddress from './mapAddress';

const mapDriverDetails = (quote: Quote | Renewal): DriverDetails => {
  const quoteDriverDetails = quote.policyHolder;
  const driverDetails: DriverDetails = {
    title: quoteDriverDetails.title,
    firstName: quoteDriverDetails.firstName,
    lastName: quoteDriverDetails.lastName,
    maritalStatus: quoteDriverDetails.maritalStatus,
    hasLivedInUkSinceBirth: quoteDriverDetails.livedInUkSinceBirthIndicator,
    dateOfBirth: dateToDateValue(new Date(quoteDriverDetails.dateOfBirth)),
    gender: getInferedGenderFromTitle(quoteDriverDetails.title)
      ? ''
      : quoteDriverDetails.gender,
    email: quote.account.email,
    mainTelephone: quote.account.primaryPhone,
    lengthLivedAtAddress: quote.account.lengthLivedAtAddress.toString(),
    ukResidentSince: quoteDriverDetails.livedInUkSinceBirthIndicator
      ? initialDateValue
      : dateToDateValue(new Date(quoteDriverDetails.ukResidentSince)),
    licenceType: quoteDriverDetails.licenceType,
    otherLicenceType: isOtherLicenceType(quoteDriverDetails)
      ? quoteDriverDetails.otherLicenceType || ''
      : '',
    licenceHeldYears: quoteDriverDetails.licenceHeldYears.toString(),
    licenceHeldMonths: quoteDriverDetails.licenceHeldMonths
      ? quoteDriverDetails.licenceHeldMonths.toString()
      : '',
    licenceNumber: quoteDriverDetails.licenceNumber || '',
    hasMedicalConditionsOrDisabilities:
      quoteDriverDetails.medicalConditionsOrDisabilities ?? undefined,
    isMedicalConditionsNotified: conditionalValue(
      quoteDriverDetails.medicalConditionsOrDisabilities ?? undefined,
      quoteDriverDetails.medicalConditionsNotified ?? undefined
    ),
    useOfOtherVehicles: quoteDriverDetails.useOfOtherVehicles,
    employmentStatus: quoteDriverDetails.employmentStatus,
    primaryOccupation: isEmployed(quoteDriverDetails)
      ? quoteDriverDetails.primaryOccupation || ''
      : '',
    primaryOccupationIndustry: isEmployed(quoteDriverDetails)
      ? quoteDriverDetails.primaryOccupationIndustry || ''
      : '',
    hasSecondaryOccupation: isEmployed(quoteDriverDetails)
      ? quoteDriverDetails.isSecondaryOccupation
      : undefined,
    secondaryOccupation: isEmployed(quoteDriverDetails)
      ? quoteDriverDetails.secondaryOccupation || ''
      : '',
    secondaryOccupationIndustry: isEmployed(quoteDriverDetails)
      ? quoteDriverDetails.secondaryOccupationIndustry || ''
      : '',
    isHomeowner: quote.policy.isHomeOwner,
    hasChildrenUnder16: quote.policy.childrenUnder16Indicator,
    numberOfChildrenInHomeUnder16: quote.policy.numberOfChildrenInHomeUnder16 || '',
    numberOfCarsInHousehold: quote.policy.numberOfCarsInHousehold,
    ...mapAddress(quote.account.address),
  };
  return driverDetails;
};

export default mapDriverDetails;
