import ProgressBar from '@rsa-digital/evo-shared-components/components/ProgressBar';
import { Title } from '@rsa-digital/evo-shared-components/components/ProgressBar/ProgressMobile/styles';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledProgressBar = styled(ProgressBar)`
  ${Title} {
    margin-top: ${spacing(0.5)};
  }
`;
