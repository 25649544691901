import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { AdditionalDriver, initialAdditionalDriver } from '../additionalDriversDetails';

export const UPDATE_MTA_EDIT_DRIVER_DETAILS = 'UPDATE_MTA_EDIT_DRIVER_DETAILS';

export type UpdateMtaEditDriverAction = {
  type: typeof UPDATE_MTA_EDIT_DRIVER_DETAILS;
  update: Partial<EditDriverMtaDetails>;
};

export type EditDriverMtaDetails = AdditionalDriver & {
  driverId: string;
};

export const initialEditDriverMtaDetails: EditDriverMtaDetails = {
  ...initialAdditionalDriver,
  driverId: '',
};

export const useEditDriverMtaDetails = (): [
  EditDriverMtaDetails,
  (update: Partial<EditDriverMtaDetails>) => void
] => {
  const mtaState = useSelector((state: RootState) => state.mtaState);
  const dispatch = useDispatch();

  const updateMtaFormState = useCallback(
    (update: Partial<EditDriverMtaDetails>): void => {
      dispatch({ type: UPDATE_MTA_EDIT_DRIVER_DETAILS, update });
    },
    [dispatch]
  );

  return [mtaState.editDriver, updateMtaFormState];
};
