import { mapApiOption } from 'api/referenceData/mapApiOption';
import { ApiOption, ReferenceDataOption } from 'types/referenceData';

export type ConvictionsReferenceDataResponse = {
  'MotoringConvictionsInput.PenaltyPoints': ApiOption[];
  'MotoringConvictionsInput.TypeOfOffence': ApiOption[];
};

export type ConvictionsReferenceData = {
  penaltyPoints: ReferenceDataOption[];
  typeOfOffence: ReferenceDataOption[];
};

export const mapConvictionsRefData = (
  data: ConvictionsReferenceDataResponse
): ConvictionsReferenceData => ({
  penaltyPoints: data['MotoringConvictionsInput.PenaltyPoints'].map(mapApiOption),
  typeOfOffence: data['MotoringConvictionsInput.TypeOfOffence'].map(mapApiOption),
});
