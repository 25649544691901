import mapValues from 'lodash/mapValues';

export type CsPlaceholders<T> = { [variable: string]: (object: T) => string };

/**
 * A function to replace variables set in ContentStack with values from an object returned from the api.
 * Variables in ContentStack are given by using double curly brackets e.g. {{Variable}}.
 * The corresponding variables are defined in the csPlaceholders object.
 *
 * This function means that we dont not have to manually check for the variables in every field,
 * giving more flexibility in ContentStack, and reducing the risk we will miss some.
 *
 * The function checks everything for the double brackets, so there is a risk that it will replace
 * something that it should not. This risk has been mitigated by:
 * * Using uncommon characters for the placeholders i.e. {{}}.
 * * Replacing the content only if the placeholder name is recognised in useCsVariables.
 *
 * @param csContent the data returned by a gatsby query
 * @param dataWithValues the data containing the values that will replace the placeholders
 * @param csPlaceholders an object containing the mappings to replace the placeholder with the data from object
 *
 * @returns a new data object with the values for the ContentStack Variables inserted.
 */
const replaceCsPlaceholders = <T, U>(
  csContent: T,
  dataWithValues: U,
  csPlaceholders: CsPlaceholders<U>
): T => {
  const replacer = (match: string): string => {
    // The regex will match a string that contains {{variable}}
    // The output of '.match' is an array of matches, where the first result is the whole match
    // And the next the match within the brackets of the regex  e.g:
    // ['{{variable}}', 'variable']
    // This is the reason we use the 2nd result in the array (word[1])
    const word = match.match(/{{([\w+]+)}}/);

    if (!word || !csPlaceholders[word[1] as keyof CsPlaceholders<U>]) {
      return match;
    }

    return csPlaceholders[word[1] as keyof CsPlaceholders<U>](dataWithValues);
  };

  const stringVariableReplacement = (csString: string): string => {
    return csString.replace(/({{)\w+(}})/g, replacer);
  };

  const nestedVariableReplace = (data: unknown): unknown => {
    if (data === null || data === undefined) {
      return data;
    }
    if (Array.isArray(data)) {
      return data.map((arrValue) => nestedVariableReplace(arrValue));
    }
    if (typeof data === 'object') {
      return mapValues(data, (objValue) => nestedVariableReplace(objValue));
    }
    if (typeof data === 'string') {
      return stringVariableReplacement(data);
    }
    return data;
  };

  return nestedVariableReplace(csContent) as T;
};

export default replaceCsPlaceholders;
