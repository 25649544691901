import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { VehicleDetails } from '../vehicleDetails';

export const UPDATE_MTA_CHANGE_CAR_DETAILS = 'UPDATE_MTA_CHANGE_CAR_DETAILS';

export type UpdateMtaChangeCarDetailsAction = {
  type: typeof UPDATE_MTA_CHANGE_CAR_DETAILS;
  update: Partial<VehicleDetails>;
};

export const useChangeCarDetails = (): [
  VehicleDetails,
  (update: Partial<VehicleDetails>) => void
] => {
  const mtaState = useSelector((state: RootState) => state.mtaState);
  const dispatch = useDispatch();
  const updateMtaFormState = useCallback(
    (update: Partial<VehicleDetails>): void => {
      dispatch({ type: UPDATE_MTA_CHANGE_CAR_DETAILS, update });
    },
    [dispatch]
  );

  return [mtaState.changeCar, updateMtaFormState];
};
