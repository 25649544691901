import { useSelector } from 'react-redux';
import { RESET_STATE, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import { Quote } from 'state/quote/quote';
import { StoredPaymentDetails } from '../quote/storedPaymentDetails';

export type MtaQuote = Quote & {
  policyNumber: string;
  mtaPaymentAmount: number;
  mtaEffectiveFromDate: string;
  mtaStatus: MtaStatus;
  mtaType: MtaQuoteType;
  storedPaymentDetails: StoredPaymentDetails;
};

export const isMtaQuote = (quote: Quote): quote is MtaQuote =>
  Object.prototype.hasOwnProperty.call(quote, 'mtaEffectiveFromDate');

// The API spec also says "None" is a valid value, but this is because it is automatically generated from the backend types.
// When there is no renewal then the `mtaSummary` field on the Policy will be `null` instead.
export enum MtaStatus {
  InProgress = 'InProgress',
  Confirmed = 'Confirmed',
}

export type MtaQuoteType = {
  isAddDriver: boolean;
  isChangeOfAddress: boolean;
  isChangeOfDriver: boolean;
  isChangeOfVehicle: boolean;
  isRemoveDriver: boolean;
};

export const UPDATE_MTA_QUOTE = 'UPDATE_MTA_QUOTE';

export type UpdateMtaQuoteAction = {
  type: typeof UPDATE_MTA_QUOTE;
  mtaQuote: MtaQuote | null;
};

export const mtaQuoteReducer = (
  mtaQuote: MtaQuote | null = null,
  action: UpdateMtaQuoteAction | ResetStateAction
): MtaQuote | null => {
  switch (action.type) {
    case UPDATE_MTA_QUOTE:
      return action.mtaQuote;
    case RESET_STATE:
      return null;
    default:
      return mtaQuote;
  }
};

export const useMtaQuote = (): MtaQuote | null => {
  return useSelector((state: RootState) => state.mtaQuote);
};
