import { QuoteBaseDriverDetails } from 'api/quote/quoteRequest';
import { AdditionalDriver } from 'state/formData/additionalDriversDetails';
import { DriverDetails } from 'state/formData/driverDetails';

export const isOtherLicenceType = (
  data: DriverDetails | AdditionalDriver | QuoteBaseDriverDetails
): boolean => data.licenceType === 'Z';

export const hasLicenceForLessThanTwoYears = (
  data: DriverDetails | AdditionalDriver
): boolean => data.licenceHeldYears === '1';

export const licenceLengthYears = (data: DriverDetails | AdditionalDriver): number => {
  if (!data.licenceHeldYears || data.licenceHeldYears === '1') {
    return 0;
  }
  return Number(data.licenceHeldYears);
};

export const licenceLengthMonths = (data: DriverDetails | AdditionalDriver): number => {
  return Number(data.licenceHeldMonths);
};

/*
The licence period is determined by the years if it is 2 or greater, otherwise it is the months.
This method works out which is applicable and presents the total years based on that.
*/
export const combinedLicenceLengthYears = (
  data: DriverDetails | AdditionalDriver
): number => {
  return licenceLengthYears(data) === 0
    ? licenceLengthMonths(data) / 12
    : licenceLengthYears(data);
};
