import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { DriverClaim } from './shared/driverClaim';
import { MotoringConviction } from './shared/motoringConviction';

export const UPDATE_COVER_DETAILS = 'UPDATE_COVER_DETAILS';

export type CoverDetails = {
  paymentOption: string;
  coverType: string;
  coverStartDate: string | undefined;
  currentNoClaimsBonus: string;
  hasHandedBackCompanyCar: boolean | undefined;
  isCompanyCarSocial: boolean | undefined;
  companyCarOwnedLength: string;
  hasMotoringAccidents: boolean | undefined;
  claims: DriverClaim[];
  hasMotoringConvictions: boolean | undefined;
  convictions: MotoringConviction[];
  hasUnspentNonMotoringConvictions: boolean | undefined;
  hasRefusedInsurance: boolean | undefined;
  isCarImpounded: boolean | undefined;
};

export const initialCoverDetails: CoverDetails = {
  paymentOption: '',
  coverType: '',
  coverStartDate: undefined,
  currentNoClaimsBonus: '',
  hasHandedBackCompanyCar: undefined,
  isCompanyCarSocial: undefined,
  companyCarOwnedLength: '',
  hasMotoringAccidents: undefined,
  claims: [],
  hasMotoringConvictions: undefined,
  convictions: [],
  hasUnspentNonMotoringConvictions: undefined,
  hasRefusedInsurance: undefined,
  isCarImpounded: undefined,
};

export type UpdateCoverDetailsAction = {
  type: typeof UPDATE_COVER_DETAILS;
  update: Partial<CoverDetails>;
};

export const useCoverDetails = (): [
  CoverDetails,
  (update: Partial<CoverDetails>) => void
] => {
  const coverDetails = useSelector((state: RootState) => state.coverDetails);
  const dispatch = useDispatch();

  const updateCoverDetails = (update: Partial<CoverDetails>): void => {
    dispatch({ type: UPDATE_COVER_DETAILS, update });
  };

  return [coverDetails, updateCoverDetails];
};
