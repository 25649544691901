import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ErrorPage, { ErrorPageData } from 'templates/ErrorPage/index';
import { ErrorType } from 'state/error/actions';

export const query = graphql`
  query {
    csErrorPage(error_id: { eq: "motor-401-recaptcha-failure" }) {
      ...ErrorPage
    }
  }
`;

const ReCaptchaFailureErrorPage: React.FC = () => {
  const data = useStaticQuery<ErrorPageData>(query);
  return <ErrorPage data={data} errorType={ErrorType.RECAPTCHA_ERROR} statusCode={401} />;
};

export default ReCaptchaFailureErrorPage;
