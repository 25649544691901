import {
  AccountDetailsReferenceData,
  AccountDetailsReferenceDataResponse,
  mapAccountDetailsRefData,
} from 'api/referenceData/accountDetails';
import {
  DriverDetailsReferenceData,
  DriverDetailsReferenceDataResponse,
  mapDriverDetailsRefData,
} from 'api/referenceData/driverDetails';
import {
  mapModificationsRefData,
  ModificationsReferenceData,
  ModificationsReferenceDataResponse,
} from 'api/referenceData/modificationDetails';
import {
  mapOccupationsRefData,
  OccupationsReferenceData,
  OccupationsReferenceDataResponse,
} from 'api/referenceData/occupations';
import {
  mapPolicyDetailsRefData,
  PolicyDetailsReferenceData,
  PolicyDetailsReferenceDataResponse,
} from 'api/referenceData/policyDetails';
import {
  mapVehicleDetailsRefData,
  VehicleDetailsReferenceData,
  VehicleDetailsReferenceDataResponse,
} from 'api/referenceData/vehicleDetails';
import apiService from './apiService';
import {
  ConvictionsReferenceData,
  ConvictionsReferenceDataResponse,
  mapConvictionsRefData,
} from './referenceData/convictions';

type ReferenceDataClient = {
  accountDetails: () => Promise<AccountDetailsReferenceData>;
  convictions: () => Promise<ConvictionsReferenceData>;
  vehicleDetails: () => Promise<VehicleDetailsReferenceData>;
  driverDetails: () => Promise<DriverDetailsReferenceData>;
  modifications: () => Promise<ModificationsReferenceData>;
  occupations: () => Promise<OccupationsReferenceData>;
  policyDetails: () => Promise<PolicyDetailsReferenceData>;
};

const referenceDataClient: ReferenceDataClient = {
  accountDetails: () =>
    apiService
      .get<AccountDetailsReferenceDataResponse>('referencedata/account')
      .then(mapAccountDetailsRefData),
  convictions: () =>
    apiService
      .get<ConvictionsReferenceDataResponse>('referencedata/convictions')
      .then(mapConvictionsRefData),
  vehicleDetails: () =>
    apiService
      .get<VehicleDetailsReferenceDataResponse>('referencedata/vehicle')
      .then(mapVehicleDetailsRefData),
  driverDetails: () =>
    apiService
      .get<DriverDetailsReferenceDataResponse>('referencedata/driver')
      .then(mapDriverDetailsRefData),
  modifications: () =>
    apiService
      .get<ModificationsReferenceDataResponse>('referencedata/modifications')
      .then(mapModificationsRefData),
  occupations: () =>
    apiService
      .get<OccupationsReferenceDataResponse>('referencedata/driver/occupation')
      .then(mapOccupationsRefData),
  policyDetails: () =>
    apiService
      .get<PolicyDetailsReferenceDataResponse>('referencedata/policy')
      .then(mapPolicyDetailsRefData),
};

export default referenceDataClient;
