import { useCallback } from 'react';
import { RESET_STATE } from 'state/actions';
import useDispatch from 'state/useDispatch';

const useResetReduxState = (): (() => void) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch({ type: RESET_STATE });
  }, [dispatch]);
};

export default useResetReduxState;
