import { parse, ParsedQuery } from 'query-string';

const getParam = (params: ParsedQuery<string>, key: string): string | null => {
  const param = params[key];
  if (param === undefined || param === null) {
    return null;
  }
  return Array.isArray(param) ? param[0] : param;
};

export const getQueryParam = (location: Location, key: string): string | null => {
  const params = parse(location.search);
  return getParam(params, key);
};

export const getHashParam = (location: Location, key: string): string | null => {
  const params = parse(location.hash);
  return getParam(params, key);
};
