import { getDocumentsDetails } from 'api/businessLogic/documents';
import { PreferenceDetails } from 'state/formData/preferenceDetails';
import { Preferences } from 'state/quote/quote';

const mapPreferences = (preferences: Preferences): PreferenceDetails => ({
  shouldKeepInformed: preferences.receiveExclusiveOffers ?? undefined,
  ...getDocumentsDetails(preferences),
});

export default mapPreferences;
