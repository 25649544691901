import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RESET_STATE, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import {
  AddDriverMtaDetails,
  initialAddDriverMtaDetails,
  UPDATE_MTA_ADD_DRIVER_DETAILS,
  UpdateMtaAddDriverAction,
} from 'state/formData/mta/addDriver';
import {
  UPDATE_MTA_CHANGE_CAR_DETAILS,
  UpdateMtaChangeCarDetailsAction,
} from 'state/formData/mta/changeCar';
import {
  EditAddressMtaDetails,
  initialEditAddressMtaDetails,
  UPDATE_MTA_EDIT_ADDRESS_DETAILS,
  UpdateMtaEditAddressAction,
} from 'state/formData/mta/editAddress';
import {
  EditDriverMtaDetails,
  initialEditDriverMtaDetails,
  UPDATE_MTA_EDIT_DRIVER_DETAILS,
  UpdateMtaEditDriverAction,
} from 'state/formData/mta/editDriver';
import {
  UPDATE_MTA_REMOVE_DRIVER_DETAILS,
  UpdateMtaRemoveDriverAction,
} from 'state/formData/mta/removeDriver';
import { initialVehicleDetails, VehicleDetails } from 'state/formData/vehicleDetails';
import { MigratedFieldsFlags } from 'state/policy/policy';
import useDispatch from 'state/useDispatch';

export type MtaFormType =
  | 'Change car'
  | 'Edit address'
  | 'Add a driver'
  | 'Edit a driver'
  | 'Remove a driver';

export const UPDATE_MTA_STATE = 'UPDATE_MTA_STATE';

export type UpdateMtaStateAction = {
  type: typeof UPDATE_MTA_STATE;
  mtaState: Partial<MtaState>;
};

type MtaFormData = {
  editAddress: EditAddressMtaDetails;
  changeCar: VehicleDetails;
  addDriver: AddDriverMtaDetails;
  editDriver: EditDriverMtaDetails;
  removeDriver: { driverId: string };
};

export type MtaState = {
  mtaInProgress: MtaFormType | null;
  migratedFields?: MigratedFieldsFlags;
} & MtaFormData;

export const initialMtaState: MtaState = {
  mtaInProgress: null,
  editAddress: initialEditAddressMtaDetails,
  changeCar: initialVehicleDetails,
  addDriver: initialAddDriverMtaDetails,
  editDriver: initialEditDriverMtaDetails,
  removeDriver: { driverId: '' },
};

export type UpdateMtaStateActions =
  | UpdateMtaStateAction
  | UpdateMtaChangeCarDetailsAction
  | UpdateMtaEditAddressAction
  | UpdateMtaAddDriverAction
  | UpdateMtaEditDriverAction
  | UpdateMtaRemoveDriverAction
  | ResetStateAction;

export const mtaDataReducer = (
  mtaState: MtaState = initialMtaState,
  action: UpdateMtaStateActions
): MtaState => {
  switch (action.type) {
    case UPDATE_MTA_STATE:
      return {
        ...mtaState,
        ...action.mtaState,
      };
    case UPDATE_MTA_CHANGE_CAR_DETAILS:
      return {
        ...mtaState,
        mtaInProgress: 'Change car',
        changeCar: { ...mtaState.changeCar, ...action.update },
      };
    case UPDATE_MTA_EDIT_ADDRESS_DETAILS:
      return {
        ...mtaState,
        mtaInProgress: 'Edit address',
        editAddress: { ...mtaState.editAddress, ...action.update },
      };
    case UPDATE_MTA_ADD_DRIVER_DETAILS:
      return {
        ...mtaState,
        mtaInProgress: 'Add a driver',
        addDriver: { ...mtaState.addDriver, ...action.update },
      };
    case UPDATE_MTA_EDIT_DRIVER_DETAILS:
      return {
        ...mtaState,
        mtaInProgress: 'Edit a driver',
        editDriver: { ...mtaState.editDriver, ...action.update },
      };
    case UPDATE_MTA_REMOVE_DRIVER_DETAILS:
      return {
        ...mtaState,
        mtaInProgress: 'Remove a driver',
        removeDriver: { ...mtaState.removeDriver, ...action.update },
      };
    case RESET_STATE:
      return initialMtaState;
    default:
      return mtaState;
  }
};

export const useMtaState = (): [MtaState, (update: Partial<MtaState>) => void] => {
  const mtaState = useSelector((state: RootState) => state.mtaState);
  const dispatch = useDispatch();

  const updateMtaState = useCallback(
    (update: Partial<MtaState>): void => {
      dispatch({ type: UPDATE_MTA_STATE, mtaState: update });
    },
    [dispatch]
  );

  return [mtaState, updateMtaState];
};

export const useResetMtaState = (): (() => void) => {
  const dispatch = useDispatch();
  const resetMtaState = useCallback((): void => {
    dispatch({ type: UPDATE_MTA_STATE, mtaState: initialMtaState });
  }, [dispatch]);

  return resetMtaState;
};
