import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { AddressDetails, initialPostcodeLookup } from '../driverDetails';

export const UPDATE_MTA_EDIT_ADDRESS_DETAILS = 'UPDATE_MTA_EDIT_ADDRESS_DETAILS';

export type UpdateMtaEditAddressAction = {
  type: typeof UPDATE_MTA_EDIT_ADDRESS_DETAILS;
  update: Partial<EditAddressMtaDetails>;
};

export type EditAddressMtaDetails = {
  overnightParkingLocation: string;
  isParkedAtHomeAddressOvernight: boolean | undefined;
  overnightParkingPostcode: string;
  daytimeParkingLocation: string;
  carUse: string;
  personalMileage: string;
  businessMileage: string;
} & AddressDetails;

export const initialEditAddressMtaDetails: EditAddressMtaDetails = {
  isManualAddress: false,
  usePreviousAddress: false,
  postcodeLookup: initialPostcodeLookup,
  address: undefined,
  house: '',
  flat: '',
  street: '',
  town: '',
  county: '',
  postcode: '',
  overnightParkingLocation: '',
  isParkedAtHomeAddressOvernight: undefined,
  overnightParkingPostcode: '',
  daytimeParkingLocation: '',
  carUse: '',
  personalMileage: '',
  businessMileage: '',
  uprn: 'TestUPRN',
};

export const useEditAddressMtaDetails = (): [
  EditAddressMtaDetails,
  (update: Partial<EditAddressMtaDetails>) => void
] => {
  const mtaState = useSelector((state: RootState) => state.mtaState);
  const dispatch = useDispatch();

  const updateMtaFormState = useCallback(
    (update: Partial<EditAddressMtaDetails>): void => {
      dispatch({
        type: UPDATE_MTA_EDIT_ADDRESS_DETAILS,
        update,
      });
    },
    [dispatch]
  );

  return [mtaState.editAddress, updateMtaFormState];
};
