import { graphql, useStaticQuery } from 'gatsby';
import { PageType } from 'components/Layout';

type SessionExpiryModalCsContent = {
  modal_id: string;
  header: string;
  body: string;
  cta: {
    cta_text: string;
    cta_screenreader_text: string;
  };
  link: {
    link_text?: string;
    link_url?: string;
  };
};

type SessionExpiryModalsCsContent = {
  allCsSessionExpiryModal: {
    edges: {
      node: SessionExpiryModalCsContent;
    }[];
  };
};

const allSessionExpiryModalsQuery = graphql`
  query {
    allCsSessionExpiryModal {
      edges {
        node {
          modal_id
          header
          body
          cta {
            cta_text
            cta_screenreader_text
          }
          link {
            link_text
            link_url
          }
        }
      }
    }
  }
`;

const getSessionExpiryModalCsId = (pageType: PageType): string => {
  switch (pageType) {
    case 'accountArea':
    case 'mtaAndRenewal':
      return 'self-serve-session-expiry-modal';
    case 'quoteAndBuy':
    case 'error':
    default:
      return 'quote-and-buy-session-expiry-modal';
  }
};

const useSessionExpiryModalData = (pageType: PageType): SessionExpiryModalCsContent => {
  const staticData = useStaticQuery<SessionExpiryModalsCsContent>(
    allSessionExpiryModalsQuery
  );

  const sessionExpiryModalCsId = getSessionExpiryModalCsId(pageType);

  const content = staticData.allCsSessionExpiryModal.edges.find(
    (modal) => modal.node.modal_id === sessionExpiryModalCsId
  );

  if (!content) {
    throw new Error(
      `Session expiry modal with modal_id '${sessionExpiryModalCsId}' not found`
    );
  }

  return content.node;
};

export default useSessionExpiryModalData;
