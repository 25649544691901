import { AddOn, BreakdownCoverLevel, Quote, Renewal } from 'state/quote/quote';

export type CustomUserData = {
  customerAge?: string;
  aggregator?: string | null;
  coveragesSelected?: string;
  breakdownSelected?: BreakdownCoverLevel | null;
  errorCode?: string;
  quoteNumber?: string;
};

const getAge = (dateOfBirth: Date): number => {
  const today = new Date();
  const age = today.getFullYear() - dateOfBirth.getFullYear();
  const monthDifference = today.getMonth() - dateOfBirth.getMonth();

  return monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < dateOfBirth.getDate())
    ? age - 1
    : age;
};

const getCoveragesNames = (coverages: AddOn[]): string => {
  return coverages
    .filter((coverage) => coverage.isSelected)
    .map((coverage) => coverage.type)
    .join(', ');
};

const generateQuoteCustomUserData = (quote: Quote | Renewal | null): CustomUserData => {
  if (!quote) {
    return {};
  }
  return {
    customerAge: getAge(new Date(quote.policyHolder.dateOfBirth)).toString(),
    aggregator: quote.aggregatorName,
    coveragesSelected: getCoveragesNames(quote.coverages),
    breakdownSelected: quote.breakdown.selectedBreakdown.coverLevel,
    quoteNumber: quote.quoteNumber,
  };
};

const updateCustomUserData = (customUserData: CustomUserData): void => {
  if (window.getCustomUserData == null || window.updateCustomUserData == null) {
    return;
  }
  const currentCustomUserData = window.getCustomUserData();
  window.updateCustomUserData({ ...currentCustomUserData, ...customUserData });
};

export const updateErrorCustomUserData = (errorCode: string): void => {
  updateCustomUserData({ errorCode });
};

export const updateQuoteCustomUserData = (quote: Quote | Renewal | null): void => {
  updateCustomUserData(generateQuoteCustomUserData(quote));
};
