import { Quote, Renewal } from 'state/quote/quote';
import mapDriverClaim from './mapClaim';
import mapConviction from './mapConviction';
import { CoverDetails } from '../../../state/formData/coverDetails';

const mapCoverDetails = (quote: Quote | Renewal): CoverDetails => {
  const quoteCoverDetails = quote.policy;
  const quoteDriverDetails = quote.policyHolder;
  const quoteVehicleDetails = quote.vehicle;
  const coverDetails: CoverDetails = {
    paymentOption: quoteCoverDetails.paymentOption,
    coverType: quoteCoverDetails.coverWanted,
    coverStartDate: quoteCoverDetails.effectiveFromDate,
    currentNoClaimsBonus: quoteVehicleDetails.currentNoClaimsBonus,
    hasHandedBackCompanyCar: quoteVehicleDetails.handedBackCompanyCar ?? undefined,
    isCompanyCarSocial: quoteVehicleDetails.privateCarIndicator ?? undefined,
    companyCarOwnedLength: quoteVehicleDetails.lengthHeldCompanyCar?.toString() || '',
    hasMotoringAccidents: quoteDriverDetails.isAccidentsOrLossesPast5Years,
    claims: quoteDriverDetails.driverClaims?.map(mapDriverClaim) || [],
    hasMotoringConvictions:
      quoteDriverDetails.isPenaltiesOrEndorsementsPast5Years ?? undefined,
    convictions: quoteDriverDetails.motoringConvictions?.map(mapConviction) || [],
    hasUnspentNonMotoringConvictions:
      quoteDriverDetails.unspentNonMotoringOffencesIndicator ?? undefined,
    hasRefusedInsurance: quoteDriverDetails.refusedCarInsuranceIndicator ?? undefined,
    isCarImpounded: quoteVehicleDetails.isImpounded ?? undefined,
  };
  return coverDetails;
};

export default mapCoverDetails;
