import { mapApiOption } from 'api/referenceData/mapApiOption';
import { ApiOption, ReferenceDataOption } from 'types/referenceData';

export type DriverDetailsReferenceDataResponse = {
  'DriverInput.EmploymentStatus': ApiOption[];
  'DriverInput.Gender': ApiOption[];
  'DriverInput.LicenceHeldMonths': ApiOption[];
  'DriverInput.LicenceHeldYears': ApiOption[];
  'DriverInput.LicenceType': ApiOption[];
  'DriverInput.MaritalStatus': ApiOption[];
  'DriverInput.OtherLicenceType': ApiOption[];
  'DriverInput.RelationshipToPolicyHolder': ApiOption[];
  'DriverInput.Title': ApiOption[];
  'DriverInput.UseOfOtherVehicles': ApiOption[];
  'DriverPreInceptionClaimInput.CauseOfLoss': ApiOption[];
  'AssociatedDriverInput.UseOfVehicle': ApiOption[];
};

export type DriverDetailsReferenceData = {
  causeOfLoss: ReferenceDataOption[];
  employmentStatus: ReferenceDataOption[];
  gender: ReferenceDataOption[];
  licenceHeldMonths: ReferenceDataOption[];
  licenceHeldYears: ReferenceDataOption[];
  licenceType: ReferenceDataOption[];
  maritalStatus: ReferenceDataOption[];
  otherLicenceType: ReferenceDataOption[];
  relationshipToPolicyHolder: ReferenceDataOption[];
  title: ReferenceDataOption[];
  useOfOtherVehicles: ReferenceDataOption[];
  useOfVehicle: ReferenceDataOption[];
};

export const mapDriverDetailsRefData = (
  data: DriverDetailsReferenceDataResponse
): DriverDetailsReferenceData => ({
  causeOfLoss: data['DriverPreInceptionClaimInput.CauseOfLoss'].map(mapApiOption),
  employmentStatus: data['DriverInput.EmploymentStatus'].map(mapApiOption),
  gender: data['DriverInput.Gender'].map(mapApiOption),
  licenceHeldMonths: data['DriverInput.LicenceHeldMonths'].map(mapApiOption),
  licenceHeldYears: data['DriverInput.LicenceHeldYears'].map(mapApiOption),
  licenceType: data['DriverInput.LicenceType'].map(mapApiOption),
  maritalStatus: data['DriverInput.MaritalStatus'].map(mapApiOption),
  otherLicenceType: data['DriverInput.OtherLicenceType'].map(mapApiOption),
  relationshipToPolicyHolder: data['DriverInput.RelationshipToPolicyHolder'].map(
    mapApiOption
  ),
  title: data['DriverInput.Title'].map(mapApiOption),
  useOfOtherVehicles: data['DriverInput.UseOfOtherVehicles'].map(mapApiOption),
  useOfVehicle: data['AssociatedDriverInput.UseOfVehicle'].map(mapApiOption),
});
