import React from 'react';
import { PageType } from 'components/Layout';
import DefaultSessionExpiryWrapper from './DefaultSessionExpiryWrapper';
import SilentSessionExpiryWrapper from './SilentSessionExpiryWrapper';

export enum SessionExpiryOption {
  NO_SESSION_EXPIRY = 'NO_SESSION_EXPIRY',
  DEFAULT_SESSION_EXPIRY = 'DEFAULT_SESSION_EXPIRY',
  SILENT_SESSION_EXPIRY = 'SILENT_SESSION_EXPIRY',
}

type SessionExpiryWrapperProps = {
  pageType: PageType;
  sessionExpiryOption: SessionExpiryOption;
};

const SessionExpiryWrapper: React.FC<SessionExpiryWrapperProps> = ({
  pageType,
  sessionExpiryOption,
  children,
}) => {
  switch (sessionExpiryOption) {
    case SessionExpiryOption.NO_SESSION_EXPIRY: {
      return <>{children}</>;
    }
    case SessionExpiryOption.SILENT_SESSION_EXPIRY: {
      return <SilentSessionExpiryWrapper>{children}</SilentSessionExpiryWrapper>;
    }
    case SessionExpiryOption.DEFAULT_SESSION_EXPIRY:
    default: {
      return (
        <DefaultSessionExpiryWrapper pageType={pageType}>
          {children}
        </DefaultSessionExpiryWrapper>
      );
    }
  }
};

export default SessionExpiryWrapper;
