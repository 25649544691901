import mapAdditionalDrivers from 'api/quote/quoteToFormMappings/mapAdditionalDrivers';
import { RESET_STATE, ResetStateAction } from 'state/actions';
import {
  RESET_QUOTE,
  ResetQuoteAction,
  UPDATE_QUOTE,
  UPDATE_RENEWAL,
  UpdateQuoteAction,
} from 'state/quote/quote';
import {
  AdditionalDriversDetails,
  initialAdditionalDriversDetails,
  UPDATE_ADDITIONAL_DRIVERS_DETAILS,
  UpdateAdditionalDriversDetailsAction,
} from './additionalDriversDetails';
import { mergeFormData } from './mergeFormData';
import { UPDATE_QUOTE_IN_PROGRESS, UpdateQuoteInProgressAction } from './quoteInProgress';

export const additionalDriversDetailsReducer = (
  additionalDriversDetails: AdditionalDriversDetails = initialAdditionalDriversDetails,
  action:
    | UpdateAdditionalDriversDetailsAction
    | UpdateQuoteInProgressAction
    | UpdateQuoteAction
    | ResetQuoteAction
    | ResetStateAction
): AdditionalDriversDetails => {
  switch (action.type) {
    case UPDATE_QUOTE:
    case UPDATE_RENEWAL:
      return action.quote ? mapAdditionalDrivers(action.quote) : additionalDriversDetails;
    case UPDATE_ADDITIONAL_DRIVERS_DETAILS:
      return {
        ...additionalDriversDetails,
        ...action.update,
      };
    case UPDATE_QUOTE_IN_PROGRESS:
      if (action.quote.additionalDriversDetails) {
        return mergeFormData(
          additionalDriversDetails,
          action.quote.additionalDriversDetails,
          initialAdditionalDriversDetails
        );
      }
      return additionalDriversDetails;
    case RESET_QUOTE:
    case RESET_STATE:
      return initialAdditionalDriversDetails;
    default:
      return additionalDriversDetails;
  }
};
