import { dateToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { QuoteAdditionalDriverDetails } from 'api/quote/quoteRequest';
import { Quote, Renewal } from 'state/quote/quote';
import mapDriverClaim from './mapClaim';
import mapConviction from './mapConviction';
import {
  AdditionalDriver,
  AdditionalDriversDetails,
  AdditionalDriverWithKey,
  MAIN_DRIVER_POLICY_HOLDER_VALUE,
} from '../../../state/formData/additionalDriversDetails';

export const mapSingleAdditionalDriver = (
  quoteAdditionalDriver: QuoteAdditionalDriverDetails
): AdditionalDriver => ({
  relationshipToPolicyHolder: quoteAdditionalDriver.relationshipToPolicyHolder,
  title: quoteAdditionalDriver.title,
  firstName: quoteAdditionalDriver.firstName,
  lastName: quoteAdditionalDriver.lastName,
  dateOfBirth: dateToDateValue(new Date(quoteAdditionalDriver.dateOfBirth)),
  gender: quoteAdditionalDriver.gender,
  maritalStatus: quoteAdditionalDriver.maritalStatus,
  hasLivedInUkSinceBirth: quoteAdditionalDriver.livedInUkSinceBirthIndicator,
  ukResidentSince: dateToDateValue(new Date(quoteAdditionalDriver.ukResidentSince)),
  licenceType: quoteAdditionalDriver.licenceType,
  otherLicenceType: quoteAdditionalDriver.otherLicenceType || '',
  licenceHeldYears: quoteAdditionalDriver.licenceHeldYears.toString(),
  licenceHeldMonths: quoteAdditionalDriver.licenceHeldMonths
    ? quoteAdditionalDriver.licenceHeldMonths.toString()
    : '',
  licenceNumber: quoteAdditionalDriver.licenceNumber || '',
  hasMedicalConditionsOrDisabilities:
    quoteAdditionalDriver.medicalConditionsOrDisabilities ?? undefined,
  isMedicalConditionsNotified:
    quoteAdditionalDriver.medicalConditionsNotified ?? undefined,
  useOfOtherVehicles: quoteAdditionalDriver.useOfOtherVehicles,
  carUse: quoteAdditionalDriver.useOfVehicle,
  employmentStatus: quoteAdditionalDriver.employmentStatus,
  primaryOccupation: quoteAdditionalDriver.primaryOccupation || '',
  primaryOccupationIndustry: quoteAdditionalDriver.primaryOccupationIndustry || '',
  hasSecondaryOccupation: quoteAdditionalDriver.isSecondaryOccupation,
  secondaryOccupation: quoteAdditionalDriver.secondaryOccupation || '',
  secondaryOccupationIndustry: quoteAdditionalDriver.secondaryOccupationIndustry || '',
  hasMotoringAccidents: quoteAdditionalDriver.isAccidentsOrLossesPast5Years,
  claims: quoteAdditionalDriver.driverClaims?.map(mapDriverClaim) || [],
  hasMotoringConvictions:
    quoteAdditionalDriver.isPenaltiesOrEndorsementsPast5Years ?? undefined,
  convictions: quoteAdditionalDriver.motoringConvictions?.map(mapConviction) || [],
  hasUnspentNonMotoringConvictions:
    quoteAdditionalDriver.unspentNonMotoringOffencesIndicator ?? undefined,
  hasRefusedInsurance: quoteAdditionalDriver.refusedCarInsuranceIndicator ?? undefined,
  driverId: quoteAdditionalDriver.driverId,
});

const mapSingleAdditionalDriverWithKey = (
  quoteAdditionalDriver: QuoteAdditionalDriverDetails,
  index: number
): AdditionalDriverWithKey => ({
  ...mapSingleAdditionalDriver(quoteAdditionalDriver),
  key: index,
});

const mapAdditionalDrivers = (quote: Quote | Renewal): AdditionalDriversDetails => ({
  addAdditionalDrivers: quote.policy.additionalDrivers,
  additionalDrivers: quote.additionalDrivers.map(mapSingleAdditionalDriverWithKey),
  mainDriver: quote.policyHolder.isMainDriver
    ? MAIN_DRIVER_POLICY_HOLDER_VALUE
    : quote.additionalDrivers.indexOf(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        quote.additionalDrivers.find((driver) => driver.isMainDriver)!
      ),
});

export default mapAdditionalDrivers;
