import { QuoteAddress } from 'api/quote/quoteRequest';

export const quoteAddressToString = (address: QuoteAddress): string => {
  const houseNameOrNumber =
    address.houseNameOrNumber ?? address.houseNumber ?? address.houseName;

  return [
    address.flatNumber,
    address.flatName,
    houseNameOrNumber,
    address.street,
    address.city,
  ]
    .filter(Boolean)
    .join(', ');
};
