import { QuoteAccountDetails } from 'api/quote/quoteRequest';
import { accountRoutes, quoteAndBuyRoutes } from './routingHelper';

const applicationId = process.env.GATSBY_AZURE_APPLICATION_ID;
const siteUrl = process.env.GATSBY_SITE_URL;
const azureBaseUrl = process.env.GATSBY_AZURE_URL;

export enum AzurePolicyType {
  SIGN_UP_SIGN_IN,
  CONFIRMATION,
  CHANGE_PASSWORD,
  CHANGE_EMAIL,
}

export const AzurePolicyNameMapping: Record<AzurePolicyType, string> = {
  [AzurePolicyType.SIGN_UP_SIGN_IN]:
    process.env.GATSBY_AZURE_SIGN_UP_SIGN_IN_POLICY || '',
  [AzurePolicyType.CONFIRMATION]:
    process.env.GATSBY_AZURE_CONFIRMATION_SIGN_UP_POLICY || '',
  [AzurePolicyType.CHANGE_PASSWORD]:
    process.env.GATSBY_AZURE_CHANGE_PASSWORD_POLICY || '',
  [AzurePolicyType.CHANGE_EMAIL]: process.env.GATSBY_AZURE_CHANGE_EMAIL_POLICY || '',
};

const getSearchParams = (extraParams?: Record<string, string>): URLSearchParams => {
  const redirectUri = `${siteUrl}${accountRoutes.login}`;

  return new URLSearchParams({
    client_id: applicationId || '',
    redirect_uri: encodeURI(redirectUri),
    scope: 'openid',
    response_type: 'id_token',
    ...extraParams,
  });
};

const getAzureUrl = (policyName: string, params: URLSearchParams): string =>
  `${azureBaseUrl}/${policyName}/oauth2/v2.0/authorize?${params.toString()}`;

export enum SignUpSignInJourneyStep {
  Login = 'SignIn',
  Register = 'SignUp',
  ForgotPassword = 'ForgotPassword',
}

export const getAzureLoginUrl = (
  journeyStep: SignUpSignInJourneyStep,
  nonce: string,
  email?: string
): string => {
  const params = getSearchParams({
    nonce,
    journeyStep,
  });

  if (email) {
    params.set('login_hint', email);
  }

  return getAzureUrl(AzurePolicyNameMapping[AzurePolicyType.SIGN_UP_SIGN_IN], params);
};

export const getAzureConfirmationSignUpUrl = (
  account: QuoteAccountDetails,
  policyNumber: string,
  nonce: string
): string => {
  const params = getSearchParams({
    nonce,
    email: account.email,
    dateOfBirth: account.dateOfBirth,
    firstName: account.firstName,
    lastName: account.lastName,
    postCode: account.address.postcode || '',
    redirect_uri: encodeURI(`${siteUrl}${quoteAndBuyRoutes.confirmation()}`),
    policyNumber,
  });

  return getAzureUrl(AzurePolicyNameMapping[AzurePolicyType.CONFIRMATION], params);
};

export const getAzureChangePasswordUrl = (nonce: string): string => {
  const params = getSearchParams({
    nonce,
  });

  return getAzureUrl(AzurePolicyNameMapping[AzurePolicyType.CHANGE_PASSWORD], params);
};

export const getAzureChangeEmailUrl = (nonce: string): string => {
  const params = getSearchParams({
    nonce,
  });

  return getAzureUrl(AzurePolicyNameMapping[AzurePolicyType.CHANGE_EMAIL], params);
};

export const getAzureLogoutUrlWithRedirectUrl = (redirectUrl: string): string => {
  const params = new URLSearchParams({
    post_logout_redirect_uri: redirectUrl,
  });

  return `${azureBaseUrl}/${
    AzurePolicyNameMapping[AzurePolicyType.SIGN_UP_SIGN_IN]
  }/oauth2/v2.0/logout?${params.toString()}`;
};

export const getAzureLogoutUrlWithRedirectPath = (redirectPath: string): string =>
  getAzureLogoutUrlWithRedirectUrl(`${siteUrl}${redirectPath}`);
