import { RESET_STATE, ResetStateAction } from 'state/actions';
import {
  RESET_ERROR,
  ResetErrorAction,
  UPDATE_ERROR,
  UpdateErrorAction,
} from './actions';
import { ErrorState } from './state';

const errorReducer = (
  state: ErrorState = {},
  action: UpdateErrorAction | ResetErrorAction | ResetStateAction
): ErrorState => {
  switch (action.type) {
    case UPDATE_ERROR:
      return { errorType: action.errorType, statusCode: action.statusCode };
    case RESET_ERROR:
    case RESET_STATE:
      return {};
    default:
      return state;
  }
};

export default errorReducer;
