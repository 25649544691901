import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import { MigratedAdditionalDriverDetailsFlags } from 'state/policy/policy';
import useDispatch from 'state/useDispatch';
import { initialDateValue, initialDateValueIgnoreDay } from './shared/dateValue';
import { DriverClaim } from './shared/driverClaim';
import { MotoringConviction } from './shared/motoringConviction';

export const UPDATE_ADDITIONAL_DRIVERS_DETAILS = 'UPDATE_ADDITIONAL_DRIVERS_DETAILS';

export type AdditionalDriverWithKey = AdditionalDriver & {
  key: number;
};

export type AdditionalDriver = {
  title: string;
  gender: string;
  firstName: string;
  lastName: string;
  dateOfBirth: DateValue;
  maritalStatus: string;
  relationshipToPolicyHolder: string;
  hasLivedInUkSinceBirth: boolean | undefined;
  ukResidentSince: DateValue;
  licenceType: string;
  otherLicenceType: string;
  licenceHeldYears: string;
  licenceHeldMonths: string;
  licenceNumber: string;
  hasMedicalConditionsOrDisabilities: boolean | undefined;
  isMedicalConditionsNotified: boolean | undefined;
  carUse: string;
  useOfOtherVehicles: string;
  employmentStatus: string;
  primaryOccupation: string;
  primaryOccupationIndustry: string;
  hasSecondaryOccupation: boolean | undefined;
  secondaryOccupation: string;
  secondaryOccupationIndustry: string;
  hasMotoringAccidents: boolean | undefined;
  claims: DriverClaim[];
  hasMotoringConvictions: boolean | undefined;
  convictions: MotoringConviction[];
  hasUnspentNonMotoringConvictions: boolean | undefined;
  hasRefusedInsurance: boolean | undefined;
  migratedFields?: MigratedAdditionalDriverDetailsFlags;
  driverId?: string;
};

export const MAIN_DRIVER_POLICY_HOLDER_VALUE = 'POLICY_HOLDER';

export type AdditionalDriversDetails = {
  addAdditionalDrivers: boolean | undefined;
  additionalDrivers: AdditionalDriverWithKey[];
  // The number represents the unique key of the additional driver
  mainDriver: number | typeof MAIN_DRIVER_POLICY_HOLDER_VALUE | undefined;
  migratedFields?: MigratedAdditionalDriverDetailsFlags[];
};

export const initialAdditionalDriver: AdditionalDriver = {
  title: '',
  gender: '',
  firstName: '',
  lastName: '',
  dateOfBirth: initialDateValue,
  maritalStatus: '',
  relationshipToPolicyHolder: '',
  hasLivedInUkSinceBirth: undefined,
  ukResidentSince: initialDateValueIgnoreDay,
  licenceType: '',
  otherLicenceType: '',
  licenceHeldYears: '',
  licenceHeldMonths: '',
  licenceNumber: '',
  hasMedicalConditionsOrDisabilities: undefined,
  isMedicalConditionsNotified: undefined,
  carUse: '',
  useOfOtherVehicles: '',
  employmentStatus: '',
  primaryOccupation: '',
  primaryOccupationIndustry: '',
  hasSecondaryOccupation: undefined,
  secondaryOccupation: '',
  secondaryOccupationIndustry: '',
  hasMotoringAccidents: undefined,
  claims: [],
  hasMotoringConvictions: undefined,
  convictions: [],
  hasUnspentNonMotoringConvictions: undefined,
  hasRefusedInsurance: undefined,
};

export const initialAdditionalDriversDetails: AdditionalDriversDetails = {
  addAdditionalDrivers: undefined,
  additionalDrivers: [],
  mainDriver: undefined,
};

export type UpdateAdditionalDriversDetailsAction = {
  type: typeof UPDATE_ADDITIONAL_DRIVERS_DETAILS;
  update: Partial<AdditionalDriversDetails>;
};

export const useAdditionalDriversDetails = (): [
  AdditionalDriversDetails,
  (update: Partial<AdditionalDriversDetails>) => void
] => {
  const additionalDriversDetails = useSelector(
    (state: RootState) => state.additionalDriversDetails
  );
  const dispatch = useDispatch();

  const updateAdditionalDriversDetails = (
    update: Partial<AdditionalDriversDetails>
  ): void => {
    dispatch({ type: UPDATE_ADDITIONAL_DRIVERS_DETAILS, update });
  };

  return [additionalDriversDetails, updateAdditionalDriversDetails];
};
