import mapDriverDetails from 'api/quote/quoteToFormMappings/mapDriverDetails';
import { RESET_STATE, ResetStateAction } from 'state/actions';
import {
  RESET_QUOTE,
  ResetQuoteAction,
  UPDATE_QUOTE,
  UPDATE_RENEWAL,
  UpdateQuoteAction,
} from 'state/quote/quote';
import {
  DriverDetails,
  initialDriverDetails,
  UPDATE_DRIVER_DETAILS,
  UpdateDriverDetailsAction,
} from './driverDetails';
import { mergeFormData } from './mergeFormData';
import { UPDATE_QUOTE_IN_PROGRESS, UpdateQuoteInProgressAction } from './quoteInProgress';

export const driverDetailsReducer = (
  driverDetails: DriverDetails = initialDriverDetails,
  action:
    | UpdateDriverDetailsAction
    | UpdateQuoteInProgressAction
    | UpdateQuoteAction
    | ResetQuoteAction
    | ResetStateAction
): DriverDetails => {
  switch (action.type) {
    case UPDATE_QUOTE:
    case UPDATE_RENEWAL:
      return action.quote ? mapDriverDetails(action.quote) : driverDetails;
    case UPDATE_DRIVER_DETAILS:
      return {
        ...driverDetails,
        ...action.update,
      };
    case UPDATE_QUOTE_IN_PROGRESS:
      if (action.quote.driverDetails) {
        return mergeFormData(
          driverDetails,
          action.quote.driverDetails,
          initialDriverDetails
        );
      }
      return driverDetails;
    case RESET_QUOTE:
    case RESET_STATE:
      return initialDriverDetails;
    default:
      return driverDetails;
  }
};
