import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import {
  initialLookupValue,
  LookupValue,
} from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import { MigratedPolicyHolderFieldsFlags } from 'state/policy/policy';
import useDispatch from 'state/useDispatch';
import { initialDateValue, initialDateValueIgnoreDay } from './shared/dateValue';

export const UPDATE_DRIVER_DETAILS = 'UPDATE_DRIVER_DETAILS';

export type AddressInfo = { id?: string; fullAddress: string };

export type PostcodeLookup = LookupValue<string, AddressInfo[]>;

export type AddressDetails = {
  isManualAddress: boolean;
  usePreviousAddress: boolean;
  postcodeLookup: PostcodeLookup;
  address: AddressInfo | undefined;
  house: string;
  flat: string;
  street: string;
  town: string;
  county: string;
  postcode: string;
  uprn: string;
};

export type DriverDetails = {
  title: string;
  gender: string;
  firstName: string;
  lastName: string;
  dateOfBirth: DateValue;
  maritalStatus: string;
  lengthLivedAtAddress: string;
  email: string;
  mainTelephone: string;
  hasLivedInUkSinceBirth: boolean | undefined;
  ukResidentSince: DateValue;
  isHomeowner: boolean | undefined;
  hasChildrenUnder16: boolean | undefined;
  numberOfChildrenInHomeUnder16: string;
  licenceType: string;
  otherLicenceType: string;
  licenceHeldYears: string;
  licenceHeldMonths: string;
  licenceNumber: string;
  hasMedicalConditionsOrDisabilities: boolean | undefined;
  isMedicalConditionsNotified: boolean | undefined;
  numberOfCarsInHousehold: string;
  useOfOtherVehicles: string;
  employmentStatus: string;
  primaryOccupation: string;
  primaryOccupationIndustry: string;
  hasSecondaryOccupation: boolean | undefined;
  secondaryOccupation: string;
  secondaryOccupationIndustry: string;
  migratedFields?: MigratedPolicyHolderFieldsFlags;
} & AddressDetails;

export const initialPostcodeLookup = initialLookupValue<string, AddressInfo[]>('');

export const initialAddressDetails: AddressDetails = {
  isManualAddress: false,
  // See mapAddress (quoteToFormMappings) for an explanation of why this property exists.
  usePreviousAddress: false,
  postcodeLookup: initialPostcodeLookup,
  address: undefined,
  house: '',
  flat: '',
  street: '',
  town: '',
  county: '',
  postcode: '',
  uprn: '',
};

export const initialDriverDetails: DriverDetails = {
  title: '',
  gender: '',
  firstName: '',
  lastName: '',
  dateOfBirth: initialDateValue,
  maritalStatus: '',
  lengthLivedAtAddress: '',
  email: '',
  mainTelephone: '',
  hasLivedInUkSinceBirth: undefined,
  ukResidentSince: initialDateValueIgnoreDay,
  isHomeowner: undefined,
  hasChildrenUnder16: undefined,
  numberOfChildrenInHomeUnder16: '',
  licenceType: '',
  otherLicenceType: '',
  licenceHeldYears: '',
  licenceHeldMonths: '',
  licenceNumber: '',
  hasMedicalConditionsOrDisabilities: undefined,
  isMedicalConditionsNotified: undefined,
  numberOfCarsInHousehold: '',
  useOfOtherVehicles: '',
  employmentStatus: '',
  primaryOccupation: '',
  primaryOccupationIndustry: '',
  hasSecondaryOccupation: undefined,
  secondaryOccupation: '',
  secondaryOccupationIndustry: '',
  ...initialAddressDetails,
};

export type UpdateDriverDetailsAction = {
  type: typeof UPDATE_DRIVER_DETAILS;
  update: Partial<DriverDetails>;
};

export const useDriverDetails = (): [
  DriverDetails,
  (update: Partial<DriverDetails>) => void
] => {
  const driverDetails = useSelector((state: RootState) => state.driverDetails);
  const dispatch = useDispatch();

  const updateDriverDetails = (update: Partial<DriverDetails>): void => {
    dispatch({ type: UPDATE_DRIVER_DETAILS, update });
  };

  return [driverDetails, updateDriverDetails];
};
