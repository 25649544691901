import React, { Fragment } from 'react';
import AccordionBlock from 'components/blocks/Accordion';
import ActionCardsBlock from 'components/blocks/ActionCards';
import CtaBannerBlock from 'components/blocks/CtaBanner';
import FaqWidgetBlock from 'components/blocks/FaqWidget';
import FeaturesBlock from 'components/blocks/Features';
import InfoCardsBlock from 'components/blocks/InfoCards';
import IntroBlock from 'components/blocks/Intro';
import LeftAlignHeadingBlock from 'components/blocks/LeftAlignHeading';
import ProductCardsBlock from 'components/blocks/ProductCards';
import AccordionSplitInfoCardBlock from 'components/blocks/SplitInfoCard/AccordionSplitInfoCard';
import { nonFatalBuildError, warningWithDetail } from './errorReporting';

export type CsBlock = {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

type BlockProps<T extends string> = {
  [K in T]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

type MappingEntry<T extends string = string> = [T, React.ComponentType<BlockProps<T>>];

const m = <T extends string>(
  key: T,
  value: React.ComponentType<BlockProps<T>>
): MappingEntry<T> => [key, value];

/**
 * This is the main mapping from blocks in ContentStack to React components.
 *
 * The key should be the name of the block in ContentStack and the value is the corresponding
 * component, where the Props for the component match the block returned by the graphql query.
 *
 * The `m` function is a helper that ensures that the key and props correspond as expected.
 */
const MAPPING: MappingEntry[] = [
  m('intro', IntroBlock),
  m('features', FeaturesBlock),
  m('action_cards', ActionCardsBlock),
  m('cta_banner', CtaBannerBlock),
  m('product_cards', ProductCardsBlock),
  m('accordion', AccordionBlock),
  m('faq_widget', FaqWidgetBlock),
  m('info_cards', InfoCardsBlock),
  m('left_aligned_heading', LeftAlignHeadingBlock),
];

const ACCORDION_MAPPING: MappingEntry[] = [
  m('split_info_card', AccordionSplitInfoCardBlock),
];

const mapBlock = (
  mapping: MappingEntry[],
  block: CsBlock,
  additionalParams?: {
    useCompactProductCards?: boolean;
  }
): JSX.Element | null => {
  const key = Object.entries(block).find(([, value]) => !!value)?.[0];

  /* istanbul ignore if */
  if (!key) {
    warningWithDetail(
      'Unrecognised empty block.',
      'Have you forgotten to extend the page query with a new block type?'
    );
    return null;
  }

  const Block = mapping.find(([k]) => k === key)?.[1];

  /* istanbul ignore if */
  if (!Block) {
    nonFatalBuildError(
      `Unrecognised block of type '${key}'.`,
      'Have you forgotten to update the block mapping?'
    );
    return null;
  }

  if (Block === (ProductCardsBlock as unknown)) {
    return (
      <Block
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...{ [key]: block[key] }}
        compact={additionalParams?.useCompactProductCards}
      />
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Block {...{ [key]: block[key] }} />;
};

const mapBlocksInternal = (mapping: MappingEntry[]) => (
  blocks: CsBlock[],
  additionalParams?: {
    useCompactProductCards?: boolean;
  }
): JSX.Element[] =>
  blocks.map((block, i) => (
    // Blocks will never be reordered
    // eslint-disable-next-line react/no-array-index-key
    <Fragment key={i}>{mapBlock(mapping, block, additionalParams)}</Fragment>
  ));

export const mapBlocks = mapBlocksInternal(MAPPING);
export const mapAccordionBlocks = mapBlocksInternal(ACCORDION_MAPPING);
