import { TertiaryButton } from '@rsa-digital/evo-shared-components/components/Button';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import React, { useState } from 'react';
import styled from 'styled-components';

const RefreshContainer = styled.div`
  position: fixed;
  top: 8px;
  left: 8px;
  z-index: 100; /* Needs to appear above everything */
`;

const StyledButton = styled(TertiaryButton)`
  background: ${colors.neutral08};
  opacity: 1;
`;

const StyledIcon = styled(Icon)`
  margin-left: 0.5em;
`;

// A button that triggers the Gatsby Refresh endpoint
// This may be replaced if a proper refresh webhook from ContentStack is set up
//
// See https://www.gatsbyjs.org/docs/running-a-gatsby-preview-server/
/* istanbul ignore next */
const RefreshButton: React.FC = () => {
  const [submitting, setSubmitting] = useState(false);

  const onClick = async (): Promise<void> => {
    setSubmitting(true);

    try {
      await fetch('/__refresh', { method: 'POST' });
    } catch (err) {
      console.error(err);
    }

    // Pause to make it feel more interactive and have a chance to update
    setTimeout(() => setSubmitting(false), 1500);
  };

  return (
    <RefreshContainer>
      <StyledButton disabled={submitting} onClick={onClick}>
        Refresh content
        <StyledIcon name="refresh" size="medium" />
      </StyledButton>
    </RefreshContainer>
  );
};

export default RefreshButton;
