import axios from 'axios';
import { stringify } from 'query-string';

const apiUrl = process.env.GATSBY_API_URL;

axios.defaults.headers = {
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  Pragma: 'no-cache',
  Expires: '0',
};

const apiService = {
  get: async <T>(
    path: string,
    queryParams?: Record<string, string | undefined>
  ): Promise<T> => {
    // Undefined query params are automatically filtered out by 'stringify'
    const serialisedQueryParams = queryParams ? `?${stringify(queryParams)}` : '';
    const result = await axios.get(`${apiUrl}/${path}${serialisedQueryParams}`, {
      withCredentials: true,
    });
    return result.data;
  },
  put: async <T = void>(path: string, body: unknown): Promise<T> => {
    const result = await axios.put(`${apiUrl}/${path}`, body, { withCredentials: true });
    return result.data;
  },
  patch: async <T = void>(path: string, body: unknown): Promise<T> => {
    const result = await axios.patch(`${apiUrl}/${path}`, body, {
      withCredentials: true,
    });
    return result.data;
  },
  post: async <T>(
    path: string,
    body: unknown,
    queryParams?: Record<string, string | undefined>
  ): Promise<T> => {
    // Undefined query params are automatically filtered out by 'stringify'
    const serialisedQueryParams = queryParams ? `?${stringify(queryParams)}` : '';
    const result = await axios.post(
      `${apiUrl}/${path}${serialisedQueryParams}`,
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );
    return result.data;
  },
  delete: async <T = void>(path: string): Promise<T> => {
    const result = await axios.delete(`${apiUrl}/${path}`, { withCredentials: true });
    return result.data;
  },
};

export default apiService;
