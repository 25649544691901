import {
  initialLookupValue,
  LookupStatus,
} from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { isValueBusinessUse } from 'api/businessLogic/carUse';
import { isValueNoAlarm, isValueNoTracker } from 'api/businessLogic/securityAndTracking';
import mapQuoteToManualVehicleLookup from 'api/quote/quoteToFormMappings/mapManualVehicleLookup';
import { conditionalValue } from 'helpers/mappingHelpers';
import { MtaQuote } from 'state/mta/mtaQuote';
import { Quote, Renewal } from 'state/quote/quote';
import mapModification from './mapModification';
import {
  initialManualLookup,
  VehicleDetails,
} from '../../../state/formData/vehicleDetails';

const mapVehicleDetails = (quote: Quote | MtaQuote | Renewal): VehicleDetails => {
  const hasAlarm = quote.vehicle.thatchamAlarmOrImmobiliser
    ? !isValueNoAlarm(quote.vehicle.thatchamAlarmOrImmobiliser)
    : false;
  const hasTracker = quote.vehicle.professionallyFittedTracker
    ? !isValueNoTracker(quote.vehicle.professionallyFittedTracker)
    : false;

  return {
    ...(conditionalValue(
      !quote.vehicle.registration,
      mapQuoteToManualVehicleLookup(quote.vehicle.vehicleInfo)
    ) || initialManualLookup),
    isManualSearch: !quote.vehicle.registration,
    registrationLookup: quote.vehicle.registration
      ? {
          lookupKey: quote.vehicle.registration,
          data: {
            make: quote.vehicle.vehicleInfo?.make || '',
            model: quote.vehicle.vehicleInfo?.model || '',
            engineSize: quote.vehicle.vehicleInfo?.engineSize || '',
            yearBuilt: Number(quote.vehicle.vehicleInfo?.year),
            insuranceCode: quote.vehicle.insuranceGroupCode || '',
          },
          status: LookupStatus.Success,
        }
      : initialLookupValue(''),
    carValue: quote.vehicle.value,
    carUse: quote.policyHolder.useOfVehicle,
    personalMileage: quote.policyHolder.annualPersonalMileage.toString(),
    businessMileage:
      conditionalValue(
        isValueBusinessUse(quote.policyHolder.useOfVehicle),
        quote.policyHolder.annualBusinessMileage?.toString()
      ) || '',
    hasModifications: quote.vehicle.modifications?.length !== 0,
    modifications: quote.vehicle.modifications?.map(mapModification) || [],
    hasSecurityOrTrackingDevice: hasAlarm || hasTracker,
    alarmOrImmobiliser:
      conditionalValue(
        hasAlarm || hasTracker,
        quote.vehicle.thatchamAlarmOrImmobiliser
      ) || '',
    tracker:
      conditionalValue(
        hasAlarm || hasTracker,
        quote.vehicle.professionallyFittedTracker
      ) || '',
    isLegalOwner: quote.vehicle.isLegalOwner,
    legalOwner:
      conditionalValue(!quote.vehicle.isLegalOwner, quote.vehicle.legalOwnerOfVehicle) ||
      '',
    isRegisteredKeeper: quote.vehicle.isRegisteredKeeper,
    registeredKeeper:
      conditionalValue(
        !quote.vehicle.isRegisteredKeeper,
        quote.vehicle.registeredKeeperOfVehicle
      ) || '',
    legalOwnerDuration: quote.vehicle.ownershipOfCarLength,
    overnightParkingLocation: quote.vehicle.vehicleKeptOvernight,
    isParkedAtHomeAddressOvernight: quote.vehicle.atHomeAddress,
    overnightParkingPostcode:
      conditionalValue(
        !quote.vehicle.atHomeAddress,
        quote.vehicle.postcodeOfVehicleOvernight
      ) || '',
    daytimeParkingLocation: quote.vehicle.carKeptDuringDay,
  };
};

export default mapVehicleDetails;
