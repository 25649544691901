const withOptionalQuoteNumber = (path: string) => (quoteNumber?: string) =>
  `${path}${quoteNumber ? `?quoteNumber=${quoteNumber}` : ''}`;

export const quoteAndBuyRoutes = {
  startQuote: '/quote/start/',
  aboutYourCar: withOptionalQuoteNumber('/quote/about-your-car/'),
  aboutYou: withOptionalQuoteNumber('/quote/about-you/'),
  aboutYourCover: withOptionalQuoteNumber('/quote/about-your-cover/'),
  additionalDrivers: withOptionalQuoteNumber('/quote/additional-drivers/'),
  quoteSummary: withOptionalQuoteNumber('/quote/summary/'),
  checkYourDetails: withOptionalQuoteNumber('/quote/check-your-details/'),
  payment: withOptionalQuoteNumber('/quote/payment/'),
  confirmation: withOptionalQuoteNumber('/quote/confirmation/'),
  ineligibleQuote: '/quote/ineligible/',
  ineligibleTier: '/quote/ineligible-tier/',
  quoteLoading: withOptionalQuoteNumber('/quote/quote-loading/'),
  checkDetailsLoading: withOptionalQuoteNumber('/quote/check-details-loading/'),
  paymentLoading: withOptionalQuoteNumber('/quote/payment-loading/'),
  aggregator: (ref: string): string => `/quote/aggregator/?ref=${ref}`,
  retrieveQuote: (ref: string): string => `/quote/retrieve/?ref=${ref}`,
};

export const accountRoutes = {
  account: '/account/',
  login: '/account/login/',
  logout: '/account/logout/',
  forgottenEmail: '/account/forgotten-email/',
  forgottenPassword: '/account/forgotten-password/',
  register: '/account/register/',
  registerWithQuoteReference: (quoteReference: string): string =>
    `/account/register/?quoteReference=${quoteReference}`,
  changeEmail: '/account/change-email/',
  changePassword: '/account/change-password/',
  policy: (policyNumber: string): string =>
    `/account/policy/?policyNumber=${policyNumber}`,
};

export const mtaRoutes = {
  addDriver: (policyNumber: string): string =>
    `/account/policy/mta/add-driver/?policyNumber=${policyNumber}`,
  editDriver: (policyNumber: string, driverId: string): string =>
    `/account/policy/mta/edit-driver/?policyNumber=${policyNumber}&driverId=${driverId}`,
  removeDriver: (policyNumber: string, driverId: string): string =>
    `/account/policy/mta/remove-driver/?policyNumber=${policyNumber}&driverId=${driverId}`,
  changeAddress: (policyNumber: string): string =>
    `/account/policy/mta/change-address/?policyNumber=${policyNumber}`,
  changeCar: (policyNumber: string): string =>
    `/account/policy/mta/change-car/?policyNumber=${policyNumber}`,
  checkYourDetails: (policyNumber: string): string =>
    `/account/policy/mta/check-your-details/?policyNumber=${policyNumber}`,
  payment: (policyNumber: string): string =>
    `/account/policy/mta/payment/?policyNumber=${policyNumber}`,
  confirmation: (policyNumber: string): string =>
    `/account/policy/mta/confirmation/?policyNumber=${policyNumber}`,
  ineligibleQuote: '/account/policy/mta/ineligible/',
  ineligibleTier: '/account/policy/mta/ineligible-tier/',
};

export const renewalRoutes = {
  aboutYourCar: (policyNumber: string): string =>
    `/account/policy/renewal/about-your-car/?policyNumber=${policyNumber}`,
  aboutYou: (policyNumber: string): string =>
    `/account/policy/renewal/about-you/?policyNumber=${policyNumber}`,
  aboutYourCover: (policyNumber: string): string =>
    `/account/policy/renewal/about-your-cover/?policyNumber=${policyNumber}`,
  additionalDrivers: (policyNumber: string): string =>
    `/account/policy/renewal/additional-drivers/?policyNumber=${policyNumber}`,
  quoteLoading: (policyNumber: string): string =>
    `/account/policy/renewal/quote-loading/?policyNumber=${policyNumber}`,
  quoteSummary: (policyNumber: string): string =>
    `/account/policy/renewal/summary/?policyNumber=${policyNumber}`,
  checkDetailsLoading: (policyNumber: string): string =>
    `/account/policy/renewal/check-details-loading/?policyNumber=${policyNumber}`,
  checkYourDetails: (policyNumber: string): string =>
    `/account/policy/renewal/check-your-details/?policyNumber=${policyNumber}`,
  paymentLoading: (policyNumber: string): string =>
    `/account/policy/renewal/payment-loading/?policyNumber=${policyNumber}`,
  payment: (policyNumber: string): string =>
    `/account/policy/renewal/payment/?policyNumber=${policyNumber}`,
  confirmation: (policyNumber: string): string =>
    `/account/policy/renewal/confirmation/?policyNumber=${policyNumber}`,
  ineligibleQuote: '/account/policy/renewal/ineligible/',
};
