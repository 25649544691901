import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import { BreakdownCoverLevel, SelectedBreakdown, TierOptions } from 'state/quote/quote';
import useDispatch from 'state/useDispatch';

export const UPDATE_ADDONS = 'UPDATE_ADDONS';

export type LocalBreakdown = {
  isSelected: boolean | null | undefined;
  coverLevel: BreakdownCoverLevel | null | undefined;
};

export type BreakdownSelection = {
  isSelected: boolean | null;
  coverLevel: BreakdownCoverLevel | null;
};

export type CoverageSelection = {
  isSelected: boolean | null;
  type: string;
};

export const isLocalBreakdownValid = (
  localBreakdown: LocalBreakdown
): localBreakdown is BreakdownSelection =>
  localBreakdown.isSelected !== undefined && localBreakdown.coverLevel !== undefined;

export const getLatestBreakdownSelection = (
  localSelection: LocalBreakdown,
  quoteSelection: SelectedBreakdown
): BreakdownSelection =>
  isLocalBreakdownValid(localSelection) ? localSelection : quoteSelection;

export const getLatestTierSelection = (
  localSelection: TierOptions | undefined,
  selectedTier: TierOptions | null | undefined
): TierOptions | null => localSelection ?? selectedTier ?? null;

/**
 * For AddonSelection, undefined means "uninitialised" and null means "no option selected".
 * This means that we should only fallback to the quote's values when a value is undefined
 */
export type AddonSelectionUpdate = {
  localCoverages: CoverageSelection[];
  localBreakdown: BreakdownSelection;
  localSelectedTier: TierOptions;
};

export type AddonSelection = {
  localCoverages: CoverageSelection[] | undefined;
  localBreakdown: LocalBreakdown;
  localSelectedTier: TierOptions | undefined;
};

export const initialAddonSelection: AddonSelection = {
  localCoverages: undefined,
  localBreakdown: {
    isSelected: undefined,
    coverLevel: undefined,
  },
  localSelectedTier: undefined,
};

export type UpdateAddonSelectionAction = {
  type: typeof UPDATE_ADDONS;
  update: Partial<AddonSelectionUpdate>;
};

export const useAddonSelection = (): [
  AddonSelection,
  (update: Partial<AddonSelectionUpdate>) => void
] => {
  const addonSelection = useSelector((state: RootState) => state.addonSelection);
  const dispatch = useDispatch();

  const updateAddonSelectionDetails = (update: Partial<AddonSelectionUpdate>): void => {
    dispatch({ type: UPDATE_ADDONS, update });
  };

  return [addonSelection, updateAddonSelectionDetails];
};
