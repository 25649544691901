import FooterComponent from '@rsa-digital/evo-shared-components/components/Footer';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { CsAsset } from 'types/contentStack';
import { FullWidthDividerWithMargin } from './styles';

export type FooterData = {
  csFooter: {
    first_section: {
      title: string;
      links: { url: string; text: string; open_in_new_tab: boolean }[];
    };
    second_section: {
      title: string;
      links: { url: string; text: string; open_in_new_tab: boolean }[];
    };
    third_section: {
      title: string;
      links: { url: string; text: string; logo: CsAsset; open_in_new_tab: boolean }[];
    };
    footer_compliance: {
      logo: CsAsset;
    };
  };
  csQuoteAndBuyFooter: {
    footer_compliance: {
      compliance_text: string;
    };
  };
};

const FooterQuery = graphql`
  query {
    csFooter {
      first_section {
        title
        links {
          url
          text
          open_in_new_tab
        }
      }
      second_section {
        title
        links {
          url
          text
          open_in_new_tab
        }
      }
      third_section {
        title
        links {
          url
          text
          open_in_new_tab
          logo {
            ...CsAsset
          }
        }
      }
      footer_compliance {
        logo {
          ...CsAsset
        }
      }
    }
    csQuoteAndBuyFooter {
      footer_compliance {
        compliance_text
      }
    }
  }
`;

const AccountAreaFooter: React.FC = () => {
  const { csFooter, csQuoteAndBuyFooter } = useStaticQuery<FooterData>(FooterQuery);

  const thirdSection = csFooter.third_section.links.map((link) => {
    const logo = processImageAsset(link.logo);

    /* istanbul ignore if */
    if (!logo) {
      throw new Error(`Footer logo for ${link.text} missing!`);
    }
    return {
      image: logo,
      link: {
        url: link.url,
        text: link.text,
        openInNewTab: link.open_in_new_tab,
      },
    };
  });

  const firstSection = csFooter.first_section.links.map((link) => {
    return {
      url: link.url,
      text: link.text,
      openInNewTab: link.open_in_new_tab,
    };
  });

  const secondSection = csFooter.second_section.links.map((link) => {
    return {
      url: link.url,
      text: link.text,
      openInNewTab: link.open_in_new_tab,
    };
  });

  const footerLinks = {
    firstLargeSection: { title: csFooter.first_section.title, links: firstSection },
    secondLargeSection: { title: csFooter.second_section.title, links: secondSection },
    smallSection: { title: csFooter.third_section.title, links: thirdSection },
  };

  const logo = processImageAsset(csFooter.footer_compliance.logo);

  /* istanbul ignore if */
  if (!logo) {
    throw new Error('Footer compliance logo missing!');
  }

  const footerCompliance = {
    compliance: csQuoteAndBuyFooter.footer_compliance.compliance_text,
    logo,
  };

  return (
    <>
      <FullWidthDividerWithMargin />
      <FooterComponent
        footerLinks={footerLinks}
        footerCompliance={footerCompliance}
        data-cy="accountArea footer"
      />
    </>
  );
};

export default React.memo(AccountAreaFooter);
