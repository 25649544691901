import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Container = styled(Grid)`
  padding-top: ${spacing(6)};

  ${mediaQuery.tabletLandscape`
    padding-top: ${spacing(6)};
  `}
`;

export const DesktopHeadingItem = styled(GridItem)`
  display: none;

  ${mediaQuery.tabletLandscape`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;

export const MobileHeadingItem = styled(GridItem)`
  ${mediaQuery.tabletLandscape`
    display: none;
  `}
`;

export const Heading = styled.h1`
  ${fonts.headingLarge}
  & {
    color: ${colors.core02};
  }

  margin: 0;

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(3)} 0 0;
  `}
`;

export const Subhead = styled.p`
  ${fonts.paragraphLarge}

  margin-top: ${spacing(3)} 0 0;
`;
