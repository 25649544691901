import { VehicleDetails } from 'state/formData/vehicleDetails';

export const isValueNoAlarm = (value: string): boolean => value === 'NoSecurity';

export const selectedNoAlarm = (data: VehicleDetails): boolean =>
  isValueNoAlarm(data.alarmOrImmobiliser);

export const isValueNoTracker = (value: string): boolean => value === '0';

export const selectedNoTracker = (data: VehicleDetails): boolean => {
  return isValueNoTracker(data.tracker);
};
