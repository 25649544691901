import { ContactSectionType } from '@rsa-digital/evo-shared-components/components/Footer/ContactSection';
import { LargeLinkSectionType } from '@rsa-digital/evo-shared-components/components/Footer/FooterLinks';
import { FooterWithContactSectionProps } from '@rsa-digital/evo-shared-components/components/Footer/FooterWithContactSection';
import { graphql, useStaticQuery } from 'gatsby';
import { processImageAsset, unwrapSingleton } from 'helpers/csTypeProcessors';
import { trackButtonClick } from 'helpers/eventTracking';
import { isGoCompareQuote, Quote, Renewal } from 'state/quote/quote';
import { CsAsset, CsIcon } from 'types/contentStack';

const QUOTE_SUMMARY_STEP = 5;

type FooterData = {
  csQuoteAndBuyFooter: CsFooter;
};

type CsFooter = {
  link_section: {
    title: string;
    links: {
      text: string;
      url: string;
    }[];
  };
  contact_section: {
    title: string;
    title_icon: [CsIcon];
    content: string;
    footer_image: CsAsset | null;
  };
  footer_compliance: {
    compliance_text: string;
  };
};

const footerQuery = graphql`
  query {
    csQuoteAndBuyFooter {
      link_section {
        title
        links {
          text
          url
        }
      }
      contact_section {
        title
        title_icon {
          icon_code
        }
        content
        footer_image {
          ...CsAsset
        }
      }
      footer_compliance {
        compliance_text
      }
    }
  }
`;

const mapContactSection = (csFooter: CsFooter): ContactSectionType => {
  const { title, content, title_icon } = csFooter.contact_section;

  const titleIcon = unwrapSingleton(title_icon);

  /* istanbul ignore if */
  if (!titleIcon) {
    throw new Error('Icon for footer missing!');
  }

  return {
    title,
    content,
    icon: titleIcon.icon_code,
  };
};

const mapLinkSection = (csFooter: CsFooter): LargeLinkSectionType => ({
  title: csFooter.link_section.title,
  links: csFooter.link_section.links.map((link) => ({
    ...link,
    openInNewTab: true,
    onClick: () => trackButtonClick('footer', link.text),
  })),
});

export const useFooterData = (
  quote: Quote | Renewal | null,
  quoteAndBuyStep?: number
): FooterWithContactSectionProps => {
  const { csQuoteAndBuyFooter } = useStaticQuery<FooterData>(footerQuery);

  return {
    linkSection: mapLinkSection(csQuoteAndBuyFooter),
    contactSection: mapContactSection(csQuoteAndBuyFooter),
    footerCompliance: {
      compliance: csQuoteAndBuyFooter.footer_compliance.compliance_text,
    },
    footerImage:
      quote && isGoCompareQuote(quote) && quoteAndBuyStep === QUOTE_SUMMARY_STEP
        ? processImageAsset(csQuoteAndBuyFooter.contact_section.footer_image)
        : undefined,
  };
};
