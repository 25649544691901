import {
  ImageLink,
  NonTextLink,
} from '@rsa-digital/evo-shared-components/commonTypes/links';
import { SingleSectionHeaderNavItem } from '@rsa-digital/evo-shared-components/components/Header/SingleSectionHeader';
import accountClient from 'api/accountClient';
import { graphql, useStaticQuery } from 'gatsby';
import { MouseEventHandler } from 'react';
import { getAzureLogoutUrlWithRedirectPath } from 'helpers/azureRoutingHelper';
import { processImageAsset, unwrapSingleton } from 'helpers/csTypeProcessors';
import { nonFatalBuildError } from 'helpers/errorReporting';
import { trackButtonClick } from 'helpers/eventTracking';
import { accountRoutes } from 'helpers/routingHelper';
import { RequestHandlerType } from 'helpers/useApiRequestHandler';
import useResetReduxState from 'helpers/useResetReduxState';
import { useUser } from 'state/user/state';
import { CsAsset, CsIcon } from 'types/contentStack';

type HeaderProps = {
  logoLink: ImageLink;
  navItem?: SingleSectionHeaderNavItem;
};

type HeaderData = {
  csSelfServeHeader: CsHeader;
};

type CsHeader = {
  logo: {
    image: CsAsset;
    link_screen_reader_text: string;
    url: string;
  };
  nav_item: {
    icon: [CsIcon];
    name: string;
    account_home_label: string;
    change_email_label: string;
    change_password_label: string;
    sign_out_label: string;
  };
};

const headerQuery = graphql`
  query {
    csSelfServeHeader {
      logo {
        image {
          filename
          description
          localAsset {
            publicURL
          }
        }
        link_screen_reader_text
        url
      }
      nav_item {
        icon {
          icon_code
        }
        name
        account_home_label
        change_email_label
        change_password_label
        sign_out_label
      }
    }
  }
`;

const mapNavItem = (
  csHeader: CsHeader,
  onLogOut: MouseEventHandler
): SingleSectionHeaderNavItem => {
  const navItem = csHeader.nav_item;
  const iconCode = unwrapSingleton(navItem.icon)?.icon_code;

  /* istanbul ignore if */
  if (iconCode === undefined) {
    nonFatalBuildError(
      'Icon missing',
      `Icon expected for self-serve nav item "${navItem.name}"`
    );
  }

  return {
    icon: iconCode ?? '',
    name: navItem.name,
    links: [
      {
        text: navItem.account_home_label,
        url: accountRoutes.account,
        onClick: () => trackButtonClick('selfServeHeader', navItem.account_home_label),
      },
      {
        text: navItem.change_email_label,
        url: accountRoutes.changeEmail,
        onClick: () => trackButtonClick('selfServeHeader', navItem.change_email_label),
      },
      {
        text: navItem.change_password_label,
        url: accountRoutes.changePassword,
        onClick: () => trackButtonClick('selfServeHeader', navItem.change_password_label),
      },
      {
        text: navItem.sign_out_label,
        url: accountRoutes.login,
        onClick: (e) => {
          trackButtonClick('selfServeHeader', navItem.sign_out_label);
          onLogOut(e);
        },
      },
    ],
  };
};

const mapLink = (csHeader: CsHeader): NonTextLink => ({
  url: csHeader.logo.url,
  screenReaderText: csHeader.logo.link_screen_reader_text,
});

export const useHeaderData = (
  requestHandler: RequestHandlerType,
  hideAccountMenu?: boolean
): HeaderProps => {
  const resetReduxState = useResetReduxState();
  const { csSelfServeHeader } = useStaticQuery<HeaderData>(headerQuery);
  const [, setUser] = useUser();

  const logOut = async (): Promise<void> => {
    await accountClient.logout();
    setUser({ isLoggedIn: false });
    window.location.assign(getAzureLogoutUrlWithRedirectPath(accountRoutes.login));
    resetReduxState();
  };

  const logo = processImageAsset(csSelfServeHeader.logo.image);
  /* istanbul ignore if */
  if (!logo) {
    throw new Error('Header logo missing!');
  }

  const logoLink = {
    image: logo,
    link: mapLink(csSelfServeHeader),
  };

  if (hideAccountMenu) {
    return { logoLink };
  }

  const onLogOut: MouseEventHandler = (e) => {
    e.preventDefault();
    requestHandler(logOut, 'Logging out, please wait');
  };

  return { logoLink, navItem: mapNavItem(csSelfServeHeader, onLogOut) };
};
