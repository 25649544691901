import { useSelector } from 'react-redux';
import { RESET_STATE, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import { MtaQuoteType, MtaStatus } from 'state/mta/mtaQuote';
import { Quote } from 'state/quote/quote';

export const UPDATE_POLICY = 'UPDATE_POLICY';

export type MtaSummary = {
  mtaStatus: MtaStatus;
  mtaEffectiveFromDate: string;
  mtaType: MtaQuoteType;
};

// The API spec also says "None" is a valid value, but this is because it is automatically generated from the backend types.
// When there is no renewal then the `renewalSummary` field on the Policy will be `null` instead.
export enum RenewalStatus {
  Pending = 'Pending',
  Ready = 'Ready',
  InProgress = 'InProgress',
  Confirmed = 'Confirmed',
}

export type RenewalSummary = {
  renewalStatus: RenewalStatus;
  renewalEffectiveFromDate?: string;
};

export type MigratedFieldsFlags = {
  policyHolder?: MigratedPolicyHolderFieldsFlags;
  vehicleDetails?: MigratedVehicleDetailsFlags;
  additionalDrivers?: MigratedAdditionalDriverDetailsFlags[];
};

export type MigratedPolicyHolderFieldsFlags = {
  livedInUkSinceBirthIndicatorIsMigrated?: boolean;
  ukResidentSinceIsMigrated?: boolean;
  useOfOtherVehiclesIsMigrated?: boolean;
  licenceTypeIsMigrated?: boolean;
  maritalStatusIsMigrated?: boolean;
  isHomeOwnerIsMigrated?: boolean;
  childrenUnder16IndicatorIsMigrated?: boolean;
  numberOfChildrenInHomeUnder16IsMigrated?: boolean;
  // annualBusinessMileageIsMigrated is defined on MigratedPolicyHolderFieldsFlags and MigratedVehicleDetailsFlags
  // AnnualBusinessMileage is a policyHolder field, but used by vehicle forms - eg About your car
  // The API will only ever populate the field in MigratedPolicyHolderFieldsFlags
  // State updates are responsible for copying across this value into the MigratedVehicleDetailsFlags model for consumption by vehicle forms
  annualBusinessMileageIsMigrated?: boolean;
};

export type MigratedVehicleDetailsFlags = {
  isRegisteredKeeperIsMigrated?: boolean;
  registeredKeeperOfVehicleIsMigrated?: boolean;
  vehicleKeptOvernightIsMigrated?: boolean;
  postcodeOfVehicleOvernightIsMigrated?: boolean;
  carKeptDuringDayIsMigrated?: boolean;
  annualBusinessMileageIsMigrated?: boolean;
};

export type MigratedAdditionalDriverDetailsFlags = {
  driverId: string;
  livedInUkSinceBirthIndicatorIsMigrated?: boolean;
  ukResidentSinceIsMigrated?: boolean;
  useOfOtherVehiclesIsMigrated?: boolean;
  licenceTypeIsMigrated?: boolean;
  maritalStatusIsMigrated?: boolean;
  relationshipToPolicyHolderIsMigrated?: boolean;
};

export type Policy = Quote & {
  policyNumber: string;
  isInArrears?: boolean;
  mtaSummary?: MtaSummary;
  renewalSummary?: RenewalSummary;
  isMigrated?: boolean;
  migratedFields?: MigratedFieldsFlags;
};

export type UpdatePolicyAction = {
  type: typeof UPDATE_POLICY;
  policyData: Policy;
};

export const policyDataReducer = (
  policyData: Policy | null = null,
  action: UpdatePolicyAction | ResetStateAction
): Policy | null => {
  switch (action.type) {
    case UPDATE_POLICY:
      return action.policyData;
    case RESET_STATE:
      return null;
    default:
      return policyData;
  }
};

export const usePolicyData = (): Policy | null => {
  return useSelector((state: RootState) => state.policyData);
};
