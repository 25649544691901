import { DocumentsDetails } from 'components/CheckDetails/DocumentsSection/validation';
import { FormatOptions, Preferences } from 'state/quote/quote';

export const getDocumentsDetails = (
  preferences: Preferences | null
): DocumentsDetails => {
  const preference = preferences?.documentPreference;
  const format = preferences?.documentFormat ?? undefined;

  return {
    useOtherFormats: preference && format ? preference !== 'Digital' : false,
    otherFormat: preference ? format : undefined,
  };
};

export const getDocumentsPreferences = (
  details: DocumentsDetails
): Pick<Preferences, 'documentPreference' | 'documentFormat'> => {
  if (!details.useOtherFormats) {
    return {
      documentPreference: 'Digital',
      documentFormat: FormatOptions.Online,
    };
  }

  return {
    documentPreference:
      details.otherFormat === FormatOptions.Paper ? 'Post' : 'InclusiveFormat',
    documentFormat: details.otherFormat,
  };
};
