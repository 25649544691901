import mapCoverDetails from 'api/quote/quoteToFormMappings/mapCoverDetails';
import { RESET_STATE, ResetStateAction } from 'state/actions';
import {
  RESET_QUOTE,
  ResetQuoteAction,
  UPDATE_QUOTE,
  UPDATE_RENEWAL,
  UpdateQuoteAction,
} from 'state/quote/quote';
import {
  CoverDetails,
  initialCoverDetails,
  UPDATE_COVER_DETAILS,
  UpdateCoverDetailsAction,
} from './coverDetails';
import { mergeFormData } from './mergeFormData';
import { UPDATE_QUOTE_IN_PROGRESS, UpdateQuoteInProgressAction } from './quoteInProgress';

export const coverDetailsReducer = (
  coverDetails: CoverDetails = initialCoverDetails,
  action:
    | UpdateCoverDetailsAction
    | UpdateQuoteInProgressAction
    | UpdateQuoteAction
    | ResetQuoteAction
    | ResetStateAction
): CoverDetails => {
  switch (action.type) {
    case UPDATE_QUOTE:
    case UPDATE_RENEWAL:
      return action.quote ? mapCoverDetails(action.quote) : coverDetails;
    case UPDATE_COVER_DETAILS:
      return {
        ...coverDetails,
        ...action.update,
      };
    case UPDATE_QUOTE_IN_PROGRESS:
      if (action.quote.policyDetails) {
        return mergeFormData(
          coverDetails,
          action.quote.policyDetails,
          initialCoverDetails
        );
      }
      return coverDetails;
    case RESET_QUOTE:
    case RESET_STATE:
      return initialCoverDetails;
    default:
      return coverDetails;
  }
};
