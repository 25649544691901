import { useCallback, useState } from 'react';

export type LoadingState = {
  isLoading: boolean;
  loadingMessage: string | undefined;
};

export const initialLoadingState: LoadingState = {
  isLoading: false,
  loadingMessage: undefined,
};

export type WithLoadingStateType = <T>(
  asyncFunction: () => Promise<T>,
  loadingMessage?: string
) => Promise<T>;

export const useLoadingState = (): LoadingState & {
  withLoadingState: WithLoadingStateType;
} => {
  const [progressState, setInProgress] = useState<LoadingState>(initialLoadingState);

  const withLoadingState = useCallback(
    async <T,>(asyncFunction: () => Promise<T>, loadingMessage?: string): Promise<T> => {
      setInProgress({ isLoading: true, loadingMessage });
      const data = await asyncFunction();
      setInProgress(initialLoadingState);
      return data;
    },
    []
  );

  return { ...progressState, withLoadingState };
};
