import replaceCsPlaceholders from './replaceCsPlaceholders';

/**
 * A function to replace a single ContentStack placeholder.
 * For details on these see the underlying method, `replaceCsPlaceholders.ts`.
 *
 * @param csContent the value of the CS field including the placeholder
 * @param variableName the name of the placeholder
 * @param value the value with which to replace the placeholder
 *
 * @returns a string with any instance of `{{variableName}}` replaced with `value`.
 */
const replaceCsPlaceholder = (
  csContent: string,
  variableName: string,
  value: string
): string =>
  replaceCsPlaceholders({ csContent }, value, {
    [variableName]: (v) => v,
  }).csContent;

export default replaceCsPlaceholder;
