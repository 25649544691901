import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import usePageTitle from 'helpers/usePageTitle';
import { CsMeta } from 'types/contentStack';

const HTML_ATTRIBUTES = {
  lang: 'en',
};

type SiteWideSeoFavIcon = {
  csSiteWideSeo: { favicon: { url: string } };
};

const metaQuery = graphql`
  query {
    csSiteWideSeo {
      favicon {
        url
      }
    }
  }
`;

const Meta: React.FC<{ meta: CsMeta }> = ({ meta }) => {
  const data = useStaticQuery<SiteWideSeoFavIcon>(metaQuery);
  const fullPageTitle = usePageTitle(meta.meta_title);

  return (
    <Helmet htmlAttributes={HTML_ATTRIBUTES}>
      <title>{fullPageTitle}</title>
      <link rel="icon" type="image/png" href={data.csSiteWideSeo.favicon.url} />
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
  );
};

export default Meta;
