import { RESET_STATE, ResetStateAction } from 'state/actions';
import {
  RESET_QUOTE,
  ResetQuoteAction,
  UPDATE_QUOTE,
  UPDATE_RENEWAL,
  UpdateQuoteAction,
} from 'state/quote/quote';
import {
  AddonSelection,
  initialAddonSelection,
  UPDATE_ADDONS,
  UpdateAddonSelectionAction,
} from './addonsSelection';

export const addonsSelectionReducer = (
  addonsSelection: AddonSelection = initialAddonSelection,
  action:
    | UpdateAddonSelectionAction
    | UpdateQuoteAction
    | ResetQuoteAction
    | ResetStateAction
): AddonSelection => {
  switch (action.type) {
    case UPDATE_ADDONS:
      return { ...addonsSelection, ...action.update };
    case UPDATE_QUOTE:
    case UPDATE_RENEWAL:
      if (!action.quote) {
        return initialAddonSelection;
      }
      return {
        localCoverages: action.quote.coverages,
        localBreakdown: {
          coverLevel: action.quote.breakdown.selectedBreakdown.coverLevel,
          isSelected: action.quote.breakdown.selectedBreakdown.isSelected,
        },
        localSelectedTier: action.quote.tieringInfo?.selectedTier ?? undefined,
      };
    case RESET_QUOTE:
    case RESET_STATE:
      return initialAddonSelection;
    default:
      return addonsSelection;
  }
};
