import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ErrorPage, { ErrorPageData } from 'templates/ErrorPage/index';
import { ErrorType } from 'state/error/actions';

export const query = graphql`
  query {
    csErrorPage(error_id: { eq: "motor-500" }) {
      ...ErrorPage
    }
  }
`;

const UnknownErrorPage: React.FC = () => {
  const data = useStaticQuery<ErrorPageData>(query);
  return <ErrorPage data={data} errorType={ErrorType.API_ERROR} statusCode={500} />;
};

export default UnknownErrorPage;
