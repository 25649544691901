import { useSelector } from 'react-redux';
import { DocumentsDetails } from 'components/CheckDetails/DocumentsSection/validation';
import { KeepInformedDetails } from 'components/CheckDetails/KeepingInformedSection/validation';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';

export const UPDATE_PREFERENCE_DETAILS = 'UPDATE_PREFERENCE_DETAILS';
export const RESET_PREFERENCE_DETAILS = 'RESET_PREFERENCE_DETAILS';

export type PreferenceDetails = DocumentsDetails & KeepInformedDetails;

export const initialPreferenceDetails: PreferenceDetails = {
  useOtherFormats: false,
  otherFormat: undefined,
  shouldKeepInformed: undefined,
};

export type UpdatePreferenceDetailsAction = {
  type: typeof UPDATE_PREFERENCE_DETAILS;
  update: Partial<PreferenceDetails>;
};

export type ResetPreferenceDetailsAction = {
  type: typeof RESET_PREFERENCE_DETAILS;
};

export const usePreferenceDetails = (): [
  PreferenceDetails,
  (update: Partial<PreferenceDetails>) => void
] => {
  const preferenceDetails = useSelector((state: RootState) => state.preferenceDetails);
  const dispatch = useDispatch();

  const updatePreferenceDetails = (update: Partial<PreferenceDetails>): void => {
    dispatch({ type: UPDATE_PREFERENCE_DETAILS, update });
  };

  return [preferenceDetails, updatePreferenceDetails];
};
