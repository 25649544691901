import { QuoteModification } from 'api/quote/quoteRequest';
import { Modification } from '../../../state/formData/vehicleDetails';

const mapModification = (modification: QuoteModification): Modification =>
  ({
    modificationDetail: modification.detail,
    modificationType: modification.type,
  } as Modification);

export default mapModification;
