import { mapApiOption } from 'api/referenceData/mapApiOption';
import { ApiOption, ReferenceDataOption } from 'types/referenceData';

export type ModificationsReferenceDataResponse = {
  'ModificationsInput.TypeOfModifications': ApiOption[];
  'ModificationsInput.DetailAccessories': ApiOption[];
  'ModificationsInput.DetailBodyMods': ApiOption[];
  'ModificationsInput.DetailBrakes': ApiOption[];
  'ModificationsInput.DetailEngineOrTransmission': ApiOption[];
  'ModificationsInput.DetailPaintworkOrStickers': ApiOption[];
  'ModificationsInput.DetailSpoilersOrBodyKits': ApiOption[];
  'ModificationsInput.DetailSuspensionOrSteering': ApiOption[];
  'ModificationsInput.DetailWheelsAndTyres': ApiOption[];
};

export type ModificationsReferenceData = {
  modificationType: ReferenceDataOption[];
  detailAccessories: ReferenceDataOption[];
  detailBodyMods: ReferenceDataOption[];
  detailBrakes: ReferenceDataOption[];
  detailEngineOrTransmission: ReferenceDataOption[];
  detailPaintworkOrStickers: ReferenceDataOption[];
  detailSpoilersOrBodyKits: ReferenceDataOption[];
  detailSuspensionOrSteering: ReferenceDataOption[];
  detailWheelsAndTyres: ReferenceDataOption[];
};

export const mapModificationsRefData = (
  data: ModificationsReferenceDataResponse
): ModificationsReferenceData => ({
  modificationType: data['ModificationsInput.TypeOfModifications'].map(mapApiOption),
  detailAccessories: data['ModificationsInput.DetailAccessories'].map(mapApiOption),
  detailBodyMods: data['ModificationsInput.DetailBodyMods'].map(mapApiOption),
  detailBrakes: data['ModificationsInput.DetailBrakes'].map(mapApiOption),
  detailEngineOrTransmission: data['ModificationsInput.DetailEngineOrTransmission'].map(
    mapApiOption
  ),
  detailPaintworkOrStickers: data['ModificationsInput.DetailPaintworkOrStickers'].map(
    mapApiOption
  ),
  detailSpoilersOrBodyKits: data['ModificationsInput.DetailSpoilersOrBodyKits'].map(
    mapApiOption
  ),
  detailSuspensionOrSteering: data['ModificationsInput.DetailSuspensionOrSteering'].map(
    mapApiOption
  ),
  detailWheelsAndTyres: data['ModificationsInput.DetailWheelsAndTyres'].map(mapApiOption),
});
