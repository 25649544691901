import accountClient from 'api/accountClient';
import React, { useEffect } from 'react';
import { useBaseApiRequestHandler } from 'helpers/useApiRequestHandler';
import { useUser } from './state';

/**
 * Initialises the User state in redux on page load. This is needed site-wide.
 *
 * Currently this contains information about whether the user is logged in.
 */
const UserInitialiser: React.FC = ({ children }) => {
  const [user, setUser] = useUser();
  const requestHandler = useBaseApiRequestHandler();

  useEffect(() => {
    if (!user) {
      const loadUser = async (): Promise<void> => {
        const fetchedUser = await accountClient.getUser();
        setUser(fetchedUser);
      };
      requestHandler(loadUser);
    }
  }, [user, setUser, requestHandler]);

  return <>{children}</>;
};

export default UserInitialiser;
