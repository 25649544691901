import { QuoteInProgress } from 'state/formData/quoteInProgress';
import apiService from './apiService';

export type QuoteInProgressClient = {
  saveQuoteInProgress: (quoteInProgress: QuoteInProgress) => Promise<void>;
  loadQuoteInProgress: () => Promise<QuoteInProgress>;
  deleteQuoteInProgress: () => Promise<void>;
};

const quoteInProgressClient: QuoteInProgressClient = {
  saveQuoteInProgress: (quoteInProgress: QuoteInProgress) =>
    apiService.put('QuoteInProgress', quoteInProgress),
  loadQuoteInProgress: () => apiService.get<QuoteInProgress>('QuoteInProgress'),
  deleteQuoteInProgress: () => apiService.delete('QuoteInProgress'),
};

export default quoteInProgressClient;
