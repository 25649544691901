import mapPreferences from 'api/quote/quoteToFormMappings/mapPreferences';
import { RESET_STATE, ResetStateAction } from 'state/actions';
import { UPDATE_QUOTE, UPDATE_RENEWAL, UpdateQuoteAction } from 'state/quote/quote';
import {
  initialPreferenceDetails,
  PreferenceDetails,
  UPDATE_PREFERENCE_DETAILS,
  UpdatePreferenceDetailsAction,
} from './preferenceDetails';

export const preferenceDetailsReducer = (
  preferenceDetails: PreferenceDetails = initialPreferenceDetails,
  action: UpdatePreferenceDetailsAction | UpdateQuoteAction | ResetStateAction
): PreferenceDetails => {
  switch (action.type) {
    case UPDATE_PREFERENCE_DETAILS:
      return {
        ...preferenceDetails,
        ...action.update,
      };
    case UPDATE_QUOTE:
    case UPDATE_RENEWAL:
      return action.quote
        ? mapPreferences(action.quote?.preferences)
        : initialPreferenceDetails;
    case RESET_STATE:
      return initialPreferenceDetails;
    default:
      return preferenceDetails;
  }
};
