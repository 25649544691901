import {
  initialLookupValue,
  LookupValue,
} from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import { MigratedVehicleDetailsFlags } from 'state/policy/policy';
import useDispatch from 'state/useDispatch';

export const UPDATE_VEHICLE_DETAILS = 'UPDATE_VEHICLE_DETAILS';

export type Modification = {
  key: number;
  modificationType: string;
  modificationDetail: string;
  modificationId?: string;
};

export type VehicleInfo = {
  make: string;
  model: string;
  engineSize: string | null;
  // TODO:DTN-120 - should this be year registered?
  yearBuilt: number;
  insuranceCode: string;
};

export type ManualVehicleLookup = {
  make: string;
  model: string;
  yearOfManufacture: string;
  engineSize: string;
  transmissionType: string;
  fuelType: string;
  numberOfDoors: string;
  insuranceGroupCode: string;
};

export type RegistrationLookup = LookupValue<string, VehicleInfo>;

export type VehicleDetails = {
  isManualSearch: boolean;
  registrationLookup: RegistrationLookup;
  carValue: number | undefined;
  carUse: string;
  personalMileage: string;
  businessMileage: string;
  hasModifications: boolean | undefined;
  modifications: Modification[];
  hasSecurityOrTrackingDevice: boolean | undefined;
  alarmOrImmobiliser: string;
  tracker: string;
  isLegalOwner: boolean | undefined;
  legalOwner: string;
  isRegisteredKeeper: boolean | undefined;
  registeredKeeper: string;
  legalOwnerDuration: string;
  overnightParkingLocation: string;
  isParkedAtHomeAddressOvernight: boolean | undefined;
  overnightParkingPostcode: string;
  daytimeParkingLocation: string;
  migratedFields?: MigratedVehicleDetailsFlags;
} & ManualVehicleLookup;

export const initialModification = {
  modificationType: '',
  modificationDetail: '',
};

export const initialRegistration = initialLookupValue<string, VehicleInfo>('');

export const initialManualLookup: ManualVehicleLookup = {
  make: '',
  model: '',
  yearOfManufacture: '',
  engineSize: '',
  transmissionType: '',
  fuelType: '',
  numberOfDoors: '',
  insuranceGroupCode: '',
};

export const initialVehicleDetails: VehicleDetails = {
  isManualSearch: false,
  registrationLookup: initialRegistration,
  carValue: undefined,
  carUse: '',
  personalMileage: '',
  businessMileage: '',
  hasModifications: undefined,
  modifications: [],
  hasSecurityOrTrackingDevice: undefined,
  alarmOrImmobiliser: '',
  tracker: '',
  isLegalOwner: undefined,
  legalOwner: '',
  isRegisteredKeeper: undefined,
  registeredKeeper: '',
  legalOwnerDuration: '',
  overnightParkingLocation: '',
  isParkedAtHomeAddressOvernight: undefined,
  overnightParkingPostcode: '',
  daytimeParkingLocation: '',
  ...initialManualLookup,
};

export type UpdateVehicleDetailsAction = {
  type: typeof UPDATE_VEHICLE_DETAILS;
  update: Partial<VehicleDetails>;
};

export const useVehicleDetails = (): [
  VehicleDetails,
  (update: Partial<VehicleDetails>) => void
] => {
  const vehicleDetails = useSelector((state: RootState) => state.vehicleDetails);
  const dispatch = useDispatch();

  const updateVehicleDetails = useCallback(
    (update: Partial<VehicleDetails>): void => {
      dispatch({ type: UPDATE_VEHICLE_DETAILS, update });
    },
    [dispatch]
  );

  return [vehicleDetails, updateVehicleDetails];
};
