import CtaBanner from '@rsa-digital/evo-shared-components/components/CtaBanner';
import { graphql } from 'gatsby';
import React from 'react';
import { processMandatoryCta, processOptionalCta } from 'helpers/csTypeProcessors';
import { CsCta } from 'types/contentStack';

type CtaBannerBlockProps = {
  cta_banner: {
    heading: string;
    subheading: string;
    primary_cta: [CsCta];
    secondary_cta: [CsCta] | null;
  };
};

export const query = graphql`
  fragment CtaBannerBlockError on cs__error_page_blocks {
    cta_banner {
      heading
      subheading
      primary_cta {
        display_text
        screen_reader_text
        url
      }
      secondary_cta {
        display_text
        screen_reader_text
        url
      }
    }
  }
`;

const CtaBannerBlock: React.FC<CtaBannerBlockProps> = ({ cta_banner }) => {
  const primaryCta = processMandatoryCta(cta_banner.primary_cta);
  const secondaryCta = processOptionalCta(cta_banner.secondary_cta);

  return (
    <CtaBanner
      primaryCta={primaryCta}
      secondaryCta={secondaryCta}
      heading={cta_banner.heading}
      subheading={cta_banner.subheading}
      data-cy="CTA Banner"
    />
  );
};

export default React.memo(CtaBannerBlock);
