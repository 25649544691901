import BaseModal from '@rsa-digital/evo-shared-components/components/Modal/SessionExpiryModal';
import { formatTimeInMinutesAndSeconds } from '@rsa-digital/evo-shared-components/helpers/timeHelpers';
import React from 'react';
import { PageType } from 'components/Layout';
import { getAzureLogoutUrlWithRedirectUrl } from 'helpers/azureRoutingHelper';
import { trackButtonClick } from 'helpers/eventTracking';
import replaceCsPlaceholder from 'helpers/replaceCsPlaceholder';
import useSessionExpiryModalData from './useSessionExpiryModalData';

const SESSION_TIMEOUT_IN_SECONDS = 5 * 60;

type SessionExpiryModalProps = {
  onExpiry: () => void;
  onContinue: () => void;
  onGoToHomepage?: () => void;
  pageType: PageType;
};

const SessionExpiryModal: React.FC<SessionExpiryModalProps> = ({
  onExpiry,
  onContinue,
  onGoToHomepage,
  pageType,
}) => {
  const staticData = useSessionExpiryModalData(pageType);

  const renderBody = (timeRemaining: number): string =>
    replaceCsPlaceholder(
      staticData.body,
      'timeRemaining',
      formatTimeInMinutesAndSeconds(timeRemaining)
    );

  const link =
    staticData.link.link_text && staticData.link.link_url
      ? {
          text: staticData.link.link_text,
          onClick: () => {
            onGoToHomepage?.();
            trackButtonClick('sessionExpiryModal', staticData.link.link_text || '');
          },
          url: getAzureLogoutUrlWithRedirectUrl(staticData.link.link_url),
        }
      : undefined;

  return (
    <BaseModal
      header={staticData.header}
      renderBody={renderBody}
      cta={{
        displayText: staticData.cta.cta_text,
        screenReaderText: staticData.cta.cta_screenreader_text,
        onClick: () => {
          onContinue();
          trackButtonClick('sessionExpiryModal', staticData.cta.cta_text);
        },
      }}
      link={link}
      onExpiry={onExpiry}
      sessionTimeout={SESSION_TIMEOUT_IN_SECONDS}
    />
  );
};

export default SessionExpiryModal;
