import { dateToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { QuoteDriverClaim } from 'api/quote/quoteRequest';
import { DriverClaim } from 'state/formData/shared/driverClaim';

const mapDriverClaim = (driverClaim: QuoteDriverClaim): DriverClaim =>
  ({
    claimDate: dateToDateValue(new Date(driverClaim.dateOfLoss)),
    claimType: driverClaim.causeOfLoss,
  } as DriverClaim);

export default mapDriverClaim;
