import {
  handleInvalidQuote,
  isIneligibleQuoteTier,
  isInvalidQuoteError,
} from 'api/businessLogic/errors/invalidQuoteError';
import { isReCaptchaThresholdError } from 'api/businessLogic/errors/recaptchaError';
import { isRightsOfReservationError } from 'api/businessLogic/errors/rightsOfReservation';
import { AxiosError } from 'axios';
import { Dispatch, useCallback } from 'react';
import { ErrorType, UPDATE_ERROR, UpdateErrorAction } from 'state/error/actions';
import useDispatch from 'state/useDispatch';
import { isAxiosError } from './axiosResponseHelpers';
import { updateErrorCustomUserData } from './customUserDataHelper';
import { trackReCaptchaScore } from './eventTracking';
import logApiError from './logApiError';
import { LoadingState, useLoadingState } from './useLoadingState';
import {
  isIneligibleMtaQuote,
  isIneligibleMtaTier,
} from '../api/businessLogic/errors/ineligibleMtaQuote';
import { isIneligibleRenewalQuote } from '../api/businessLogic/errors/ineligibleRenewalQuote';

type RequestHandlerWithoutLoadingStateType = <T>(
  makeRequest: () => Promise<T>
) => Promise<T | undefined>;

type RequestHandlerWithLoadingStateType = <T>(
  makeRequest: () => Promise<T>,
  loadingMessage?: string
) => Promise<T | undefined>;

export type RequestHandlerType = RequestHandlerWithLoadingStateType;

const handleRequestError = (
  error: Error | AxiosError,
  dispatchError: Dispatch<UpdateErrorAction>
): void => {
  if (isAxiosError(error)) {
    logApiError(error);
    updateErrorCustomUserData(error.response.data?.Code);
    if (isReCaptchaThresholdError(error)) {
      trackReCaptchaScore('Failure', error.response.data.Details.reCaptchaScore);
      dispatchError({
        type: UPDATE_ERROR,
        errorType: ErrorType.RECAPTCHA_ERROR,
      });
      return;
    }
    if (isInvalidQuoteError(error)) {
      handleInvalidQuote(error, dispatchError);
      return;
    }
    if (isIneligibleQuoteTier(error)) {
      dispatchError({
        type: UPDATE_ERROR,
        errorType: ErrorType.QUOTE_TIER_INVALID,
      });
      return;
    }
    if (isIneligibleMtaQuote(error)) {
      dispatchError({
        type: UPDATE_ERROR,
        errorType: ErrorType.MTA_QUOTE_INELIGIBLE,
      });
      return;
    }
    if (isIneligibleMtaTier(error)) {
      dispatchError({
        type: UPDATE_ERROR,
        errorType: ErrorType.MTA_TIER_INVALID,
      });
      return;
    }

    if (isIneligibleRenewalQuote(error)) {
      dispatchError({
        type: UPDATE_ERROR,
        errorType: ErrorType.RENEWAL_QUOTE_INELIGIBLE,
      });
      return;
    }
    if (isRightsOfReservationError(error)) {
      dispatchError({
        type: UPDATE_ERROR,
        errorType: ErrorType.ACCOUNT_UNAVAILABLE,
      });
      return;
    }
    dispatchError({
      type: UPDATE_ERROR,
      errorType: ErrorType.API_ERROR,
      statusCode: error.response.status,
    });
  } else {
    console.error('Error when making API request: ', error.message);
    dispatchError({
      type: UPDATE_ERROR,
      errorType: ErrorType.API_ERROR,
    });
  }
};

export const useBaseApiRequestHandler = (): RequestHandlerWithoutLoadingStateType => {
  const dispatch = useDispatch();

  return useCallback(
    async <T>(makeRequest: () => Promise<T>): Promise<T | undefined> => {
      try {
        return await makeRequest();
      } catch (error) {
        handleRequestError(error, dispatch);
        return undefined;
      }
    },
    [dispatch]
  );
};

const useApiRequestHandlerWithLoadingState = (): LoadingState & {
  requestHandler: RequestHandlerWithLoadingStateType;
} => {
  const {
    isLoading,
    loadingMessage: currentLoadingMessage,
    withLoadingState,
  } = useLoadingState();
  const dispatch = useDispatch();
  const requestHandler = useBaseApiRequestHandler();

  const requestHandlerWithLoadingState = useCallback(
    async <T>(
      makeRequest: () => Promise<T>,
      loadingMessage?: string
    ): Promise<T | undefined> => {
      try {
        return await withLoadingState<T | undefined>(
          () => requestHandler(makeRequest),
          loadingMessage
        );
      } catch (error) {
        handleRequestError(error, dispatch);
        return undefined;
      }
    },
    [dispatch, requestHandler, withLoadingState]
  );

  return {
    isLoading,
    loadingMessage: currentLoadingMessage,
    requestHandler: requestHandlerWithLoadingState,
  };
};

export default useApiRequestHandlerWithLoadingState;
