import SimpleHeader from '@rsa-digital/evo-shared-components/components/Header/SimpleHeader';
import styled from 'styled-components';

type OptionalShadowHeaderProps = {
  displayShadow: boolean;
};

export const OptionalShadowHeader = styled(SimpleHeader)<OptionalShadowHeaderProps>`
  &&& {
    ${(props) => !props.displayShadow && 'box-shadow: none;'}
  }
`;
