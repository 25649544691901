import { QuoteVehicleManualLookup } from 'api/quote/quoteRequest';
import { ManualVehicleLookup } from 'state/formData/vehicleDetails';

const mapQuoteToManualVehicleLookup = (
  vehicleInfo?: QuoteVehicleManualLookup | null
): ManualVehicleLookup => ({
  make: vehicleInfo?.make || '',
  model: vehicleInfo?.model || '',
  yearOfManufacture: vehicleInfo?.year || '',
  engineSize: vehicleInfo?.engineSize || '',
  transmissionType: vehicleInfo?.transmissionType || '',
  fuelType: vehicleInfo?.fuelType || '',
  numberOfDoors: vehicleInfo?.numberOfDoors || '',
  insuranceGroupCode: vehicleInfo?.insuranceGroupCode || '',
});

export default mapQuoteToManualVehicleLookup;
