import FooterWithContactSection from '@rsa-digital/evo-shared-components/components/Footer/FooterWithContactSection';
import React from 'react';
import { useQuote } from 'state/quote/quote';
import { useFooterData } from './useFooterData';

const QuoteAndBuyFooter: React.FC<{ quoteAndBuyStep?: number }> = ({
  quoteAndBuyStep,
}) => {
  const quote = useQuote();
  const footerData = useFooterData(quote, quoteAndBuyStep);
  return <FooterWithContactSection {...footerData} data-cy="quoteAndBuy footer" />;
};

export default QuoteAndBuyFooter;
