import { QuoteParameters } from 'state/quoteParameters/quoteParameters';
import { AdditionalDriversDetails } from './additionalDriversDetails';
import { CoverDetails } from './coverDetails';
import { DriverDetails } from './driverDetails';
import { VehicleDetails } from './vehicleDetails';

export const UPDATE_QUOTE_IN_PROGRESS = 'UPDATE_QUOTE_IN_PROGRESS';

export type QuoteInProgress = {
  vehicleDetails?: VehicleDetails;
  policyDetails?: CoverDetails;
  driverDetails?: DriverDetails;
  additionalDriversDetails?: AdditionalDriversDetails;
  quoteParameters?: QuoteParameters;
};

export type UpdateQuoteInProgressAction = {
  type: typeof UPDATE_QUOTE_IN_PROGRESS;
  quote: QuoteInProgress;
};
