import 'whatwg-fetch';
import elementClosest from 'element-closest';

import React from 'react';
import ErrorBoundary from './src/components/ErrorBoundary';
import UserInitialiser from './src/state/user/initialiser';
import ThemeProvider from './src/theme';
import StoreProvider from './src/state/storeProvider';

elementClosest(window);

/* istanbul ignore next */
const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

export const wrapPageElement = ({ element, props }) => (
  <ThemeProvider>
    {activeEnv === 'maintenance'
      // Do not include the error boundary in maintenance mode
      ? element
      : <ErrorBoundary location={props.location}>{element}</ErrorBoundary>}
  </ThemeProvider>
);

export const wrapRootElement = ({ element }) => (
  <StoreProvider>
    {activeEnv === 'maintenance'
      // Do not include the user initialiser in maintenance mode
      ? element
      : <UserInitialiser>{element}</UserInitialiser>}
  </StoreProvider>
);

export const onInitialClientRender = () => {
  window.isHydrated = true;
}
